import {useLazyQuery, useMutation, useQuery, useSubscription} from '@apollo/client'
import {GET_ALL_EMPLOYEES} from "../queries/getAllEmployees";
import {ON_EMPLOYEES_CHANGED} from "../subscriptions/onEmployeesChanged";
import {CREATE_EMPLOYEE} from "../mutations/createEmployee";
import {ARCHIVE_EMPLOYEE} from "../mutations/archiveEmployee";
import {UPDATE_EMPLOYEE} from "../mutations/updateEmployee";
import {GET_EMPLOYEE_BY_ID} from "../queries/getEmployeeById";
import {REMOVE_EMPLOYEE} from "../mutations/removeEmployee";
import {useNotifications} from "./useNotifications";

export const useEmployees = () => {

    const {addNotification} = useNotifications()
    const fetchQuery = GET_ALL_EMPLOYEES

    const {
        data: dataQuery,
        loading: loadingQuery,
        error: errorQuery,
        refetch: refetchQuery,
    } = useQuery(fetchQuery, {
        fetchPolicy: 'no-cache'
    })
    const {data: allEmployeesSubData} = useSubscription(ON_EMPLOYEES_CHANGED, {
        fetchPolicy: 'no-cache'
    });

    let employeeData = allEmployeesSubData ? allEmployeesSubData?.onEmployeesChanged : dataQuery?.getAllEmployees;

    const [add, {error: errorAdd}] = useMutation(
        CREATE_EMPLOYEE,
        {
            errorPolicy: "all",
            fetchPolicy: 'no-cache',
            onCompleted: (res) => {
                if(!res) return
                refetchQuery()
                addNotification("Mitarbeiter " + res.createEmployee.vorname + " " + res.createEmployee.nachname + " hinzugefügt.", "EMPLOYEES")
                console.log('employee created!')
            },
        },
    );

    const [update, {error: errorUpdate}] = useMutation(
        UPDATE_EMPLOYEE,
        {
            errorPolicy: "all",
            fetchPolicy: 'no-cache',
            onCompleted: (res) => {
                if(!res) return
                refetchQuery()
                addNotification("Mitarbeiter " + res.updateEmployee.vorname + " " + res.updateEmployee.nachname + " aktualisiert.", "EMPLOYEES")
                console.log('employee updated!')
            },
        },
    );

    const [archive, {error: errorArchive}] = useMutation(
        ARCHIVE_EMPLOYEE,
        {
            errorPolicy: "all",
            fetchPolicy: 'no-cache',
            onCompleted: (res) => {
                if(!res) return
                refetchQuery()
                addNotification("Mitarbeiter " + res.archiveEmployee.vorname + " " + res.archiveEmployee.nachname + (res.archiveEmployee.disabled ? " archiviert." : " aus dem Archiv entfernt."), "EMPLOYEES")
                console.log('employee archived!')
            },
        },
    );

    const [remove, {error: errorRemove}] = useMutation(
        REMOVE_EMPLOYEE,
        {
            errorPolicy: "all",
            fetchPolicy: 'no-cache',
            onCompleted: (res) => {
                if(!res) return
                refetchQuery()
                addNotification("Mitarbeiter " + res.removeEmployee.vorname + " " + res.removeEmployee.nachname + " entfernt.", "EMPLOYEES")
                console.log('employee removed!')
            },
        },
    );

    const [getById] = useLazyQuery(
        GET_EMPLOYEE_BY_ID,
        {
            errorPolicy: "all",
            fetchPolicy: 'no-cache',
            onCompleted: (res) => {
                if(!res) return
                refetchQuery()
                console.log('employee found!')
            },
        },
    );

    return {
        employees: employeeData,
        enabledEmployees: employeeData?.filter(x => !x.disabled).sort((a, b) => a.employeeId - b.employeeId),
        disabledEmployees: employeeData?.filter(x => x.disabled).sort((a, b) => a.employeeId - b.employeeId),
        employeesWithBox: employeeData?.filter(x => x.hasBox).filter(x => !x.disabled).sort((a, b) => a.boxNumber - b.boxNumber),
        employeesWithoutBox: employeeData?.filter(x => !x.hasBox).filter(x => !x.disabled).sort((a, b) => a.employeeId - b.employeeId),
        loading: loadingQuery,
        error: errorQuery || errorAdd || errorArchive || errorUpdate,
        addEmployee: (input) => add({
            variables: {
                input: input
            }
        }),
        updateEmployee: (input, id) => update({
            variables: {
                input: input,
                _id: id,
            }
        }),
        archiveEmployee: (id, disabled) => archive({
            variables: {
                _id: id,
                disabled: disabled
            }
        }),
        removeEmployee: (id) => remove({
            variables: {
                _id: id,
            }
        }),
        getEmployeeById: (id) => getById({
            variables: {
                _id: id,
            }
        })
    }
}