import {gql} from '@apollo/client';
import {DISEASE_ENTITY} from "../entities/diseaseEntity";

export const CREATE_DISEASE = gql`
  mutation createDisease($input: DiseaseInput!) {
    createDisease(input: $input){
      ...DiseaseEntity
    }
  }
  ${DISEASE_ENTITY}
`;
