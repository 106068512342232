// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.plans_planCard__TsKqI {
    position: relative;
}
.plans_planCard__TsKqI:after {
    content: '';
    position: absolute;
    height: 40px;
    width: 40px;
    background-color: #00b6f5;
    top: 50%;
    border-radius: 50%;
    border: 6px solid #0d1b2f;
    transform: translateY(calc(-50% + 4px)) translateX(calc(-50% - 4px));
}`, "",{"version":3,"sources":["webpack://./src/views/Plans/plans.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;AACtB;AACA;IACI,WAAW;IACX,kBAAkB;IAClB,YAAY;IACZ,WAAW;IACX,yBAAyB;IACzB,QAAQ;IACR,kBAAkB;IAClB,yBAAyB;IACzB,oEAAoE;AACxE","sourcesContent":[".planCard {\n    position: relative;\n}\n.planCard:after {\n    content: '';\n    position: absolute;\n    height: 40px;\n    width: 40px;\n    background-color: #00b6f5;\n    top: 50%;\n    border-radius: 50%;\n    border: 6px solid #0d1b2f;\n    transform: translateY(calc(-50% + 4px)) translateX(calc(-50% - 4px));\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"planCard": `plans_planCard__TsKqI`
};
export default ___CSS_LOADER_EXPORT___;
