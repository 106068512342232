import React from 'react';

import './style.css';
import Dot from "./Dot";
import moment from "moment";
import Rank from "../../Rank";
import {Drawer, ListItem, ListItemText, Paper, Stack, Typography} from "@mui/material";
import {useUsers} from "../../../../apollo/hooks/useUsers";

const UserPopper = (props) => {

    const {users, onlineUsers, loading, error} = useUsers()

    if (loading) return (<p>Loading...</p>);
    if (error) return (<p>Error...</p>);

    const isUserOnline = (user) => {
        if(onlineUsers.some(u => u._id === user._id)) {
            return "success"
        } else {
            return "error"
        }
    }

    const renderUserList = () => {
        return users.map((user, i) => {
            return(
                <Stack key={i} direction={'column'} spacing={1}>
                    <ListItem key={i}>
                        <Dot variant={isUserOnline(user)} />
                        <ListItemText
                            primary={(
                                <Stack direction={'row'} spacing={1} alignItems={'center'}>
                                    <Typography variant={'body1'} color={'#ffffff'}>
                                        {user.prename + " " + user.surname}
                                    </Typography>
                                    <Typography variant={'body2'}>
                                        <Rank rank={user.role} />
                                    </Typography>
                                </Stack>
                            )}
                            secondary=<Typography variant={'body2'}>Zuletzt online: {moment(user.last_seen).format('LLL')}</Typography>
                        />

                    </ListItem>
                </Stack>
            )
        })
    }

    return (
        <Drawer open={props.openUserPopper} anchor={"right"} onClose={props.onPopperClose}>
            <Paper>
                <Typography style={{textAlign: "center"}}>
                    <h4>
                        Nutzerliste<br/>
                        <small>
                            {onlineUsers.length + "/"+users.length+" Online"}
                        </small>
                    </h4>
                </Typography>
            </Paper>
            {renderUserList()}
        </Drawer>
    )
}

export default UserPopper;
