import { gql } from '@apollo/client';

import {CUSTOMER_ENTITY} from "../entities/customerEntity";

export const CREATE_CUSTOMER = gql`
  mutation createCustomer($input: CustomerInput!) {
    createCustomer(input: $input){
        ...CustomerEntity
    }
  }
  ${CUSTOMER_ENTITY}
`;
