import { gql } from '@apollo/client';

import {NOTIFICATION_ENTITY} from "../entities/notificationEntity";
import {USER_ENTITY} from "../entities/userEntity";

export const ON_NEW_NOTIFICATION = gql`
  subscription {
    onNewNotification {
        ...NotificationEntity
        user {
          ...UserEntity
        }
    }
  }
  ${NOTIFICATION_ENTITY},${USER_ENTITY}
`;
