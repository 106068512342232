import { gql } from '@apollo/client';

import {PLAN_ENTITY} from "../entities/planEntity";
import {EMPLOYEE_ENTITY} from "../entities/employeeEntity";
import {CUSTOMER_ENTITY} from "../entities/customerEntity";

export const GET_ALL_PLANS = gql`
  query getAllPlans {
    getAllPlans {
        ...PlanEntity
        employee {
          ...EmployeeEntity
        }
        customer {
          ...CustomerEntity
        }
    }
  }
  ${PLAN_ENTITY},${EMPLOYEE_ENTITY},${CUSTOMER_ENTITY}
`;
