import React, {useEffect} from 'react';

import './style.css';
import {useMutation} from "@apollo/client";
import {UPDATE_LAST_SEEN} from "../../../apollo/mutations/updateLastSeen";

const OnlineUsersWrapper = (props) => {

  const [updateLastSeenData] = useMutation(UPDATE_LAST_SEEN);

  const [onlineIndicator, setOnlineIndicator] = React.useState(0);

  useEffect(() => {
    if(localStorage.getItem('jwt')) {
      // Every 20s, run a mutation to tell the backend that you're online
      updateLastSeenData();
      setOnlineIndicator(setInterval(() => updateLastSeenData(), 5000));
      return () => {
        // Clean up
        clearInterval(onlineIndicator);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return props.children

}

export default OnlineUsersWrapper;
