import {gql} from '@apollo/client';
import {VACATION_ENTITY} from "../entities/vacationEntity";

export const CREATE_VACATION = gql`
  mutation createVacation($input: VacationInput!) {
    createVacation(input: $input){
        ...VacationEntity
    }
  }
  ${VACATION_ENTITY}
`;
