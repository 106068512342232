import React from 'react';

import './style.css';
import ContentHeader from "../ContentHeader";
import {Grid, Paper, Skeleton} from "@mui/material";

const LoadingSkeleton = (props) => {

  switch(props.variant) {
    case "small":
      return(
          <>
            <ContentHeader title={"Lade Daten..."}  subtitle={"Daten werden angezeigt, sobald die Seite fertig geladen hat."}/>
            <Paper style={{ borderTopRightRadius: "0px", borderTopLeftRadius: "0px" }} className="pageContainer">
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Skeleton variant="rect" height={100} />
                </Grid>
                <Grid item xs={12}>
                  <Skeleton variant="rect" height={100} />
                </Grid>
              </Grid>
            </Paper>
          </>
      )
    default:
      return (
          <>
            <ContentHeader title={"Lade Daten..."}  subtitle={"Daten werden angezeigt, sobald die Seite fertig geladen hat."}/>
            <Paper style={{ borderTopRightRadius: "0px", borderTopLeftRadius: "0px" }} className="pageContainer">
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Skeleton variant="rect" height={100} />
                </Grid>
                <Grid item xs={12}>
                  <Skeleton variant="rect" height={100} />
                </Grid>
                <Grid item xs={12}>
                  <Skeleton variant="rect" height={100} />
                </Grid>
                <Grid item xs={12}>
                  <Skeleton variant="rect" height={100} />
                </Grid>
                <Grid item xs={12}>
                  <Skeleton variant="rect" height={100} />
                </Grid>
                <Grid item xs={12}>
                  <Skeleton variant="rect" height={100} />
                </Grid>
                <Grid item xs={12}>
                  <Skeleton variant="rect" height={100} />
                </Grid>
              </Grid>
            </Paper>
          </>
      );
  }
}

export default LoadingSkeleton;
