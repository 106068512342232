import {gql} from '@apollo/client';

export const VACATION_ENTITY = gql`
  fragment VacationEntity on VacationEntity {
    _id,
    startdate,
    enddate,
    comment,
    type,
    created_at,
    updated_at
  }
`;
