import { gql } from '@apollo/client';
import {CUSTOMER_ENTITY} from "../entities/customerEntity";

export const REMOVE_CUSTOMER = gql`
  mutation removeCustomer($_id: String!) {
    removeCustomer(_id: $_id){
        ...CustomerEntity
    }
  }
  ${CUSTOMER_ENTITY}
`;