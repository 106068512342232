import { gql } from '@apollo/client';

import {EMPLOYEE_ENTITY} from "../entities/employeeEntity";
import {DISEASE_ENTITY} from "../entities/diseaseEntity";

export const ON_DISEASES_CHANGED = gql`
  subscription {
    onDiseasesChanged {
      ...DiseaseEntity
      employee {
        ...EmployeeEntity
      }
    }
  }
  ${DISEASE_ENTITY},${EMPLOYEE_ENTITY}
`;
