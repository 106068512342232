// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.overview_movingContainer__yj-tr {
    position: sticky;
    top: 8px;
}`, "",{"version":3,"sources":["webpack://./src/views/Overview/overview.module.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,QAAQ;AACZ","sourcesContent":[".movingContainer {\n    position: sticky;\n    top: 8px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"movingContainer": `overview_movingContainer__yj-tr`
};
export default ___CSS_LOADER_EXPORT___;
