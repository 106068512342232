import {gql} from '@apollo/client';
import {VACATION_ENTITY} from "../entities/vacationEntity";

export const UPDATE_VACATION = gql`
  mutation updateVacation($input: VacationInput!, $_id: String!) {
    updateVacation(input: $input, _id: $_id) {
        ...VacationEntity
    }
  }
  ${VACATION_ENTITY}
`;
