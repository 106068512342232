import React, {useState} from 'react';
import CustomerPopper from './Popper/CustomerPopper';
import {useLazyQuery} from "@apollo/client";
import {GET_CUSTOMER_BY_ID} from "../../../apollo/queries/getCustomerById";
import './style.css'
import {Box, Chip, Link, ListItem, ListItemText, Stack, Typography} from "@mui/material";

const GetCustomer = (props) => {

    const [openCustomerPopper, setOpenCustomerPopper] = useState(false);

    const [getCustomerById, {data: customerData, loading: customerLoading}] = useLazyQuery(GET_CUSTOMER_BY_ID)

    if (customerLoading) return <p>Loading...</p>

    const handleToggleCustomerPopper = (event) => {
        getCustomerById({
            variables: {
                _id: props.customer._id
            }
        }).then((res) => {
            setOpenCustomerPopper(event)
        })
    }

    if (props.customer) {
        return (
            <Box style={{overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}>
                {customerData && <CustomerPopper customer={customerData.getCustomerById}
                                                 anchor={props.anchor ? props.anchor : "bottom"}
                                                 openCustomerPopper={openCustomerPopper}
                                                 onPopperClose={() => handleToggleCustomerPopper(false)}/>}
                <ListItem key={0} style={{padding: '0px'}}>
                    <Stack direction={'column'}>
                        <ListItemText
                            style={{margin: '0px'}}
                            key={0}
                            primary={
                                <Link style={{cursor: 'pointer', textDecoration: 'none'}}
                                      onClick={() => handleToggleCustomerPopper(true)}>
                                    <Typography color={'inherit'} variant={'h7'}>{props.customer.name}</Typography>
                                </Link>}
                        />
                        <Stack direction={'row'} spacing={1}>
                        {props.customer.gewerk.length > 0 &&
                            props.customer.gewerk.map((gewerk, i) => (
                                <Chip
                                    key={i}
                                    className={
                                        (gewerk === "Lüftung" && "BL") ||
                                        (gewerk === "Elektro" && "BE") ||
                                        (gewerk === "Heizung / Sanitär" && "BHS") ||
                                        (gewerk === "Klima" && "BK") ||
                                        (gewerk === "MSR" && "MSR")
                                    }
                                    label={gewerk}
                                />
                            ))}
                        </Stack>
                    </Stack>
                </ListItem>
            </Box>
        )
    }
}

export default GetCustomer
