import React from 'react';
import {Avatar, Box, Button, Chip, Drawer, Grid, Icon, Paper, Stack, Typography} from "@mui/material";
import {useNavigate} from "react-router-dom";

const CustomerPopper = (props) => {
    const {customer, openCustomerPopper, onPopperClose} = props;
    const navigate = useNavigate()

    const positions = [
        {
            icon: (
                <Icon>
                    fingerprint
                </Icon>
            ),
            title: customer.customerId,
            subtitle: "Kunden ID"
        },
        {
            icon: (
                <Icon>
                    person
                </Icon>
            ),
            title: customer.name,
            subtitle: "Firma"
        },
        {
            icon: (
                <Icon>
                    build
                </Icon>
            ),
            title: customer.gewerk.length > 0 &&
                customer.gewerk.map((gewerk, i) => (
                    <Chip
                        key={i}
                        style={{marginRight: '5px'}}
                        className={
                            (gewerk === "Lüftung" && "BL") ||
                            (gewerk === "Elektro" && "BE") ||
                            (gewerk === "Heizung / Sanitär" && "BHS") ||
                            (gewerk === "Klima" && "BK") ||
                            (gewerk === "MSR" && "MSR")
                        }
                        label={gewerk}
                    />)),
            subtitle: "Gewerk"
        },
        {
            icon: (
                <Icon>
                    map
                </Icon>
            ),
            title: customer.strasse + " " + customer.hausnummer + ", " + customer.postleitzahl + " " + customer.ort,
            subtitle: "Anschrift"
        },
    ]

    const renderPositions = () => {
        return positions.map((position, index) => (
            <Paper key={index} style={{ backgroundColor: "#172840" }}>
                <Box p={1}>
                    <Stack direction={'row'} spacing={2} alignItems={'center'}>
                        <Avatar>
                            {position.icon}
                        </Avatar>
                        <Stack direction={'column'}>
                            <Typography variant={'h7'} color={'#ffffff'}>
                                {position.title}
                            </Typography>
                            <Typography variant={'body2'}>
                                {position.subtitle}
                            </Typography>
                        </Stack>
                    </Stack>
                </Box>
            </Paper>
        ))
    }

    const renderContact = () => {
        return customer.contacts.map((contact, index) => (
            <Paper key={index} style={{ backgroundColor: "#172840" }}>
                <Box p={1}>
                    <Stack direction={'row'} spacing={2} alignItems={'center'}>
                        <Avatar>
                            <Icon>
                                person
                            </Icon>
                        </Avatar>
                        <Stack direction={'column'}>
                            <Typography variant={'h7'} color={'#ffffff'}>
                                {contact.anrede + " " + contact.nachname}
                            </Typography>
                            <Typography variant={'body2'}>
                                {contact.telephone}
                            </Typography>
                            <Typography variant={'body2'}>
                                {contact.email}
                            </Typography>
                        </Stack>
                    </Stack>
                </Box>
            </Paper>
        ))
    }

    return (
        <Drawer open={openCustomerPopper} anchor={"left"} onClose={onPopperClose}>
            <Box p={5} height={'100%'} display={'grid'} justifyContent={'center'} alignItems={'center'}>
                <Stack direction={'column'} spacing={1}>
                    <Typography variant={'h6'} color={'inherit'}>
                        {customer.name}
                    </Typography>
                    {renderPositions()}
                    {renderContact()}
                    <Stack direction={'row'} spacing={1}>
                        <Button fullWidth onClick={() => navigate('/customers/' + customer._id + '/edit')} variant={'outlined'}
                                disableElevation>Bearbeiten</Button>
                    </Stack>
                </Stack>
            </Box>
        </Drawer>
    );
}

export default CustomerPopper;
