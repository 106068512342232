import React, {useState} from 'react';
import EmployeePopper from './Popper/EmployeePopper';
import './style.css'
import Logo from '../Logo/Logo';
import {useLazyQuery} from "@apollo/client";
import {GET_EMPLOYEE_BY_ID} from "../../../apollo/queries/getEmployeeById";
import {Avatar, Box, Chip, Link, ListItem, ListItemAvatar, ListItemText, Stack, Typography} from "@mui/material";
import {FaToolbox} from "react-icons/fa";

const GetEmployee = (props) => {

    const [openEmployeePopper, setOpenEmployeePopper] = useState(false);

    const [getEmployeeById, {
        data: employeeData,
        error: employeeError,
        loading: employeeLoading
    }] = useLazyQuery(GET_EMPLOYEE_BY_ID)

    if (employeeLoading) return <p>Loading...</p>
    if (employeeError) return <p>Loading...</p>

    const handleToggleEmployeePopper = (event) => {
        getEmployeeById({
            variables: {
                _id: props.employee._id
            }
        }).then(() => {
            setOpenEmployeePopper(event)
        })
    }

    function getLogoVariant(beschaeftigung_als) {
        switch (beschaeftigung_als) {
            case "Lüftungsmonteur":
                return <Logo width={'35px'} id="BL" color="#538dd5"/>;
            case "Heizungs- und Sanitärinstallateur":
                return <Logo width={'35px'} id="BHS" color="#c72c2c"/>;
            case "Kälteanlagenmechatroniker":
                return <Logo width={'35px'} id="BK" color="#16365c"/>;
            case "Elektroinstallateur":
                return <Logo width={'35px'} id="BE" color="#ffc000"/>;
            case "Fachhelfer-Lüftung":
                return <Logo width={'35px'} id="BLF" color="#8db4e2"/>;
            case "Fachhelfer Heizung/Sanitär":
                return <Logo width={'35px'} id="BHSF" color="#da9694"/>;
            default:
                return <Logo width={'35px'} id="DEFAULT" color="#FFFFFF"/>;
        }
    }

    if (props.employee) {
        return (
            <Box style={{overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}>
                {employeeData && <EmployeePopper employee={employeeData.getEmployeeById}
                                                 anchor={props.anchor ? props.anchor : "bottom"}
                                                 openEmployeePopper={openEmployeePopper}
                                                 onPopperClose={() => handleToggleEmployeePopper(false)}/>}
                <ListItem style={{padding: '0px'}}>
                    <ListItemAvatar>
                        <Avatar>
                            {getLogoVariant(props.employee.beschaeftigung_als)}
                        </Avatar>
                    </ListItemAvatar>
                    <Stack direction={'column'}>
                        <ListItemText
                            style={{ margin: '0px' }}
                            primary={
                            <Stack direction={'row'} spacing={2} alignItems={'center'}>
                                <Link style={{cursor: 'pointer', textDecoration: 'none'}}
                                      onClick={() => handleToggleEmployeePopper(true)}>
                                    <Typography color={'inherit'}
                                                variant={'h7'}>{props.employee.vorname + " " + props.employee.nachname}</Typography>
                                </Link>
                                {props.employee?.hasBox &&
                                <Stack direction={'row'} spacing={0.5} alignItems={'center'}>
                                    <FaToolbox/>
                                    <Typography color={'inherit'} variant={'body2'}>{props.employee?.boxNumber}</Typography>
                                </Stack>}
                            </Stack>}
                        />
                        <Chip
                            className={
                                (props.employee.beschaeftigung_als === "Lüftungsmonteur" && "BL") ||
                                (props.employee.beschaeftigung_als === "Heizungs- und Sanitärinstallateur" && "BHS") ||
                                (props.employee.beschaeftigung_als === "Kälteanlagenmechatroniker" && "BK") ||
                                (props.employee.beschaeftigung_als === "Elektroinstallateur" && "BE") ||
                                (props.employee.beschaeftigung_als === "Fachhelfer-Lüftung" && "BLF") ||
                                (props.employee.beschaeftigung_als === "Fachhelfer Heizung/Sanitär" && "BHSF")
                            }
                            label={props.employee.beschaeftigung_als}
                        />
                    </Stack>
                </ListItem>
            </Box>
        )
    }
}

export default GetEmployee
