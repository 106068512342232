import React, {useState} from 'react';
import {FormattedMessage} from 'react-intl';

import styles from './sidebar.module.css';
import {Box, Button, MenuItem, MenuList, Paper, Stack, Typography} from "@mui/material";
import {useLocation, useNavigate} from 'react-router-dom'
import {BiLeftArrowAlt} from "react-icons/bi";
import {AnimatePresence, motion} from "framer-motion";
import {useUsers} from "../../../apollo/hooks/useUsers";

const Sidebar = (props) => {

    let history = useNavigate()
    const location = useLocation()

    const [smallSidebar, setSmallSidebar] = useState(false);

    const {me, loading: meLoading, error: meError} = useUsers()

    if (meLoading) return (<p>Loading...</p>);
    if (meError) return (<p>Error...</p>);

    let items = [
        {
            key: 'overview',
            path: '',
            icon: 'home',
            name: 'sidebar.overview',
            shown: ['admin', 'fachbereichsleiter', 'sekretariat', 'betriebsleiter']
        },
        {
            key: 'plans',
            path: 'plans',
            icon: 'contacts',
            name: 'sidebar.plans',
            shown: ['admin', 'fachbereichsleiter', 'sekretariat', 'betriebsleiter']
        },
        {
            key: 'calendar',
            path: 'calendar',
            icon: 'calendar_month',
            name: 'sidebar.calendar',
            shown: ['admin', 'fachbereichsleiter', 'sekretariat', 'betriebsleiter']
        },
        {
            key: 'employees',
            path: 'employees',
            icon: 'group',
            name: 'sidebar.employees',
            shown: ['admin', 'fachbereichsleiter', 'sekretariat', 'betriebsleiter']
        },
        {
            key: 'customers',
            path: 'customers',
            icon: 'business_center',
            name: 'sidebar.customers',
            shown: ['admin', 'fachbereichsleiter', 'sekretariat', 'betriebsleiter']
        },
        {
            key: 'annotations',
            path: 'annotations',
            icon: 'assignment',
            name: 'sidebar.annotations',
            shown: ['admin', 'fachbereichsleiter', 'sekretariat', 'betriebsleiter']
        }
    ];

    const handleChangeLocation = (event, value) => {
        history(value)
    }

    const renderItems = () =>
        items.filter(item => {
            if (item.shown === 'all') {
                return true;
            } else if (item.shown === 'none') {
                return false;
            } else if (Array.isArray(item.shown) && item.shown.includes(me.me.role.toLowerCase())) {
                return true;
            }
            return false;
            //return plugins.filter(p => p.isInstalled && item.shown === `plugins/${p.id.toLowerCase()}`).length !== 0;
        })
            .map(item => (
                <MenuItem
                    key={item.key}
                    selected={item.path === location.pathname.toString().split('/')[1]}
                    onClick={(event) => handleChangeLocation(event, item.path)}
                >
                    <Box px={1}>
                        <Stack direction={'row'} spacing={2} alignItems={'center'}>
                            <i className="material-icons">{item.icon}</i>
                            {!smallSidebar &&
                                <Typography variant={'body2'}>
                                    <FormattedMessage id={item.name}/>
                                </Typography>
                            }
                        </Stack>
                    </Box>
                </MenuItem>
            ));

    return (
        <AnimatePresence initial={false}>
            <Paper width={'100%'}>
                <Box className={styles.sidebarWrapper} py={1} pl={smallSidebar ? 1 : 2} pr={1}>
                    <Stack direction={'row'} spacing={1}
                           justifyContent={'space-between'} height={'100%'} alignItems={'center'}>
                        {!smallSidebar &&
                            <motion.div
                                initial={{opacity: 0, x: -100}}
                                animate={{opacity: 1, x: 0}}
                                transition={{stiffness: 100, mass: 0.1, type: "spring"}}
                            >
                                <Box>
                                    <Typography variant={'h6'} color="inherit">
                                        <FormattedMessage id="sidebar.navigation_headline"/>
                                    </Typography>
                                </Box>
                            </motion.div>
                        }
                        <Box>
                            <motion.div
                                key={smallSidebar}
                                initial={{rotate: smallSidebar ? 0 : 180}}
                                animate={{rotate: smallSidebar ? 180 : 0}}
                                exit={{rotate: smallSidebar ? 0 : 180}}
                                transition={{stiffness: 100, mass: 0.1, type: "spring"}}
                            >
                                <Button className={styles.iconButton}
                                        onClick={() => setSmallSidebar(!smallSidebar)}>
                                    <BiLeftArrowAlt className={styles.buttonIcon}/>
                                </Button>
                            </motion.div>
                        </Box>
                    </Stack>
                </Box>
                <MenuList sx={{padding: "0px"}}>
                    {renderItems()}
                </MenuList>
            </Paper>
        </AnimatePresence>
    );
};

export default Sidebar;
