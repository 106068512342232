import {gql} from '@apollo/client';

export const EMPLOYEE_ENTITY = gql`
  fragment EmployeeEntity on EmployeeEntity {
    _id,
    employeeId,
    telefon,
    email,
    nachname,
    vorname,
    strasse,
    hausnummer,
    ort,
    postleitzahl,
    geburtsdatum,
    staatsangehoerigkeit,
    beschaeftigungsbeginn,
    beschaeftigung_als,
    urlaubsanspruch,
    anrede,
    fuehrerschein,
    disabled,
    hasBox,
    boxNumber,
    created_at,
    updated_at
  }
`;