import {gql} from '@apollo/client';
import {CUSTOMER_ENTITY} from "../entities/customerEntity";

export const ARCHIVE_CUSTOMER = gql`
  mutation archiveCustomer($_id: String!, $disabled: Boolean!) {
    archiveCustomer(_id: $_id, disabled: $disabled) {
        ...CustomerEntity
    }
  }
  ${CUSTOMER_ENTITY}
`;
