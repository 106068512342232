const token = localStorage.getItem('jwt');
let url = new URL(window.location.href)
let params = new URLSearchParams(url.search);

export function getToken() {
  if(token) {
    return token;
  } else {
    return false;
  }
}

export function setToken() {
  localStorage.setItem("jwt", "")
  let originalUrl = window.location.href
  window.location.href = window.env.AUTH_URL+"?redirect="+originalUrl;
  if(params.get("jwt")) {
    localStorage.setItem("jwt", params.get("jwt"))
    window.location.href = window.location.origin
  }
}

export function getJwtContents() {
  return parseJwt(getToken())
}

function parseJwt (token) {
  if(token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
  } else {
    return false;
  }
};
