export default {
  // General
  'common.save': 'Speichern',
  'common.refresh': 'Aktualisieren',
  'common.actions': 'Aktionen',
  'common.delete': 'Löschen',
  'common.edit': 'Bearbeiten',
  'common.yes': 'Ja',
  'common.no': 'Nein',

  'rank.admin': 'Administrator',
  'rank.chef': 'Geschäftsführer',
  'rank.fachbereichsleiter': 'Fachbereichsleiter',
  'rank.sekretariat': 'Sekretariat',
  'rank.lager': 'Lager',
  'rank.montageleiter': 'Montageleiter',
  'rank.betriebsleiter': 'Betriebsleiter',

  // Sidebar
  'sidebar.navigation_headline': 'Navigation',
  'sidebar.overview': 'Übersicht',
  'sidebar.employees': 'Mitarbeiter',
  'sidebar.customers': 'Auftraggeber',
  'sidebar.annotations': 'Anmerkungen',
  'sidebar.calendar': 'Zeitleiste',
  'sidebar.inventory': 'Inventurliste',
  'sidebar.plans': 'Personalplanung',

  // Notifications
  'notification.EMPLOYEES': 'Mitarbeiter',
  'notification.CUSTOMERS': 'Auftraggeber',
  'notification.PLANS': 'Personalplanung',
  'notification.ANNOTATIONS': 'Anmerkungen',
  'notification.VEHICLES': 'Fahrzeuge',
  'notification.VACATIONS': 'Urlaub',
  'notification.DISEASES': 'Erkrankungen',
  'notification.BIRTHDAYS': 'Geburtstage',
  'notification.INVENTORY': 'Inventurliste',

  // Categories
  'category.TOOLS': 'Werkzeuge',
  'category.EQUIPMENT': 'Werkzeug Zubehör',
  'category.MACHINERY': 'Maschinen',
  'category.CLOTHES': 'Bekleidung',
  'category.OFFICE': 'Büromaterial',
  'category.OTHER': 'Anderes',

  // States
  'state.NEW': 'Neu / Ungenutzt',
  'state.LOST': 'Verloren / Nicht auffindbar',
  'state.USED': 'Gebraucht / Genutzt',
  'state.OLD': 'Alt',
  'state.UNUSABLE': 'Unbrauchbar',
  'state.DEFECT': 'Defekt / Kaputt',

  // Overview

  // Employees
  'employees.title': 'Mitarbeiter',
  'employees.subtitle': 'Hier sind alle Mitarbeiter aufgelistet.',
  'employees.archive.title': 'Archivierte Mitarbeiter',
  'employees.archive.subtitle': 'Hier sind alle archivierten Mitarbeiter aufgelistet.',
  'employees.search': 'Mitarbeiter suchen',
  'employees.archive.search': 'Archiv durchsuchen',

  // Customers
  'customers.title': 'Auftraggeber',
  'customers.subtitle': 'Hier sind alle Auftraggeber aufgelistet.',
  'customers.archive.title': 'Archivierte Auftraggeber',
  'customers.archive.subtitle': 'Hier sind alle archivierten Auftraggeber aufgelistet.',
  'customers.search': 'Auftraggeber suchen',
  'customers.archive.search': 'Archiv durchsuchen',

  // Verification
  'NOT_A_STRING': 'Hier dürfen keine Zahlen stehen.',
  'IS_REQUIRED': 'Dieses Feld wird benötigt.',
  'NOT_A_EMAIL': 'Email Format: name@name.de',
  'NOT_A_INT': 'Hier dürfen nur Zahlen stehen.',
  'NOT_A_DATE': 'Hier muss ein Datum sein.',
  'NOT_A_BOOLEAN': 'Hier muss ein Boolean sein.'
};
