import {gql} from '@apollo/client';

import {EMPLOYEE_ENTITY} from "../entities/employeeEntity";
import {VACATION_ENTITY} from "../entities/vacationEntity";

export const GET_ALL_VACATIONS = gql`
  query getAllVacations {
    getAllVacations {
        ...VacationEntity
        employee {
            ...EmployeeEntity
        }
    }
  }
  ${EMPLOYEE_ENTITY},${VACATION_ENTITY}
`;
