import { gql } from '@apollo/client';

import {USER_ENTITY} from "../entities/userEntity";

export const UPDATE_LAST_SEEN = gql`
  mutation updateLastSeen {
    updateLastSeen {
        ...UserEntity
    }
  }
  ${USER_ENTITY}
`;
