import { gql } from '@apollo/client';

import {USER_ENTITY} from "../entities/userEntity";

export const ON_USERS_CHANGED = gql`
  subscription {
    onUsersChanged {
        ...UserEntity
    }
  }
  ${USER_ENTITY}
`;
