import { gql } from '@apollo/client';

import {NOTIFICATION_ENTITY} from "../entities/notificationEntity";

export const CREATE_NOTIFICATION = gql`
  mutation createNotification($input: NotificationInput!) {
    createNotification(input: $input){
        ...NotificationEntity
    }
  }
  ${NOTIFICATION_ENTITY}
`;
