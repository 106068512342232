import React, {useEffect} from 'react';
import moment, {now} from "moment";
import {FormattedMessage} from "react-intl";
import {
    Box,
    Button,
    Checkbox,
    FilledInput,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    Stack,
    TextField,
    Typography
} from "@mui/material";
import {DesktopDatePicker} from "@mui/x-date-pickers";
import ContentHeader from "../common/ContentHeader";
import {useNavigate, useParams} from "react-router-dom";
import {useEmployees} from "../../apollo/hooks/useEmployees";

const EmployeeTemplate = (props) => {

    // Stammdaten
    const [employeeId, setEmployeeId] = React.useState("");
    const [anrede, setAnrede] = React.useState("Herr");
    const [fuehrerschein, setFuehrerschein] = React.useState(false);
    const [telefon, setTelefon] = React.useState("");
    const [email, setEmail] = React.useState("");
    const [nachname, setNachname] = React.useState("");
    const [vorname, setVorname] = React.useState("");
    const [strasse, setStrasse] = React.useState("");
    const [hausnummer, setHausnummer] = React.useState("");
    const [postleitzahl, setPostleitzahl] = React.useState("");
    const [ort, setOrt] = React.useState("");
    const [adresszusatz, setAdresszusatz] = React.useState("");
    const [geburtsdatum, setGeburtsdatum] = React.useState(new Date(now()));
    const [staatsangehoerigkeit, setStaatsangehoerigkeit] = React.useState("");
    const [hasBox, setHasBox] = React.useState(false);
    const [boxNumber, setBoxNumber] = React.useState("");

    const [urlaubsanspruch, setUrlaubsanspruch] = React.useState(30);
    const [beschaeftigungsbeginn, setBeschaeftigungsbeginn] = React.useState(new Date(now()));
    const [beschaeftigung_als, setBeschaeftigung_als] = React.useState("");

    const routeParams = useParams()
    const navigate = useNavigate()

    const {loading, error, updateEmployee, addEmployee, getEmployeeById} = useEmployees();

    const [errors, setErrors] = React.useState([]);
    useEffect(() => {
        if(!routeParams.id) return
        getEmployeeById(routeParams.id).then((val) => {
                setEmployeeId(val.data.getEmployeeById.employeeId)
                setAnrede(val.data.getEmployeeById.anrede)
                setNachname(val.data.getEmployeeById.nachname)
                setVorname(val.data.getEmployeeById.vorname)
                setStrasse(val.data.getEmployeeById.strasse)
                setFuehrerschein(val.data.getEmployeeById.fuehrerschein)
                setHausnummer(val.data.getEmployeeById.hausnummer)
                setPostleitzahl(val.data.getEmployeeById.postleitzahl)
                setOrt(val.data.getEmployeeById.ort)
                setAdresszusatz(val.data.getEmployeeById.adresszusatz)
                setTelefon(val.data.getEmployeeById.telefon)
                setEmail(val.data.getEmployeeById.email)
                setGeburtsdatum(val.data.getEmployeeById.geburtsdatum)
                setStaatsangehoerigkeit(val.data.getEmployeeById.staatsangehoerigkeit)
                setBeschaeftigungsbeginn(val.data.getEmployeeById.beschaeftigungsbeginn)
                setUrlaubsanspruch(val.data.getEmployeeById.urlaubsanspruch)
                setBeschaeftigung_als(val.data.getEmployeeById.beschaeftigung_als)
                setHasBox(val.data.getEmployeeById.hasBox)
                setBoxNumber(val.data.getEmployeeById.boxNumber)
        })
    }, [])

    const handleCreateEmployee = () => {
        const payload = {
            employeeId,
            anrede,
            telefon,
            email,
            nachname,
            vorname,
            strasse,
            hausnummer,
            ort,
            adresszusatz,
            postleitzahl,
            geburtsdatum,
            staatsangehoerigkeit,
            fuehrerschein,
            beschaeftigungsbeginn,
            beschaeftigung_als,
            urlaubsanspruch,
            hasBox,
            boxNumber
        }

        addEmployee(payload).then((res) => {
            if (res.data) {
                navigate('/employees')
            }
            if (res.errors) {
                setErrors(
                    res.errors[0].message.map(error => {
                        return {
                            property: error.property,
                            constraint: Object.values(error.constraints)[0]
                        }
                    })
                )
            }
        })
    }

    const handleUpdateEmployee = () => {
        const payload = {
            employeeId,
            anrede,
            telefon,
            email,
            nachname,
            vorname,
            strasse,
            hausnummer,
            ort,
            adresszusatz,
            postleitzahl,
            geburtsdatum,
            staatsangehoerigkeit,
            fuehrerschein,
            beschaeftigungsbeginn,
            beschaeftigung_als,
            urlaubsanspruch,
            hasBox,
            boxNumber
        }
        updateEmployee(payload, routeParams.id).then(() => navigate('/employees'))
    }

    const getError = (propertyName) => {
        return errors.filter(e => e.property === propertyName).length > 0
    }

    const getHelperText = (propertyName) => {
        if (!errors.filter(e => e.property === propertyName).length > 0) return false;
        return <FormattedMessage id={errors.filter(e => e.property === propertyName)[0]?.constraint}/>
    }

    const getFormContent = () => {
        return (
            <Paper>
                <Box p={1}>
                    <Grid container spacing={1}>
                        <Grid item xs={2}>
                            <TextField
                                error={getError("employeeId")}
                                helperText={getHelperText("employeeId")}
                                label={"Mitarbeiter ID"}
                                key={"employeeId"}
                                fullWidth
                                value={employeeId}
                                onChange={(e) => setEmployeeId(e.target.value)}
                                InputLabelProps={{shrink: true}}
                                variant="filled"
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <FormControl variant="filled" fullWidth error={getError("anrede")}>
                                <InputLabel id="anrede">Anrede</InputLabel>
                                <Select
                                    displayEmpty
                                    labelId="anrede"
                                    label={"Anrede"}
                                    key={"anrede"}
                                    value={anrede}
                                    onChange={(e) => setAnrede(e.target.value)}
                                    input={
                                        <FilledInput
                                            name="anrede"
                                        />
                                    }
                                >
                                    <MenuItem value={"Herr"}>Herr</MenuItem>
                                    <MenuItem value={"Frau"}>Frau</MenuItem>
                                </Select>
                                <FormHelperText>
                                    {getHelperText("anrede")}
                                </FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid item xs={2} display={'grid'} alignItems={'center'}>
                            <FormControlLabel
                                control={
                                    <Checkbox color="primary" checked={fuehrerschein}
                                              onChange={(e) => setFuehrerschein(e.target.checked)}/>
                                }
                                label={<Typography variant={'body2'}>Führerschein vorhanden?</Typography>}
                            />
                        </Grid>
                        <Grid item xs={2} display={'grid'} alignItems={'center'}>
                            <FormControlLabel
                                control={
                                    <Checkbox color="primary" checked={hasBox}
                                              onChange={(e) => setHasBox(e.target.checked)}/>
                                }
                                label={<Typography variant={'body2'}>Kiste zugewiesen?</Typography>}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                                disabled={!hasBox}
                                label="Kistennummer"
                                key={"kistennummer"}
                                fullWidth
                                value={boxNumber}
                                onChange={(e) => setBoxNumber(e.target.value)}
                                InputLabelProps={{shrink: true}}
                                variant="filled"
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                error={getError("nachname")}
                                helperText={getHelperText("nachname")}
                                label="Nachname"
                                key={"nachname"}
                                fullWidth
                                value={nachname}
                                onChange={(e) => setNachname(e.target.value)}
                                InputLabelProps={{shrink: true}}
                                variant="filled"
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                error={getError("vorname")}
                                helperText={getHelperText("vorname")}
                                label="Vorname"
                                key={"vorname"}
                                fullWidth
                                value={vorname}
                                onChange={(e) => setVorname(e.target.value)}
                                InputLabelProps={{shrink: true}}
                                variant="filled"
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                error={getError("strasse")}
                                helperText={getHelperText("strasse")}
                                label="Straße"
                                key={"strasse"}
                                fullWidth
                                value={strasse}
                                onChange={(e) => setStrasse(e.target.value)}
                                InputLabelProps={{shrink: true}}
                                variant="filled"
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <TextField
                                error={getError("hausnummer")}
                                helperText={getHelperText("hausnummer")}
                                label="Hausnummer"
                                key={"hausnummer"}
                                fullWidth
                                value={hausnummer}
                                onChange={(e) => setHausnummer(e.target.value)}
                                InputLabelProps={{shrink: true}}
                                variant="filled"
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <TextField
                                error={getError("postleitzahl")}
                                helperText={getHelperText("postleitzahl")}
                                label="Postleitzahl"
                                key={"postleitzahl"}
                                fullWidth
                                value={postleitzahl}
                                onChange={(e) => setPostleitzahl(e.target.value)}
                                InputLabelProps={{shrink: true}}
                                variant="filled"
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <TextField
                                error={getError("ort")}
                                helperText={getHelperText("ort")}
                                label="Ort"
                                key={"ort"}
                                fullWidth
                                value={ort}
                                onChange={(e) => setOrt(e.target.value)}
                                InputLabelProps={{shrink: true}}
                                variant="filled"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                error={getError("adresszusatz")}
                                helperText={getHelperText("adresszusatz")}
                                label="Adresszusatz"
                                key={"adresszusatz"}
                                fullWidth
                                value={adresszusatz}
                                onChange={(e) => setAdresszusatz(e.target.value)}
                                InputLabelProps={{shrink: true}}
                                variant="filled"
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                error={getError("telefon")}
                                helperText={getHelperText("telefon")}
                                label="Telefonnummer"
                                key={"telefon"}
                                fullWidth
                                value={telefon}
                                onChange={(e) => setTelefon(e.target.value)}
                                InputLabelProps={{shrink: true}}
                                variant="filled"
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                error={getError("email")}
                                helperText={getHelperText("email")}
                                label="Email"
                                key={"email"}
                                fullWidth
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                InputLabelProps={{shrink: true}}
                                variant="filled"
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <DesktopDatePicker
                                slotProps={{
                                    textField: {
                                        variant: 'filled',
                                        fullWidth: true
                                    }
                                }}
                                label="Geburtsdatum"
                                key={"geburtsdatum"}
                                format="DD.MM.YYYY"
                                fullWidth
                                value={moment(geburtsdatum)}
                                onChange={(date) => setGeburtsdatum(date)}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <DesktopDatePicker
                                slotProps={{
                                    textField: {
                                        variant: 'filled',
                                        fullWidth: true
                                    }
                                }}
                                label="Beschäftigungsbeginn"
                                key={"beschaeftigungsbeginn"}
                                format="DD.MM.YYYY"
                                fullWidth
                                value={moment(beschaeftigungsbeginn)}
                                onChange={(date) => setBeschaeftigungsbeginn(date)}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <TextField
                                error={getError("urlaubsanspruch")}
                                helperText={getHelperText("urlaubsanspruch")}
                                label="Urlaubsanspruch"
                                key={"urlaubsanspruch"}
                                fullWidth
                                value={urlaubsanspruch}
                                onChange={(e) => setUrlaubsanspruch(e.target.value)}
                                InputLabelProps={{shrink: true}}
                                variant="filled"
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                error={getError("staatsangehoerigkeit")}
                                helperText={getHelperText("staatsangehoerigkeit")}
                                label="Staatsangehörigkeit"
                                key={"staatsangehoerigkeit"}
                                fullWidth
                                value={staatsangehoerigkeit}
                                onChange={(e) => setStaatsangehoerigkeit(e.target.value)}
                                InputLabelProps={{shrink: true}}
                                variant="filled"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl variant="filled" fullWidth error={getError("beschaeftigung_als")}>
                                <InputLabel id="beschaeftigung_als">Beschäftigung als</InputLabel>
                                <Select
                                    displayEmpty
                                    labelId="beschaeftigung_als"
                                    label={"Beschäftigung als"}
                                    key={"beschaeftigung_als"}
                                    value={beschaeftigung_als}
                                    onChange={(e) => setBeschaeftigung_als(e.target.value)}
                                    input={
                                        <FilledInput
                                            name="beschaeftigung_als"
                                        />
                                    }
                                >
                                    <MenuItem value={"Lüftungsmonteur"}>Lüftungsmonteur</MenuItem>
                                    <MenuItem value={"Heizungs- und Sanitärinstallateur"}>Heizungs- und
                                        Sanitärinstallateur</MenuItem>
                                    <MenuItem
                                        value={"Kälteanlagenmechatroniker"}>Kälteanlagenmechatroniker</MenuItem>
                                    <MenuItem value={"Elektroinstallateur"}>Elektroinstallateur</MenuItem>
                                    <MenuItem value={"MSR-Monteur"}>MSR-Monteur</MenuItem>
                                    <MenuItem value={"Fachhelfer-Lüftung"}>Fachhelfer-Lüftung</MenuItem>
                                    <MenuItem value={"Fachhelfer Heizung/Sanitär"}>Fachhelfer
                                        Heizung/Sanitär</MenuItem>
                                </Select>
                                <FormHelperText>
                                    {getHelperText("beschaeftigung_als")}
                                </FormHelperText>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Box>
            </Paper>
        );
    }

    return (
        <Box>
            <Box>
                <ContentHeader
                    title={routeParams.id ? anrede + " " + vorname + " " + nachname : "Neuen Mitarbeiter anlegen"}
                    subtitle={routeParams.id ? "Mitarbeiter bearbeiten" : "Mitarbeiter hinzufügen"}
                    actions={
                        <Stack direction={'row'} spacing={1}>
                            <Button variant={"contained"} color={"secondary"}
                                    onClick={() => navigate('/employees')}>Zurück</Button>
                            {routeParams.id ? <Button variant={"contained"} color={"primary"}
                                                      onClick={() => handleUpdateEmployee()}
                                                      disabled={loading}>Speichern</Button>
                                :
                                <Button variant={"contained"} color={"primary"}
                                        onClick={() => handleCreateEmployee()} disabled={loading}>Hinzufügen</Button>}
                        </Stack>
                    }
                />
                <Paper>
                    {getFormContent()}
                </Paper>
            </Box>
        </Box>
    )
}

export default EmployeeTemplate;
