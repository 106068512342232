import React, {useState} from "react";
import {Alert, Slide, Snackbar} from "@mui/material";

export const withSnackbar = WrappedComponent => {

    return props => {

        const [open, setOpen] = useState(false);
        const [message, setMessage] = useState("Kein Inhalt");
        const [duration, setDuration] = useState(5000);
        const [severity, setSeverity] = useState(
            "success"
        );
        /** error | warning | info */

        const showMessage = (message, severity, duration) => {
            setMessage(message);
            setSeverity(severity);
            setDuration(duration);
            setOpen(true);
        };

        const handleClose = (event, reason) => {
            if (reason === "clickaway") {
                return;
            }
            setOpen(false);
        };

        return (
            <>
                <WrappedComponent {...props} snackbarShowMessage={showMessage}/>
                <Snackbar
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left"
                    }}
                    autoHideDuration={duration}
                    open={open}
                    onClose={handleClose}
                    TransitionComponent={Slide}
                >
                    <Alert variant="filled" onClose={handleClose} severity={severity}>
                        {message}
                    </Alert>
                </Snackbar>
            </>
        );
    };
};

export default withSnackbar;
