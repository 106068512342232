import {gql} from '@apollo/client';

import {PLAN_ENTITY} from "../entities/planEntity";

export const CREATE_PLAN = gql`
  mutation createPlan($input: PlanInput!) {
    createPlan(input: $input){
      ...PlanEntity
    }
  }
  ${PLAN_ENTITY}
`;