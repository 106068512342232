import React, {useLayoutEffect} from "react";

import * as am5 from "@amcharts/amcharts5";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

//chart type
import * as am5percent from "@amcharts/amcharts5/percent";
import {Box} from "@mui/material";

function EmployeePieChart(props) {

    //dataset
    let data = [
        {
            category: "Umsatzbringend",
            value: props.umsatzbringend,
            color: '#3bde5e',
        },
        {
            category: "Ohne Vertrag",
            value: props.noContract,
            color: '#8f1516',//#8f1516
        },
        {
            category: "Krank",
            value: props.diseases,
            color: '#b92425',
        },
        {
            category: "Urlaub",
            value: props.vacations,
            color: '#de3b3c',
        },
    ];

    //const chart = useRef(null);
    const chartID = props.chartID;

    useLayoutEffect(() => {
        //var root = am5.Root.new("chartdiv2");
        var root = am5.Root.new(chartID);

        // Set themes
        // https://www.amcharts.com/docs/v5/concepts/themes/
        var myTheme = am5.Theme.new(root);
        myTheme.rule("Label").set("fontSize", "1rem");
        root.setThemes([am5themes_Animated.new(root), myTheme]);

        // Create chart
        // https://www.amcharts.com/docs/v5/charts/percent-charts/pie-chart/
        var chart = root.container.children.push(
            am5percent.PieChart.new(root, {
                innerRadius: am5.percent(50),
                layout: root.verticalLayout,
            })
        );

        // Create series
        // https://www.amcharts.com/docs/v5/charts/percent-charts/pie-chart/#Series
        var series = chart.series.push(
            am5percent.PieSeries.new(root, {
                valueField: "value",
                categoryField: "category",
                fillField: "color",
                alignLabels: false,
            })
        );

        series.labels.template.setAll({
            text: "{category}: {value}",
            inside: true,
            radius: 0,
            relativeRotation: 90,
            fill: am5.color(0xffffff)
        });

        series.events.on("datavalidated", function () {
            am5.array.each(series.dataItems, function (dataItem) {
                if (dataItem.get("value") == 0) {
                    dataItem.hide();
                }
            })
        });

        // Set data
        // https://www.amcharts.com/docs/v5/charts/percent-charts/pie-chart/#Setting_data
        series.appear(1000, 0);
        series.data.setAll(data);

        //series.labels.template.set("visible", false);
        series.ticks.template.set("visible", false);
        series.ticks.template.set("stroke", am5.color(0xffffff))

        return () => {
            root.dispose();
        };
    }, [props.umsatzbringend]);

    return (
        <Box>
            <div style={{height: "350px", width: "100%"}} id={chartID}></div>
        </Box>
    );
}

export default EmployeePieChart;