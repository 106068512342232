import React, {useState} from 'react';
import Timeline from 'react-calendar-timeline'
import 'react-calendar-timeline/lib/Timeline.css'
import './style.css';
import 'react-giphy-select/lib/styles.css';
import moment from "moment";
import ContentHeader from "../common/ContentHeader";
import TimelineMarkers from "react-calendar-timeline/lib/lib/markers/public/TimelineMarkers";
import CustomMarker from "react-calendar-timeline/lib/lib/markers/public/CustomMarker";
import {Autocomplete, Box, Button, Checkbox, FormControlLabel, Paper, Stack, TextField} from "@mui/material";
import styles from './calendar.module.css'
import {useDiseases} from "../../apollo/hooks/useDiseases";
import {useEmployees} from "../../apollo/hooks/useEmployees";
import {useVacations} from "../../apollo/hooks/useVacations";
import {usePlans} from "../../apollo/hooks/usePlans";
import {FormattedMessage} from "react-intl";

const Calendar = () => {

    const {
        diseases,
        addDisease,
        updateDisease,
        removeDisease,
        error: diseasesError
    } = useDiseases()
    const {
        vacations,
        addVacation,
        updateVacation,
        removeVacation,
        error: vacationsError
    } = useVacations()
    const {plans, error: plansError} = usePlans()
    const {enabledEmployees, loading: employeesLoading, error: employeesError} = useEmployees()
    const [disableMissClick, setDisableMissClick] = useState(false)
    const [page, setPage] = useState(0)
    const [showPlans, setShowPlans] = useState(false)
    const [selectedEmployee, setSelectedEmployee] = React.useState(null);

    if (employeesLoading) return (<p>Loading...</p>);
    if (diseasesError || employeesError || vacationsError || plansError) return (<p>Error...</p>);

    let sortedEmployees = enabledEmployees.sort((a, b) => a.employeeId - b.employeeId)
    const handleSelectEmployee = (event, index) => {
        if (index) {
            setSelectedEmployee(sortedEmployees.filter(x => x._id === index._id)[0]);
        } else {
            setSelectedEmployee(null)
        }
    }

    const loadGroups = () => {
        var elements = []
        let array = selectedEmployee ? [selectedEmployee] : enabledEmployees
        array.map(employee => {
            var element = {}
            element.id = employee._id
            element.title = employee.employeeId + " | " + employee.nachname + ", " + employee.vorname
            elements.push(element)
            return elements
        })
        return elements
    }

    const itemRenderer = ({item, timelineContext, itemContext, getItemProps, getResizeProps}) => {
        const {left: leftResizeProps, right: rightResizeProps} = getResizeProps();
        const backgroundColor = itemContext.selected ? item.selectedBgColor : item.bgColor;
        return (
            <div
                {...getItemProps({
                    style: {
                        backgroundColor,
                        color: item.color,
                    },
                })}
            >
                {itemContext.useResizeHandle && itemContext.selected ? <div {...leftResizeProps}/> : null}

                <div
                    style={{
                        height: itemContext.dimensions.height,
                        overflow: "hidden",
                        paddingLeft: 3,
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap"
                    }}
                >
                    {itemContext.title}
                </div>

                {itemContext.useResizeHandle && itemContext.selected ? <div {...rightResizeProps}/> : null}
            </div>
        );
    };

    const loadItems = () => {
        var diseasesArray = []
        var vacationsArray = []
        var plansArray = []

        plans?.map(plan => {
            var planItem = {}
            planItem.id = plan._id
            planItem.group = plan.employee._id
            planItem.title = plan.customer.name
            planItem.start_time = moment(plan.startdate).startOf('day')
            planItem.end_time = plan.enddate ? moment(plan.enddate).endOf('day') : moment(plan.startdate).add(9, 'month').endOf('day')
            planItem.canResize = "none"
            planItem.canMove = false
            planItem.canChangeGroup = false
            planItem.useResizeHandle = false
            planItem.canSelect = false
            planItem.bgColor = "#263c54"
            planItem.color = "#ffffff"
            planItem.type = 'allowed'
            plansArray.push(planItem)
        })

        vacations?.map(vacation => {
            var vacationItem = {}
            vacationItem.id = vacation._id
            vacationItem.group = vacation.employee._id
            vacationItem.start_time = moment(vacation.startdate).startOf('day')
            vacationItem.end_time = moment(vacation.enddate).endOf('day')
            vacationItem.canResize = "both"
            vacationItem.canMove = true
            vacationItem.canChangeGroup = false
            vacationItem.useResizeHandle = true
            vacationItem.canSelect = page !== 1 ? false : true
            vacationItem.bgColor = page !== 1 ? "#4d6990" : "#DC143C"
            vacationItem.color = "#ffffff"
            vacationItem.selectedBgColor = "#00b6f5"
            vacationItem.title = "Urlaub"
            vacationItem.type = vacation.type
            vacationsArray.push(vacationItem)
        })

        diseases?.map(disease => {
            var diseaseItem = {}
            diseaseItem.id = disease._id
            diseaseItem.group = disease.employee._id
            diseaseItem.start_time = moment(disease.startdate).startOf('day')
            diseaseItem.end_time = moment(disease.enddate).endOf('day')
            diseaseItem.canResize = "both"
            diseaseItem.canMove = true
            diseaseItem.canChangeGroup = false
            diseaseItem.useResizeHandle = true
            diseaseItem.canSelect = page !== 0 ? false : true
            diseaseItem.bgColor = page !== 0 ? "#4d6990" : "#DC143C"
            diseaseItem.color = "#ffffff"
            diseaseItem.selectedBgColor = "#00b6f5"
            diseaseItem.title = "Krank"
            diseaseItem.type = disease.type
            diseasesArray.push(diseaseItem)
        })
        return showPlans ? diseasesArray.concat(vacationsArray).concat(plansArray) : diseasesArray.concat(vacationsArray)
    }

    const handleItemResize = (itemId, time, edge) => {
        const filteredItem = page === 0 ? diseases.filter(disease => disease._id === itemId)[0] : vacations.filter(vacation => vacation._id === itemId)[0]
        const payload = {
            employee: filteredItem?.employee._id,
            startdate: edge === "left" ? moment(time).add(1, 'days').startOf('day') : filteredItem?.startdate,
            enddate: edge === "right" ? moment(time).startOf('day') : filteredItem?.enddate,
            type: filteredItem?.type
        }
        page === 0 ? updateDisease(payload, itemId) : updateVacation(payload, itemId)
    };

    const handleItemMove = (itemId, dragTime, newGroupOrder) => {
        const filteredItem = page === 0 ? diseases.filter(disease => disease._id === itemId)[0] : vacations.filter(vacation => vacation._id === itemId)[0]
        const difference = moment(dragTime).startOf('day').diff(moment(filteredItem.startdate), 'days')
        const payload = {
            employee: filteredItem.employee._id,
            startdate: moment(dragTime).startOf('day'),
            enddate: moment(filteredItem.enddate).add(difference, 'days').endOf('day'),
            type: filteredItem?.type
        }
        page === 0 ? updateDisease(payload, itemId) : updateVacation(payload, itemId)
    }

    const handleAddItem = (groupId, time) => {
        const payload = {
            employee: groupId,
            startdate: moment(time).startOf('day'),
            enddate: moment(time).startOf('day').add(4, "days"),
            type: "allowed"
        }
        page === 0 ? addDisease(payload) : addVacation(payload)
    }

    const handleRemoveDisease = (id) => {
        setDisableMissClick(false)
        page === 0 ? removeDisease(id) : removeVacation(id)
    }

    return (
        <Box className={styles.diseaseContainer}>
            <ContentHeader
                title={page === 0 ? "Krankentage" : "Urlaubstage"}
                subtitle={page === 0 ? diseases.length + " Eintragungen" : vacations.length + " Eintragungen"}
                actions={
                    <Stack direction={'row'} spacing={1} alignItems={'center'}>
                        <Autocomplete
                            style={{width: '300px'}}
                            id="employee"
                            onChange={handleSelectEmployee}
                            options={sortedEmployees}
                            getOptionLabel={option => option.employeeId + " - " + option.vorname + " " + option.nachname}
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    label={<FormattedMessage id="employees.search"/>}
                                    variant="filled"
                                    size={"small"}
                                />
                            )}
                        />
                        <FormControlLabel control={<Checkbox onClick={(event) => setShowPlans(event.target.checked)} value={showPlans}/>} label="Überlassungen anzeigen" />
                        <Button variant={'contained'} color={'primary'} disabled={page !== 1} onClick={() => setPage(0)}>Krankentage</Button>
                        <Button variant={'contained'} color={'primary'} disabled={page !== 0} onClick={() => setPage(1)}>Urlaubstage</Button>
                    </Stack>
                }
            />
            <Paper className={styles.timeline}>
                <Timeline
                    onCanvasClick={(groupId, time) => !disableMissClick && handleAddItem(groupId, time)}
                    onItemContextMenu={(itemId) => handleRemoveDisease(itemId)}
                    onItemResize={(itemId, time, edge) => handleItemResize(itemId, time, edge)}
                    onItemMove={(itemId, dragTime, newGroupOrder) => handleItemMove(itemId, dragTime, newGroupOrder)}
                    onItemSelect={() => setDisableMissClick(true)}
                    onItemDeselect={() => setDisableMissClick(false)}
                    groups={loadGroups()}
                    items={loadItems()}
                    fullUpdate
                    itemRenderer={itemRenderer}
                    defaultTimeStart={moment().startOf('month')}
                    defaultTimeEnd={moment().endOf('month')}
                    sidebarWidth={220}
                    lineHeight={35}
                    dragSnap={24 * 60 * 60 * 1000}
                    useResizeHandle={true}
                    stackItems={true}
                    timeSteps={{
                        second: 0,
                        minute: 0,
                        hour: 0,
                        day: 1,
                        month: 1,
                        year: 1,
                    }}
                >
                    <TimelineMarkers>
                        <CustomMarker date={moment()}>
                            {/* custom renderer for this marker */}
                            {({styles}) => {
                                const customStyles = {
                                    ...styles,
                                    backgroundColor: '#4d6990',
                                    width: '3px'
                                }
                                return <div style={customStyles}/>
                            }}
                        </CustomMarker>
                    </TimelineMarkers>
                </Timeline>
            </Paper>
        </Box>
    )
}

export default Calendar;
