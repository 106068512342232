import {useMutation, useQuery, useSubscription} from '@apollo/client'
import {GET_ALL_NOTIFICATIONS} from "../queries/getAllNotifications";
import {ON_NOTIFICATIONS_CHANGED} from "../subscriptions/onNotificationsChanged";
import {CREATE_NOTIFICATION} from "../mutations/createNotification";
import {REMOVE_NOTIFICATION} from "../mutations/removeNotification";
import {REVIEW_NOTIFICATION} from "../mutations/reviewNotification";
import {useUsers} from "./useUsers";

export const useNotifications = () => {

    const {me} = useUsers()
    const fetchQuery = GET_ALL_NOTIFICATIONS

    const {
        data: dataQuery,
        loading: loadingQuery,
        error: errorQuery,
        refetch: refetchQuery,
    } = useQuery(fetchQuery, {
        fetchPolicy: 'no-cache'
    })
    const {data: allNotificationsSubData} = useSubscription(ON_NOTIFICATIONS_CHANGED, {
        fetchPolicy: 'no-cache'
    });

    let notificationData = allNotificationsSubData ? allNotificationsSubData?.onNotificationsChanged : dataQuery?.getAllNotifications;

    const [add, {error: errorAdd}] = useMutation(
        CREATE_NOTIFICATION,
        {
            errorPolicy: "all",
            fetchPolicy: 'no-cache',
            onCompleted: (res) => {
                if(!res) return
                refetchQuery()
                console.log('notification created!')
            },
        },
    );

    const [review, {error: errorReview, loading: loadingReview}] = useMutation(
        REVIEW_NOTIFICATION,
        {
            errorPolicy: "all",
            fetchPolicy: 'no-cache',
            onCompleted: (res) => {
                if(!res) return
                refetchQuery()
                console.log('notification reviewed!')
            },
        },
    );

    const [remove, {error: errorRemove, loading: loadingRemove}] = useMutation(
        REMOVE_NOTIFICATION,
        {
            errorPolicy: "all",
            fetchPolicy: 'no-cache',
            onCompleted: (res) => {
                if(!res) return
                refetchQuery()
                console.log('notification removed!')
            },
        },
    );

    return {
        notifications: notificationData,
        loading: loadingQuery,
        loadingRemove: loadingRemove,
        loadingReview: loadingReview,
        error: errorQuery || errorAdd || errorRemove || errorReview,
        addNotification: (message, category) => add({
            variables: {
                input: {
                    message: message,
                    user: me.me._id,
                    duration: 5000,
                    severity: 'info',
                    category: category,
                    reviewed: [me.me._id]
                }
            }
        }),
        reviewNotification: (id, user) => review({
            variables: {
                _id: id,
                user: user
            }
        }),
        removeNotification: (id) => remove({
            variables: {
                _id: id
            }
        })
    }
}