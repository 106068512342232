import {gql} from '@apollo/client';
import {CUSTOMER_ENTITY} from "../entities/customerEntity";

export const UPDATE_CUSTOMER = gql`
  mutation updateCustomer($input: CustomerInput!, $_id: String!) {
    updateCustomer(input: $input, _id: $_id){
        ...CustomerEntity
    }
  }
  ${CUSTOMER_ENTITY}
`;
