import React, {useState} from 'react';
import {Backdrop, Box, Fab, Popover} from "@mui/material";
import {FiMoreVertical} from "react-icons/fi";
import styles from './popovermenu.module.css'

const PopoverMenu = (props) => {

    const [open, setOpen] = useState(false)
    const [anchorEl, setAnchorEl] = useState(false)

    const handleOpenPopover = (event) => {
        setOpen(true)
        setAnchorEl(event.currentTarget)
    }

    const handleClosePopover = (event) => {
        setOpen(false)
        setAnchorEl(false)
    }

    return (
        <Box>
            <Fab variant={'contained'}
                 onClick={(event) => handleOpenPopover(event)} size="small" color={'info'}>
                {props.icon ? props.icon : <FiMoreVertical/>}
            </Fab>
            <Backdrop
                open={open}
                onClick={() => handleClosePopover()}
            >
                <Popover
                    sx={{ zIndex: 20 }}
                    elevation={5}
                    open={open}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                >
                    <Box p={0} className={styles.container}>
                        {props.children}
                    </Box>
                </Popover>
            </Backdrop>
        </Box>

    )
        ;
}

export default PopoverMenu;
