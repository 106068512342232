import {gql} from '@apollo/client';

export const MESSAGE_ENTITY = gql`
  fragment MessageEntity on MessageEntity {
    _id,
    message,
    gif,
    creationDate,
    updated_at
  }
`;
