import {gql} from '@apollo/client';
import {USER_ENTITY} from "../entities/userEntity";

export const CREATE_USER = gql`
  mutation registerUser($input: UserInput!) {
    registerUser(input: $input){
        ...UserEntity
    }
  }
  ${USER_ENTITY}
`;