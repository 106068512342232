// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.panelcontent_pageWrapper__TMqNB {
    z-index: 1;
    margin-bottom: 5rem;
}
.panelcontent_sidebarWrapper__\\+a0rl {
    white-space: pre-wrap;
    z-index: 1;
}
.panelcontent_contentWrapper__9wfKO {
    white-space: pre-wrap;
    z-index: 1;
    flex-grow: 1;
    overflow: hidden;
}
`, "",{"version":3,"sources":["webpack://./src/views/common/PanelContent/panelcontent.module.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,mBAAmB;AACvB;AACA;IACI,qBAAqB;IACrB,UAAU;AACd;AACA;IACI,qBAAqB;IACrB,UAAU;IACV,YAAY;IACZ,gBAAgB;AACpB","sourcesContent":[".pageWrapper {\n    z-index: 1;\n    margin-bottom: 5rem;\n}\n.sidebarWrapper {\n    white-space: pre-wrap;\n    z-index: 1;\n}\n.contentWrapper {\n    white-space: pre-wrap;\n    z-index: 1;\n    flex-grow: 1;\n    overflow: hidden;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pageWrapper": `panelcontent_pageWrapper__TMqNB`,
	"sidebarWrapper": `panelcontent_sidebarWrapper__+a0rl`,
	"contentWrapper": `panelcontent_contentWrapper__9wfKO`
};
export default ___CSS_LOADER_EXPORT___;
