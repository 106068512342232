import { gql } from '@apollo/client';

import {USER_ENTITY} from "../entities/userEntity";
import {MESSAGE_ENTITY} from "../entities/messageEntity";

export const ON_MESSAGES_CHANGED = gql`
  subscription {
    onMessagesChanged {
        ...MessageEntity
        user {
            ...UserEntity
        }
        target {
            ...UserEntity
        }
    }
  }
  ${USER_ENTITY},${MESSAGE_ENTITY}
`;
