import {gql} from '@apollo/client';
import {CUSTOMER_ENTITY} from "../entities/customerEntity";
import {CONTACT_ENTITY} from "../entities/contactEntity";

export const ON_CONTACTS_CHANGED = gql`
  subscription {
    onContactsChanged {
        ...ContactEntity
        customer {
          ...CustomerEntity
        }
    }
  }
  ${CONTACT_ENTITY},${CUSTOMER_ENTITY}
`;
