import React from 'react';
import moment from "moment-business-days";
import GetEmployee from "../common/Essentials/GetEmployee";
import GetCustomer from "../common/Essentials/GetCustomer";
import {
    Box,
    Card,
    CardContent,
    Grid,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    Paper,
    Stack,
    Tab,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Tabs,
    Typography
} from "@mui/material";
import EmployeePieChart from "./EmployeePieChart";
import {isInKw} from "../../filters";
import {FaLongArrowAltRight} from "react-icons/fa";
import {useNavigate} from "react-router-dom";
import styles from './overview.module.css'
import PlanCard from "../Plans/PlanCard";
import PopoverMenu from "../common/PopoverMenu/PopoverMenu";
import ContentHeader from "../common/ContentHeader";
import {useEmployees} from "../../apollo/hooks/useEmployees";
import {usePlans} from "../../apollo/hooks/usePlans";
import {useDiseases} from "../../apollo/hooks/useDiseases";
import {useVacations} from "../../apollo/hooks/useVacations";

function TabPanel(props) {
    const {children, value, index} = props;

    return (
        <Box>
            {value === index && children}
        </Box>
    );
}

const Overview = () => {

    const navigate = useNavigate()

    const [contractTab, setContractTab] = React.useState(1);
    const [selectedCalenderWeek, setSelectedCalenderWeek] = React.useState(`${moment().tz("UTC").startOf('day')}`);

    const {diseases, loading: diseasesLoading, error: diseasesError} = useDiseases()
    const {vacations, loading: vacationsLoading, error: vacationsError} = useVacations()
    const {plans, loading: plansLoading, error: plansError} = usePlans()
    const {enabledEmployees, employeesWithBox, employeesWithoutBox, error: employeesError, loading: employeesLoading} = useEmployees()

    if (diseasesError || vacationsError || employeesError || plansError) return <p>Error...</p>
    if (diseasesLoading || vacationsLoading || employeesLoading || plansLoading) return <p>Loading...</p>

    // Überlassungen, die in einer definierten KW liegen
    const withContract = plans.filter(plan => isInKw(plan?.startdate, plan?.enddate, selectedCalenderWeek))
    var withoutContract = enabledEmployees.filter(function (objOne) {
        return !withContract.some(function (objTwo) {
            return objOne._id == objTwo.employee._id;
        });
    });

    const vacationsWithContract = vacations.filter(vacation => isInKw(vacation?.startdate, vacation?.enddate, selectedCalenderWeek))
        .filter(vacation => vacation.employee._id !== withContract.some(contract => contract.employee._id))
    const diseasesWithContract = diseases.filter(disease => isInKw(disease?.startdate, disease?.enddate, selectedCalenderWeek))
        .filter(disease => disease.employee._id !== withContract.some(contract => contract.employee._id))

    const umsatzbringend = enabledEmployees.length - withoutContract.length - diseasesWithContract.length - vacationsWithContract.length

    const handleChangeContractTab = (event, newValue) => {
        setContractTab(newValue);
    };

    const renderDiseases = () => {
        return diseases.filter(disease =>
            isInKw(disease?.startdate, disease?.enddate, selectedCalenderWeek)
        ).map((disease, i) =>
            <TableRow key={i}>
                <TableCell><GetEmployee employee={enabledEmployees.find(employee => disease.employee._id === employee._id)}/></TableCell>
                <TableCell>
                    <Paper>
                        <Stack p={1} direction={'row'} divider={<FaLongArrowAltRight/>}
                               alignItems={'center'} justifyContent={'space-between'}>
                            <Stack direction={'column'}>
                                <Typography variant={'body2'}>Start</Typography>
                                <Typography color={'inherit'}
                                            variant={'body2'}>{moment(disease.startdate).format('DD.MM.yyyy')}</Typography>
                            </Stack>
                            <Stack direction={'column'}>
                                <Typography variant={'body2'}>Ende</Typography>
                                <Typography color={'inherit'}
                                            variant={'body2'}>{disease.enddate ? moment(disease.enddate).format('DD.MM.yyyy') : "Kein Ende"}</Typography>
                            </Stack>
                        </Stack>
                    </Paper>
                </TableCell>
            </TableRow>
        )
    }

    const renderVacations = () => {
        return vacations.filter(vacation =>
            isInKw(vacation?.startdate, vacation?.enddate, selectedCalenderWeek)
        ).map((vacation, i) =>
            <TableRow key={i}>
                <TableCell><GetEmployee employee={enabledEmployees.find(employee => vacation.employee._id === employee._id)}/></TableCell>
                <TableCell>
                    <Paper>
                        <Stack p={1} direction={'row'} divider={<FaLongArrowAltRight/>}
                               alignItems={'center'} justifyContent={'space-between'}>
                            <Stack direction={'column'}>
                                <Typography variant={'body2'}>Start</Typography>
                                <Typography color={'inherit'}
                                            variant={'body2'}>{moment(vacation.startdate).format('DD.MM.yyyy')}</Typography>
                            </Stack>
                            <Stack direction={'column'}>
                                <Typography variant={'body2'}>Ende</Typography>
                                <Typography color={'inherit'}
                                            variant={'body2'}>{vacation.enddate ? moment(vacation.enddate).startOf('day').format('DD.MM.yyyy') : "Kein Ende"}</Typography>
                            </Stack>
                        </Stack>
                    </Paper>
                </TableCell>
            </TableRow>
        )
    }

    const renderContracts = () => {
        return withContract.map((plan, i) =>
            <TableRow key={i}>
                <TableCell><GetEmployee employee={enabledEmployees.find(employee => plan.employee._id === employee._id)}/></TableCell>
                <TableCell style={{maxWidth: "250px"}}><GetCustomer customer={plan.customer}/></TableCell>
                <TableCell>
                    <PlanCard disableMenu startdate={plan.startdate} enddate={plan.enddate}/>
                </TableCell>
            </TableRow>
        )
    }

    const renderNoContracts = () => {
        return withoutContract.map((employee, i) =>
            <TableRow key={i}>
                <TableCell>
                    <GetEmployee employee={employee}/>
                </TableCell>
                <TableCell align={'right'}>
                    {renderContractButton(employee)}
                </TableCell>
            </TableRow>
        )
    }

    const renderWithBox = () => {
        return employeesWithBox.map((employee, i) =>
            <TableRow key={i}>
                <TableCell>
                    <GetEmployee employee={employee}/>
                </TableCell>
                <TableCell align={'right'}>
                    {renderContractButton(employee)}
                </TableCell>
            </TableRow>
        )
    }

    const renderWithoutBox = () => {
        return employeesWithoutBox.map((employee, i) =>
            <TableRow key={i}>
                <TableCell>
                    <GetEmployee employee={employee}/>
                </TableCell>
                <TableCell align={'right'}>
                    {renderContractButton(employee)}
                </TableCell>
            </TableRow>
        )
    }

    const renderAllEmployees = () => {
        return enabledEmployees
            .map((employee, i) =>
                <TableRow key={i}>
                    <TableCell>
                        <GetEmployee employee={employee}/>
                    </TableCell>
                    <TableCell align={'right'}>
                        {renderContractButton(employee)}
                    </TableCell>
                </TableRow>
            )
    }

    const renderContractButton = (employee) => {
        return (
            <PopoverMenu>
                <List dense style={{padding: 0}}>
                    <ListItem disablePadding>
                        <ListItemButton onClick={() => navigate('/employees/' + employee._id + '/edit')}>
                            <ListItemText primary="Bearbeiten"/>
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton onClick={() => navigate('/plans/create/' + employee._id)}>
                            <ListItemText primary='Vertrag erstellen'/>
                        </ListItemButton>
                    </ListItem>
                </List>
            </PopoverMenu>
        )
    }

    const renderTabs = () => {
        let tabs = []
        for (var i = -3; i <= 3; i++) {
            if (i < 0) {
                tabs.push(
                    <Tab
                        value={`${moment().tz("UTC").subtract(-i, "week").startOf('day')}`}
                        key={i}
                        label={
                            <Stack direction={'column'}>
                                <Typography
                                    variant={'h7'}>{"KW " + moment().tz("UTC").subtract(-i, "week").isoWeek()}</Typography>
                                <Typography
                                    variant={'subtitle2'}>{moment().tz("UTC").subtract(-i, 'week').startOf('week').format('L') + " - " + moment().tz("UTC").subtract(-i, 'week').endOf('week').format('L')}</Typography>
                            </Stack>
                        }
                    />
                )
            } else if (i > 0) {
                tabs.push(
                    <Tab
                        value={`${moment().tz("UTC").add(i, "week").startOf('day')}`}
                        key={i}
                        label={
                            <Stack direction={'column'}>
                                <Typography
                                    variant={'h7'}>{"KW " + moment().tz("UTC").add(i, "week").isoWeek()}</Typography>
                                <Typography
                                    variant={'subtitle2'}>{moment().tz("UTC").add(i, 'week').startOf('week').format('L') + " - " + moment().tz("UTC").add(i, 'week').endOf('week').format('L')}</Typography>
                            </Stack>
                        }
                    />
                )
            } else {
                tabs.push(
                    <Tab
                        value={`${moment().tz("UTC").startOf('day')}`}
                        key={i}
                        label={
                            <Stack direction={'column'}>
                                <Typography color={'#ffffff'}
                                            variant={'h7'}>{"KW " + moment().tz("UTC").isoWeek()}</Typography>
                                <Typography color={'#ffffff'}
                                            variant={'subtitle2'}>{moment().tz("UTC").startOf('week').format('L') + " - " + moment().tz("UTC").endOf('week').format('L')}</Typography>
                            </Stack>
                        }
                    />
                )
            }
        }
        return tabs
    }

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Tabs
                    centered
                    indicatorColor="primary"
                    textColor="primary"
                    value={selectedCalenderWeek}
                    onChange={(event, value) => setSelectedCalenderWeek(value)}
                >
                    {renderTabs()}
                </Tabs>
            </Grid>
            <Grid item md={8} xs={12}>
                <Tabs
                    indicatorColor="primary"
                    textColor="primary"
                    value={contractTab}
                    onChange={handleChangeContractTab}
                >
                    <Tab key={0} label={
                        <Stack direction={'column'}>
                            <Typography color={'inherit'} variant={'h6'}>
                                {enabledEmployees.length}
                            </Typography>
                            <Typography variant={'caption'} color={'inherit'}>
                                Alle Mitarbeiter
                            </Typography>
                        </Stack>
                    }/>
                    <Tab key={1} label={
                        <Stack direction={'column'}>
                            <Typography color={'inherit'} variant={'h6'}>
                                {withContract.length}
                            </Typography>
                            <Typography variant={'caption'} color={'inherit'}>
                                Mit Vertrag
                            </Typography>
                        </Stack>
                    }/>
                    <Tab key={2} label={
                        <Stack direction={'column'}>
                            <Typography color={'inherit'} variant={'h6'}>
                                {withoutContract.length}
                            </Typography>
                            <Typography variant={'caption'} color={'inherit'}>
                                Ohne Vertrag
                            </Typography>
                        </Stack>
                    }/>
                    <Tab key={3} label={
                        <Stack direction={'column'}>
                            <Typography color={'inherit'} variant={'h6'}>
                                {diseasesWithContract.length}
                            </Typography>
                            <Typography variant={'caption'} color={'inherit'}>
                                Krank
                            </Typography>
                        </Stack>
                    }/>
                    <Tab key={4} label={
                        <Stack direction={'column'}>
                            <Typography color={'inherit'} variant={'h6'}>
                                {vacationsWithContract.length}
                            </Typography>
                            <Typography variant={'caption'} color={'inherit'}>
                                Urlaub
                            </Typography>
                        </Stack>
                    }/>
                    <Tab key={5} label={
                        <Stack direction={'column'}>
                            <Typography color={'inherit'} variant={'h6'}>
                                {employeesWithBox.length}
                            </Typography>
                            <Typography variant={'caption'} color={'inherit'}>
                                Mit Kiste
                            </Typography>
                        </Stack>
                    }/>
                    <Tab key={5} label={
                        <Stack direction={'column'}>
                            <Typography color={'inherit'} variant={'h6'}>
                                {employeesWithoutBox.length}
                            </Typography>
                            <Typography variant={'caption'} color={'inherit'}>
                                Ohne Kiste
                            </Typography>
                        </Stack>
                    }/>
                </Tabs>
                <Paper style={{borderTopRightRadius: "0px", borderTopLeftRadius: "0px"}}>
                    <TabPanel value={contractTab} index={0}>
                        <TableContainer component={Paper}>
                            <Table size="small">
                                <TableBody>
                                    {renderAllEmployees()}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </TabPanel>
                    <TabPanel value={contractTab} index={1}>
                        <TableContainer component={Paper}>
                            <Table size="small">
                                <TableBody>
                                    {renderContracts()}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </TabPanel>
                    <TabPanel value={contractTab} index={2}>
                        <TableContainer component={Paper}>
                            <Table size="small">
                                <TableBody>
                                    {renderNoContracts()}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </TabPanel>
                    <TabPanel value={contractTab} index={3}>
                        <TableContainer component={Paper}>
                            <Table size="small">
                                <TableBody>
                                    {renderDiseases()}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </TabPanel>
                    <TabPanel value={contractTab} index={4}>
                        <TableContainer component={Paper}>
                            <Table size="small">
                                <TableBody>
                                    {renderVacations()}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </TabPanel>
                    <TabPanel value={contractTab} index={5}>
                        <TableContainer component={Paper}>
                            <Table size="small">
                                <TableBody>
                                    {renderWithBox()}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </TabPanel>
                    <TabPanel value={contractTab} index={6}>
                        <TableContainer component={Paper}>
                            <Table size="small">
                                <TableBody>
                                    {renderWithoutBox()}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </TabPanel>
                </Paper>
            </Grid>
            <Grid item md={4} xs={12} position={'relative'}>
                <Box className={styles.movingContainer}>
                    <Stack direction={'column'} spacing={1}>
                        <Box>
                            <ContentHeader title={'Zusammenfassung'}
                                           subtitle={'Umsatzbringend / Urlaub / Krankentage / Ohne Vertrag'}/>
                            <Paper>
                                <EmployeePieChart chartID={"1234"} umsatzbringend={umsatzbringend}
                                                  noContract={withoutContract.length}
                                                  vacations={vacationsWithContract.length}
                                                  diseases={diseasesWithContract.length}/>
                            </Paper>
                        </Box>
                        <Box>
                            <ContentHeader title={'Arbeitstage'} subtitle={'Arbeitstage der nächsten 6 und der letzten 3 Monate.'}/>
                            <Paper>
                                <Box p={1}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={4} style={{textAlign: "center"}}>
                                            <Card>
                                                <CardContent>
                                                    <Box p={1}>
                                                        <Typography variant="h6" style={{color: '#fff'}}>
                                                            {moment().subtract(3, "month").monthBusinessDays().length}
                                                        </Typography>
                                                        <Typography variant="body2" style={{color: '#fff'}}>
                                                            {moment().subtract(3, "month").format('MMMM YYYY')}
                                                        </Typography>
                                                    </Box>
                                                </CardContent>
                                            </Card>
                                        </Grid>
                                        <Grid item xs={4} style={{textAlign: "center"}}>
                                            <Card>
                                                <CardContent>
                                                    <Box p={1}>
                                                        <Typography variant="h6" style={{color: '#fff'}}>
                                                            {moment().subtract(2, "month").monthBusinessDays().length}
                                                        </Typography>
                                                        <Typography variant="body2" style={{color: '#fff'}}>
                                                            {moment().subtract(2, "month").format('MMMM YYYY')}
                                                        </Typography>
                                                    </Box>
                                                </CardContent>
                                            </Card>
                                        </Grid>
                                        <Grid item xs={4} style={{textAlign: "center"}}>
                                            <Card>
                                                <CardContent>
                                                    <Box p={1}>
                                                        <Typography variant="h6" style={{color: '#fff'}}>
                                                            {moment().subtract(1, "month").monthBusinessDays().length}
                                                        </Typography>
                                                        <Typography variant="body2" style={{color: '#fff'}}>
                                                            {moment().subtract(1, "month").format('MMMM YYYY')}
                                                        </Typography>
                                                    </Box>
                                                </CardContent>
                                            </Card>
                                        </Grid>
                                        <Grid item xs={4} style={{textAlign: "center"}}>
                                            <Card>
                                                <CardContent style={{ boxShadow: "inset 0px 0px 0px 3px #00b6f5" }}>
                                                    <Box p={1}>
                                                        <Typography variant="h6" style={{color: '#fff'}}>
                                                            {moment().monthBusinessDays().length}
                                                        </Typography>
                                                        <Typography variant="body2" style={{color: '#fff'}}>
                                                            {moment().format('MMMM YYYY')}
                                                        </Typography>
                                                    </Box>
                                                </CardContent>
                                            </Card>
                                        </Grid>
                                        <Grid item xs={4} style={{textAlign: "center"}}>
                                            <Card>
                                                <CardContent>
                                                    <Box p={1}>
                                                        <Typography variant="h6" style={{color: '#fff'}}>
                                                            {moment().add(1, "month").monthBusinessDays().length}
                                                        </Typography>
                                                        <Typography variant="body2" style={{color: '#fff'}}>
                                                            {moment().add(1, "month").format('MMMM YYYY')}
                                                        </Typography>
                                                    </Box>
                                                </CardContent>
                                            </Card>
                                        </Grid>
                                        <Grid item xs={4} style={{textAlign: "center"}}>
                                            <Card>
                                                <CardContent>
                                                    <Box p={1}>
                                                        <Typography variant="h6" style={{color: '#fff'}}>
                                                            {moment().add(2, "month").monthBusinessDays().length}
                                                        </Typography>
                                                        <Typography variant="body2" style={{color: '#fff'}}>
                                                            {moment().add(2, "month").format('MMMM YYYY')}
                                                        </Typography>
                                                    </Box>
                                                </CardContent>
                                            </Card>
                                        </Grid>
                                        <Grid item xs={4} style={{textAlign: "center"}}>
                                            <Card>
                                                <CardContent>
                                                    <Box p={1}>
                                                        <Typography variant="h6" style={{color: '#fff'}}>
                                                            {moment().add(3, "month").monthBusinessDays().length}
                                                        </Typography>
                                                        <Typography variant="body2" style={{color: '#fff'}}>
                                                            {moment().add(3, "month").format('MMMM YYYY')}
                                                        </Typography>
                                                    </Box>
                                                </CardContent>
                                            </Card>
                                        </Grid>
                                        <Grid item xs={4} style={{textAlign: "center"}}>
                                            <Card>
                                                <CardContent>
                                                    <Box p={1}>
                                                        <Typography variant="h6" style={{color: '#fff'}}>
                                                            {moment().add(4, "month").monthBusinessDays().length}
                                                        </Typography>
                                                        <Typography variant="body2" style={{color: '#fff'}}>
                                                            {moment().add(4, "month").format('MMMM YYYY')}
                                                        </Typography>
                                                    </Box>
                                                </CardContent>
                                            </Card>
                                        </Grid>
                                        <Grid item xs={4} style={{textAlign: "center"}}>
                                            <Card>
                                                <CardContent>
                                                    <Box p={1}>
                                                        <Typography variant="h6" style={{color: '#fff'}}>
                                                            {moment().add(5, "month").monthBusinessDays().length}
                                                        </Typography>
                                                        <Typography variant="body2" style={{color: '#fff'}}>
                                                            {moment().add(5, "month").format('MMMM YYYY')}
                                                        </Typography>
                                                    </Box>
                                                </CardContent>
                                            </Card>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Paper>
                        </Box>
                    </Stack>
                </Box>
            </Grid>
        </Grid>
    );
}

export default Overview;
