import {gql} from '@apollo/client';

import {PLAN_ENTITY} from "../entities/planEntity";
import {EMPLOYEE_ENTITY} from "../entities/employeeEntity";
import {CUSTOMER_ENTITY} from "../entities/customerEntity";

export const GET_PLAN_BY_ID = gql`
  query getPlanById($_id: String!){
    getPlanById(_id: $_id) {
      ...PlanEntity
      employee {
        ...EmployeeEntity
      }
      customer {
        ...CustomerEntity
      }
    }
  }
  ${PLAN_ENTITY},${EMPLOYEE_ENTITY},${CUSTOMER_ENTITY}
`;
