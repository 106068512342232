import {gql} from '@apollo/client';

export const CONTACT_ENTITY = gql`
  fragment ContactEntity on ContactEntity {
    _id,
    anrede,
    vorname,
    nachname,
    telephone,
    email,
    created_at,
    updated_at
  }
`;
