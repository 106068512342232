import {gql} from '@apollo/client';

import {EMPLOYEE_ENTITY} from "../entities/employeeEntity";
import {DISEASE_ENTITY} from "../entities/diseaseEntity";

export const GET_ALL_DISEASES = gql`
  query getAllDiseases {
    getAllDiseases {
        ...DiseaseEntity
        employee {
          ...EmployeeEntity
        }
    }
  }
  ${DISEASE_ENTITY},${EMPLOYEE_ENTITY}
`;
