import React from 'react';

import './style.css';
import Logo from "../Logo";
import {Grid} from "@mui/material";

const FullpageLoader = (props) => (
  <div className="FullpageLoaderWrapper">
    <Grid container>
      <Grid item xs={12}>
        <div className="FullpageLoader">
          <Logo id="logo" color="#ffffff" />
        </div>
      </Grid>
      <Grid item xs={12}>
        <div className="FullpageLoaderText">{props.error}</div>
      </Grid>
    </Grid>
  </div>
);

export default FullpageLoader;
