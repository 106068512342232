import './twasi-darkblue.css';
import {createTheme} from "@mui/material";

const primaryColor = "#00b6f5";

export default createTheme({
    palette: {
        mode: 'dark',
        background: {
            default: '#061121'
        },
        primary: {
            main: primaryColor,
            contrastText: '#ffffff'
        },
        secondary: {
            main: '#d21f3c',
            contrastText: '#ffffff'
        }
    },
    neutral: {
        color: '#da7720'
    },
    components: {
        MuiPaper: { // Name of the component ⚛️ / style sheet
            styleOverrides: {
                root: { // Name of the rule
                    color: '#ffffff', // Some CSS
                    backgroundColor: '#0d1b2f',
                    borderRadius: '0px',
                    border: '0px',
                    backgroundImage: 'none',
                },
                elevation1: {
                    boxShadow: 'none'
                },
                elevation2: {
                    boxShadow: 'none'
                },
                elevation3: {
                    boxShadow: 'none'
                },
                elevation4: {
                    boxShadow: 'none'
                }
            },
        },
        MuiDrawer: {
            styleOverrides: {
                paper: {
                    background: 'rgba(13,27,47,.5)',
                    backdropFilter: 'blur(10px) saturate(1.3)'
                }
            },
        },
        MuiPickersCalendarHeader: {
            styleOverrides: {
                iconButton: {
                    backgroundColor: "#0d1b2f"
                }
            },
        },
        MuiCardContent: { // Name of the component ⚛️ / style sheet
            styleOverrides: {
                root: { // Name of the rule
                    color: '#afb6c5', // Some CSS
                    backgroundColor: '#2a3c52', // Some CSS
                    borderRadius: '0px',
                    padding: '0px',
                    margin: '0px',
                    '&:last-child': {
                        padding: '0px'
                    }
                }
            },
        },
        MuiMenuItem: { // Name of the component ⚛️ / style sheet
            styleOverrides: {
                root: { // Name of the rule
                    color: '#ffffff', // Some CSS
                    background: "#0d1b2f",
                    padding: '10px',
                    '&:hover': {
                        backgroundColor: '#172840'
                    },
                    '&.Mui-selected': { // Name of the rule
                        color: '#ffffff',
                        backgroundColor: '#172840',
                        borderRadius: '0px',
                        '&:hover': {
                            backgroundColor: '#172840'
                        },
                    }
                },
            },
        },
        MuiPagination: {
            styleOverrides: {
                root: {
                    borderRadius: '50px',
                    padding: '5px 3px',
                    backgroundColor: '#0d1b2f'
                }
            },
        },
        MuiList: {
            styleOverrides: {
                root: {
                    padding: '16px'
                }
            },
        },
        MuiListItem: {
            styleOverrides: {
                secondaryAction: {
                    paddingRight: "80px"
                }
            },
        },
        MuiAccordion: {
            styleOverrides: {
                rounded: {
                    borderRadius: '4px'
                },
                root: {
                    marginTop: '2px',
                    '&:before': {
                        opacity: '0'
                    }
                }
            },
        },
        MuiListItemText: { // Name of the component ⚛️ / style sheet
            styleOverrides: {
                root: { // Name of the rule
                    color: '#afb6c5'
                },
                primary: {
                    color: '#ffffff'
                }
            },
        },
        MuiButton: { // Name of the component ⚛️ / style sheet
            styleOverrides: {
                root: { // Name of the rule
                    textTransform: 'none', // Some CSS
                    color: '#ffffff', // Some CSS
                    borderRadius: '4px'
                },
                containedPrimary: {
                    boxShadow: 'none',
                    backgroundColor: primaryColor,
                    '&$disabled': {
                        background: '#0d1b2f',
                        color: '#afb6c5'
                    }
                },
                containedSecondary: {
                    boxShadow: 'none',
                    background: '#d21f3c',
                    '&$disabled': {
                        background: '#0d1b2f',
                        color: '#afb6c5'
                    }
                },
                outlinedPrimary: {
                    borderWidth: '1px',
                    '&:hover': {
                        borderWidth: '1px'
                    }
                },
                outlinedSecondary: {
                    borderWidth: '1px',
                    '&:hover': {
                        borderWidth: '1px'
                    }
                },
                contained: {
                    boxShadow: 'none',
                    backgroundColor: '#0d1b2f',
                    color: '#ffffff',
                    '&$disabled': {
                        backgroundColor: '#0d1b2f',
                        color: '#afb6c5'
                    },
                    '&:hover': {
                        backgroundColor: '#1a2035'
                    }
                }
            },
        },
        MuiToggleButtonGroup: {
            styleOverrides: {
                root: {
                    "&$selected": {
                        backgroundColor: "transparent",
                        boxShadow: "none"
                    }
                }
            },
        },
        MuiToggleButton: {
            styleOverrides: {
                label: {
                    textTransform: 'none'
                },
                root: {
                    '&$selected': {
                        backgroundColor: primaryColor
                    }
                }
            },
        },
        MuiFab: {
            styleOverrides: {
                info: {
                    background: 'transparent',
                    color: '#ffffff',
                    '&:hover': {
                        backgroundColor: '#4d6990',
                    }
                },
                root: {
                    fontSize: '1.5rem',
                    boxShadow: 'none',
                    '&:hover': {
                        boxShadow: 'none',
                    }
                },
                sizeMedium: {
                  fontSize: '1.5rem'
                },
                sizeSmall: {
                    height: "36px",
                    width: "36px"
                }
            },
        },
        MuiTableCell: { // Name of the component ⚛️ / style sheet
            styleOverrides: {
                head: {
                    color: '#afb6c5'
                },
                body: { // Name of the rule
                    color: '#afb6c5', // Some CSS
                    borderColor: 'transparent',
                    padding: '16px'
                },
                root: {
                    padding: '16px',
                    borderBottom: '0px'
                }
            },
        },
        MuiTableRow: {
            styleOverrides: {
                root: {
                    '&:nth-of-type(even)': {
                        backgroundColor: '#172840'
                    }
                }
            },
        },
        MuiInputAdornment: { // Name of the component ⚛️ / style sheet
            styleOverrides: {
                root: { // Name of the rule
                    backgroundColor: '#0d1b2f' // Some CSS
                }
            },
        },
        MuiTabs: { // Name of the component ⚛️ / style sheet
            styleOverrides: {
                root: { // Name of the rule
                    background: "rgba(13,27,47,.5)",
                    backdropFilter: "blur(10px) saturate(1.3)"
                }
            },
        },
        MuiTab: { // Name of the component ⚛️ / style sheet
            styleOverrides: {
                root: { // Name of the rule
                    textTransform: 'none',
                    color: '#ffffff',
                    '&.Mui-selected': {
                        color: '#ffffff',
                        background: 'rgba(0,182,245,0.2)',
                        borderBottom: 'none'
                    },
                },
                textColorPrimary: {
                    '&$selected': {
                        color: '#ffffff'
                    },
                }
            },
        },
        MuiChip: { // Name of the component ⚛️ / style sheet
            styleOverrides: {
                root: { // Name of the rule
                    backgroundColor: '#0d1b2f', // Some CSS
                    height: "18px",
                    borderRadius: "4px"
                },
                label: {
                    paddingLeft: "8px",
                    paddingRight: "8px",
                    fontSize: "11px"
                },
                colorPrimary: {
                    backgroundColor: primaryColor
                },
                colorSecondary: {
                    background: 'linear-gradient(135deg,#de6464,#ff7b7b)'
                }
            },
        },
        MuiAvatar: { // Name of the component ⚛️ / style sheet
            styleOverrides: {
                colorDefault: { // Name of the rule
                    backgroundColor: '#0d1b2f', // Some CSS
                    color: '#a2a9ba'
                }
            },
        },
        MuiTypography: { // Name of the component ⚛️ / style sheet
            styleOverrides: {
                root: { // Name of the rule
                    color: '#afb6c5' // Some CSS
                },
                h1: {
                    fontWeight: 700
                },
                h2: {
                    fontWeight: 700
                },
                h3: {
                    fontWeight: 700
                },
                h4: {
                    fontWeight: 700
                },
                h5: {
                    fontWeight: 700
                },
                h6: {
                    fontWeight: 700,
                    lineHeight: '1.5rem'
                },
                h7: {
                    fontWeight: 700
                },
                body2: {
                    lineHeight: '1rem'
                },
                subtitle2: {
                    fontSize: '0.75rem'
                }
            },
        },
        MuiTimeline: {
          styleOverrides: {
              root: {
                  padding: '0px',
                  margin: '0px',
              }
          }
        },
        MuiTimelineContent: {
          styleOverrides: {
              root: {
                  padding: '8px 0px 0px 16px',
              }
          }
        },
        MuiTooltip: { // Name of the component ⚛️ / style sheet
            styleOverrides: {
                tooltip: { // Name of the rule
                    color: '#afb6c5', // Some CSS
                    backgroundColor: 'rgba(0,0,0,0.0)',
                    opacity: '1'
                },
                popper: {
                    opacity: '1'
                }
            },
        },
        MuiSelect: {
            styleOverrides: {
                root: {
                    color: 'rgba(255, 255, 255, 0.7)',
                    fontSize: '0.875em',
                    borderBottom: '0px'
                },
                icon: {
                    color: 'rgba(255, 255, 255, 0.7)'
                }
            },
        },
        MuiExpansionPanelSummary: {
            styleOverrides: {
                root: {
                    borderRadius: '4px',
                    backgroundColor: '#0d1b2f',
                    borderBottom: '3px solid #2f80ed'
                }
            }
        },
        MuiExpansionPanel: {
            styleOverrides: {
                root: {
                    '&:before': {
                        display: 'none'
                    }
                }
            },
        },
        MuiLinearProgress: {
            styleOverrides: {
                colorPrimary: {
                    backgroundColor: '#0d1b2f',
                    height: '20px'
                }
            },
        },
        MuiPopover: {
          styleOverrides: {
              paper: {
                  background: 'transparent'
              }
          }
        },
        MuiDialogContent: {
            styleOverrides: {
                root: {
                    padding: '24px',
                    whiteSpace: 'pre-wrap'
                }
            },
        },
        MuiSlider: {
            styleOverrides: {
                markLabel: {
                    filter: 'grayscale(100%)'
                },
                markLabelActive: {
                    filter: 'grayscale(0%)'
                }
            },
        },
        MuiBackdrop: {
            styleOverrides: {
                root: {
                    background: 'rgba(0,0,0,0.1)',
                    backdropFilter: 'blur(1px) saturate(0.8)'
                }
            }
        }
    },
    typography: {
        useNextVariants: true
    }
});
