import React, {useEffect} from 'react';

import './style.css';
import moment from "moment";
import Dot from "../common/Header/InfoWidget/Dot";
import GiphySelect from 'react-giphy-select';
import 'react-giphy-select/lib/styles.css';
import {
    Badge,
    Box,
    Button,
    Divider,
    IconButton,
    InputAdornment,
    List,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    Popper, Stack,
    TextField,
    Typography
} from "@mui/material";
import {useMessages} from "../../apollo/hooks/useMessages";
import {useUsers} from "../../apollo/hooks/useUsers";
import {withStyles} from "@mui/styles";

const StyledBadge = withStyles((theme) => ({
    badge: {
        right: 3,
        top: 7,
        padding: '0 4px',
        position: 'absolute'
    },
}))(Badge);

const Chatbox = () => {

    const [chatMessage, setChatMessage] = React.useState("")
    const [openGiphyPicker, setOpenGiphyPicker] = React.useState(false)
    const [anchorEl, setAnchorEl] = React.useState(false)
    const [gif, setGif] = React.useState(null)
    const [target, setTarget] = React.useState(null)
    const [chatBoxOpen, setChatBoxOpen] = React.useState(localStorage.getItem("chatbox-open") ? localStorage.getItem("chatbox-open") : false)

    const {messages, addMessage, loading: messageLoading, error: messageError} = useMessages()
    const {users, onlineUsers, me, loading: usersLoading, error: usersError} = useUsers()

    // Chatcontainer nach unten scrollen wenn target oder nachrichten wechseln
    useEffect(() => {
        const chatContainer = document.getElementById("userchat-body")
        if (chatContainer)
            chatContainer.scrollTop = document.getElementById("userchat-body").scrollHeight
    }, [messages, target])

    if (messageLoading || usersLoading) return (<p>Loading...</p>);
    if (messageError || usersError) return (<p>Error...</p>);

    const handleChangeChatBoxOpen = () => {
        if (!JSON.parse(chatBoxOpen)) {
            localStorage.setItem("chatbox-open", true)
            localStorage.setItem("chatmessages", 0)
            setChatBoxOpen(true)
        } else {
            localStorage.setItem("chatbox-open", false)
            localStorage.setItem("chatmessages", 0)
            setChatBoxOpen(false)
        }
    }

    const handleChangeTarget = (value) => {
        setTarget(value)
    }

    const onEntrySelect = (entry) => {
        setGif(entry.images.downsized.url)
        setOpenGiphyPicker(false)
    }

    const handleToggleGiphyPicker = (event) => {
        if (openGiphyPicker) {
            setOpenGiphyPicker(false)
            setAnchorEl(null)
        } else {
            setOpenGiphyPicker(true)
            setAnchorEl(event.currentTarget)
        }
    }

    const handleAddMessage = () => {
        if (chatMessage.trim() || gif) {
            const payload = {
                user: me.me._id,
                target: target._id,
                message: chatMessage,
                gif: gif ? gif : ""
            }
            addMessage(payload).then(() => {
                const chatContainer = document.getElementById("userchat-body")
                if (chatContainer)
                    chatContainer.scrollTop = document.getElementById("userchat-body").scrollHeight
                setChatMessage("")
                setGif(null)
            })
        }
    }

    const handleTextFieldKeyDown = event => {
        switch (event.key) {
            case 'Enter':
                handleAddMessage()
                break
            default:
                break
        }
    }

    const renderChatMessagesDates = (messages) => {

        const messagesArray = [...messages].filter(x => (x.user._id === target._id && x.target._id === me.me._id) || (x.user._id === me.me._id && x.target._id === target._id));
        const uniqueDates = messagesArray.reduce((message, d) => {
            // moment(a.data[0].creationDate).tz("UTC").format("L")
            // moment(d.creationDate).tz("UTC").format("L")
            const found = message.find(a => moment(a.data[0].creationDate).format("L") === moment(d.creationDate).format("L"));
            //const value = { name: d.name, val: d.value };
            const value = d; // the element in data property
            if (!found) {
                //acc.push(...value);
                message.push({day: moment(d.creationDate), data: [value]}) // not found, so need to add data property
            } else {
                //acc.push({ name: d.name, data: [{ value: d.value }, { count: d.count }] });
                found.data.push(value) // if found, that means data property exists, so just push new element to found.data.
            }
            return message;
        }, []);

        return uniqueDates.map((category, i) => {
            return (
                <Stack key={i} direction={'column'}>
                    <Typography style={{textAlign: "center"}}>
                        <h6 style={{margin: "5px"}}>
                            <div className={"date-pill"}>
                                {moment(category.day).format('LL')}
                            </div>
                        </h6>
                    </Typography>
                    {renderChatMessages(category.data)}
                </Stack>
            )
        })

    }

    const renderChatMessages = (messages) => {
        return messages.map((message, i) => (
            <Box key={i} className={message.user._id === me.me._id ? "chatmessage-self" : "chatmessage"}>
                {message.gif &&
                    <Box className={"gif"}><img src={message.gif} alt="Chat Gif" style={{maxHeight: "150px"}}/></Box>}
                <Box className={"message"}>{message.message}</Box>
                <Box className={"timestamp"}>{moment(message.creationDate).format("hh:mm")}</Box>
            </Box>
        ))
    }

    const isUserOnline = (user) => {
        if (onlineUsers.some(u => u._id === user._id)) {
            return "success"
        } else {
            return "error"
        }
    }

    const getLastMessageByUser = (_id) => {
        return messages.filter(x => (x.user._id === _id && x.target._id === me.me._id) || (x.user._id === me.me._id && x.target._id === _id)).map(message => (
            <>
                {message.gif && <i style={{position: "relative", top: "6px", marginRight: "3px"}}
                                   className={"material-icons"}>image</i>}
                {message.message}
            </>
        )).pop()
    }

    const getLastMessageTimeByUser = (_id) => {
        return messages.filter(x => (x.user._id === _id && x.target._id === me.me._id) || (x.user._id === me.me._id && x.target._id === _id)).map(message => (
            moment(message.creationDate).format("L")
        )).pop()
    }

    const renderUserList = () => {
        const userArray = [...users];
        return userArray.filter(x => x._id !== me.me._id).map((user, i) => {
            return (
                <Box key={i}>
                    <ListItem button onClick={() => handleChangeTarget(user)} key={i}>
                        <Dot variant={isUserOnline(user)}/>
                        <ListItemText className={"userchat-userinfo"}
                                      primary={user.prename + " " + user.surname}
                                      secondary={getLastMessageByUser(user._id) ? getLastMessageByUser(user._id) : "Keine Nachrichten!"}
                        />
                        <ListItemSecondaryAction>
                            {getLastMessageTimeByUser(user._id)}
                        </ListItemSecondaryAction>
                    </ListItem>
                    <Divider/>
                </Box>
            )
        })
    }

    return (
        <div className={"chatbox-container"}>
            <div style={{
                height: JSON.parse(chatBoxOpen) ? "550px" : "50px",
                width: JSON.parse(chatBoxOpen) ? "400px" : "50px"
            }} className={"userchat-wrapper"}>
                <div onClick={() => !JSON.parse(chatBoxOpen) && handleChangeChatBoxOpen(chatBoxOpen)}
                     className={"userchat-header"}>
                    <div className={"userchat-header-back"}>
                        {JSON.parse(chatBoxOpen) && target &&
                            <IconButton onClick={() => setTarget(null)} size={"small"}>
                                <i className={"material-icons"}>arrow_back</i>
                            </IconButton>}
                    </div>
                    <div className={"userchat-header-username"}>
                        {target ?
                            JSON.parse(chatBoxOpen) ?
                                (
                                    <Stack direction={'row'} alignItems={'center'}>
                                        <Dot variant={isUserOnline(target)}/>
                                        <Typography variant={'h7'} color={'#ffffff'}>{target.prename + " " + target.surname}</Typography>
                                    </Stack>
                                )
                                : <StyledBadge overlap="circular" badgeContent={parseInt(localStorage.getItem("chatmessages"))} color="primary"><i className={"material-icons"}>person</i></StyledBadge>
                            :
                            JSON.parse(chatBoxOpen) ?
                                "Nachrichten" : <StyledBadge overlap="circular" badgeContent={parseInt(localStorage.getItem("chatmessages"))} color="primary"><i className={"material-icons"}>forum</i></StyledBadge>
                        }
                    </div>

                    <div className={"userchat-header-close"}>
                        {JSON.parse(chatBoxOpen) &&
                            <IconButton onClick={() => handleChangeChatBoxOpen(chatBoxOpen)} size={"small"}>
                                <i className={"material-icons"}>close</i>
                            </IconButton>}
                    </div>
                </div>
                <div className={"userchat-lower-wrapper"}>
                    {JSON.parse(chatBoxOpen) && target ?
                        <div id={"userchat-body"} className={"userchat-body"}>
                            <div className={"chatmessage-container"}>
                                {renderChatMessagesDates(messages)}
                            </div>
                        </div> :
                        <div className={"userchat-body-1"}>
                            <List style={{width: "100%", padding: "0px"}} component="nav">
                                {renderUserList()}
                            </List>
                        </div>}
                    {JSON.parse(chatBoxOpen) && target &&
                        <div className={"userchat-footer"}>
                            {gif &&
                                <div className={"gif-container"}>
                                    <img src={gif} alt="Chat Gif" style={{maxHeight: "150px"}}/>
                                    <Button onClick={() => setGif(null)} size={"small"}>
                                        <i className={"material-icons"}>close</i>
                                    </Button>
                                </div>}
                            <div className={"input-container"}>
                                <TextField
                                    multiline
                                    rowsMax={4}
                                    onKeyDown={handleTextFieldKeyDown}
                                    onChange={(event) => setChatMessage(event.target.value)}
                                    value={chatMessage}
                                    fullWidth
                                    variant={"filled"}
                                    InputLabelProps={{shrink: true}}
                                    label={"Nachricht"}
                                    InputProps={{
                                        endAdornment:
                                            <InputAdornment style={{backgroundColor: "transparent", marginTop: "-15px"}}
                                                            position="end">
                                                <IconButton onClick={handleToggleGiphyPicker}>
                                                    <i className={"material-icons"}>gif</i>
                                                </IconButton>
                                                <IconButton onClick={() => handleAddMessage()}>
                                                    <i className={"material-icons"}>send</i>
                                                </IconButton>
                                            </InputAdornment>,
                                        disableUnderline: true
                                    }}
                                />
                            </div>
                        </div>}
                </div>
            </div>
            <Popper style={{zIndex: 50, backgroundColor: "#061121"}} open={openGiphyPicker} anchorEl={anchorEl}>
                <GiphySelect requestKey={"X853ShgjMsAgeHq591EpxfrEAiOU7VBf"} onEntrySelect={onEntrySelect}/>
            </Popper>
        </div>
    )
}

export default Chatbox;
