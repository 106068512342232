import {useMutation, useQuery, useSubscription} from '@apollo/client'
import {ON_ANNOTATIONS_CHANGED} from "../subscriptions/onAnnotationsChanged";
import {CREATE_ANNOTATION} from "../mutations/createAnnotation";
import {REMOVE_ANNOTATION} from "../mutations/removeAnnotation";
import {UPDATE_ANNOTATION} from "../mutations/updateAnnotation";
import {GET_ALL_ANNOTATIONS} from "../queries/getAllAnnotations";

export const useAnnotations = () => {

    const fetchQuery = GET_ALL_ANNOTATIONS

    const {
        data: dataQuery,
        loading: loadingQuery,
        error: errorQuery,
        refetch: refetchQuery,
    } = useQuery(fetchQuery, {
        fetchPolicy: 'no-cache'
    })
    const {data: allAnnotationsSubData} = useSubscription(ON_ANNOTATIONS_CHANGED, {
        fetchPolicy: 'no-cache'
    });

    let annotationData = allAnnotationsSubData ? allAnnotationsSubData?.onAnnotationsChanged : dataQuery?.getAllAnnotations;

    const [add, {error: errorAdd}] = useMutation(
        CREATE_ANNOTATION,
        {
            errorPolicy: "all",
            fetchPolicy: 'no-cache',
            onCompleted: (res) => {
                if(!res) return
                refetchQuery()
                console.log('annotation created!')
            },
        },
    );

    const [update, {error: errorUpdate}] = useMutation(
        UPDATE_ANNOTATION,
        {
            errorPolicy: "all",
            fetchPolicy: 'no-cache',
            onCompleted: (res) => {
                if(!res) return
                refetchQuery()
                console.log('annotation updated!')
            },
        },
    );

    const [remove, {error: errorRemove}] = useMutation(
        REMOVE_ANNOTATION,
        {
            errorPolicy: "all",
            fetchPolicy: 'no-cache',
            onCompleted: (res) => {
                if(!res) return
                refetchQuery()
                console.log('annotation removed!')
            },
        },
    );

    return {
        annotations: annotationData,
        loading: loadingQuery,
        error: errorQuery || errorAdd || errorRemove,
        addAnnotation: (input) => add({
            variables: {
                input: input
            }
        }),
        updateAnnotation: (input, id) => update({
            variables: {
                input: input,
                _id: id,
            }
        }),
        removeAnnotation: (id) => remove({
            variables: {
                _id: id
            }
        })
    }
}