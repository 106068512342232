import React from 'react';

import {Avatar, Box, Button, Chip, Drawer, Icon, Paper, Stack, Typography} from "@mui/material";

import moment from 'moment';
import {useNavigate} from "react-router-dom";

const EmployeePopper = (props) => {

    const navigate = useNavigate()
    const {employee, openEmployeePopper, onPopperClose} = props;

    const positions = [
        {
            icon: (
                <Icon>
                    fingerprint
                </Icon>
            ),
            title: employee.employeeId,
            subtitle: "Personal ID"
        },
        {
            icon: (
                <Icon>
                    drive_eta
                </Icon>
            ),
            title: employee.fuehrerschein ? 'vorhanden' : 'nicht vorhanden',
            subtitle: "Führerschein"
        },
        {
            icon: (
                <Icon>
                    cake
                </Icon>
            ),
            title: moment(employee.geburtsdatum).format("DD.MM.YYYY") + " (" + moment().diff(moment(employee.geburtsdatum), "years") + " Jahre)",
            subtitle: "Geburtsdatum / Alter"
        },
        {
            icon: (
                <Icon>
                    phone
                </Icon>
            ),
            title: employee.telefon,
            subtitle: "Telefonnummer"
        },
        {
            icon: (
                <Icon>
                    map
                </Icon>
            ),
            title: (
                <Box>
                    {employee.strasse + " " + employee.hausnummer}<br/>
                    {employee.postleitzahl + " " + employee.ort}
                </Box>
            ),
            subtitle: "Anschrift"
        },
        {
            icon: (
                <Icon>
                    build
                </Icon>
            ),
            title: (
                <Chip
                    className={
                        (employee.beschaeftigung_als === "Lüftungsmonteur" && "BL") ||
                        (employee.beschaeftigung_als === "Heizungs- und Sanitärinstallateur" && "BHS") ||
                        (employee.beschaeftigung_als === "Kälteanlagenmechatroniker" && "BK") ||
                        (employee.beschaeftigung_als === "Elektroinstallateur" && "BE") ||
                        (employee.beschaeftigung_als === "Fachhelfer-Lüftung" && "BLF") ||
                        (employee.beschaeftigung_als === "Fachhelfer Heizung/Sanitär" && "BHSF")
                    }
                    label={employee.beschaeftigung_als}
                />
            ),
            subtitle: "Beschäftigung als"
        },
        {
            icon: (
                <Icon>
                    access_time
                </Icon>
            ),
            title: moment(employee.beschaeftigungsbeginn).format("DD.MM.YYYY"),
            subtitle: "Beschäftigungsbeginn"
        },
        {
            icon: (
                <Icon>
                    language
                </Icon>
            ),
            title: employee.staatsangehoerigkeit,
            subtitle: "Staatsangehörigkeit"
        }
    ]

    const renderPositions = () => {
        return positions.map((position, index) => (
            <Paper key={index} style={{backgroundColor: "#172840"}}>
                <Box p={1} minWidth={'350px'}>
                    <Stack direction={'row'} spacing={2} alignItems={'center'}>
                        <Avatar>
                            {position.icon}
                        </Avatar>
                        <Stack direction={'column'}>
                            <Typography variant={'h7'} color={'#ffffff'}>
                                {position.title}
                            </Typography>
                            <Typography variant={'body2'}>
                                {position.subtitle}
                            </Typography>
                        </Stack>
                    </Stack>
                </Box>
            </Paper>
        ))
    }

    return (
        <Drawer open={openEmployeePopper} anchor={"left"} onClose={onPopperClose}>
            <Box p={5} height={'100%'} display={'grid'} justifyContent={'center'} alignItems={'center'}>
                <Stack direction={'column'} spacing={1}>
                    <Typography variant={'h6'} color={'inherit'}>
                        {employee.anrede + " " + employee.vorname + " " + employee.nachname}
                    </Typography>
                    {renderPositions()}
                    <Stack direction={'row'} spacing={1}>
                        <Button fullWidth onClick={() => navigate('/plans/create/' + employee._id)} variant={'contained'}
                                disableElevation>Vertrag erstellen</Button>
                        <Button fullWidth onClick={() => navigate('/employees/' + employee._id + '/edit')} variant={'outlined'}
                                disableElevation>Bearbeiten</Button>
                    </Stack>
                </Stack>
            </Box>
        </Drawer>
    );
}

export default EmployeePopper;
