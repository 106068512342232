import { gql } from '@apollo/client';

import {CUSTOMER_ENTITY} from "../entities/customerEntity";
import {ANNOTATION_ENTITY} from "../entities/annotationEntity";
import {USER_ENTITY} from "../entities/userEntity";

export const ON_ANNOTATIONS_CHANGED = gql`
  subscription {
    onAnnotationsChanged {
        ...AnnotationEntity
        customer {
          ...CustomerEntity
        }
        user {
          ...UserEntity
        }
    }
  }
  ${CUSTOMER_ENTITY},${ANNOTATION_ENTITY},${USER_ENTITY}
`;
