import { gql } from '@apollo/client';

import {EMPLOYEE_ENTITY} from "../entities/employeeEntity";
import {VACATION_ENTITY} from "../entities/vacationEntity";

export const ON_VACATIONS_CHANGED = gql`
  subscription {
    onVacationsChanged {
        ...VacationEntity
        employee {
            ...EmployeeEntity
        }
    }
  }
  ${EMPLOYEE_ENTITY},${VACATION_ENTITY}
`;
