import React from 'react';
import {Box, Paper, Stack, Typography} from "@mui/material";
import {BiError} from "react-icons/bi";

const ErrorPaper = (props) => {

    return (
        <Paper style={{ borderBottom: "5px solid #de3b3c" }}>
            <Box display={'flex'} alignItems={'center'} justifyContent={'center'} height={'250px'}>
                <Stack direction={'column'} textAlign={'center'}>
                    <Typography variant={'h2'}><BiError/></Typography>
                    <Typography variant={'h5'}>{props.title ? props.title : "Error"}</Typography>
                    <Typography variant={'body2'}>{props.subtitle && props.subtitle}</Typography>
                </Stack>
            </Box>
        </Paper>
    );

}

export default ErrorPaper;
