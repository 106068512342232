// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.twasi-darkblue {
  -webkit-user-select: none;
  user-select: none;
}
.twasi-darkblue img {
  pointer-events: none;
}
.twasi-darkblue .TableRow {
  border-bottom: 3px solid #00b6f5;
  color: #ffffff;
}
.twasi-darkblue .TableRow > th {
  color: #ffffff;
  font-weight: 600;
  font-size: 16px;
}
.twasi-darkblue .bannerHeader {
  background-color: #0d1b2f;
}
.twasi-darkblue .bannerHeaderTopBar {
  background-color: #00b6f5;
  z-index: 1;
}
.twasi-darkblue .themeIcon:hover{
  background: #00b6f5;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.twasi-darkblue a {
  color: #00b6f5;
  text-decoration: none;
}
.twasi-darkblue .header-wrapper {
  border-bottom-right-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  padding: 23px 23px 23px 23px;
  background: rgba(13,27,47,.5) !important;
  backdrop-filter: blur(10px) saturate(130%);
  -webkit-backdrop-filter: blur(10px) saturate(130%);
  display: flex;
  flex-direction: row;
  align-items: center;
}
.twasi-darkblue .overview-infobox {
  padding: 20px 23px 20px 23px;
  background: rgba(47,128,237,1) !important;
  position: relative;
  display: flex;
  flex-direction: row;
}`, "",{"version":3,"sources":["webpack://./src/themes/twasi-darkblue/twasi-darkblue.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,iBAAiB;AACnB;AACA;EACE,oBAAoB;AACtB;AACA;EACE,gCAAgC;EAChC,cAAc;AAChB;AACA;EACE,cAAc;EACd,gBAAgB;EAChB,eAAe;AACjB;AACA;EACE,yBAAyB;AAC3B;AACA;EACE,yBAAyB;EACzB,UAAU;AACZ;AACA;EACE,mBAAmB;EACnB,6BAA6B;EAC7B,oCAAoC;AACtC;AACA;EACE,cAAc;EACd,qBAAqB;AACvB;AACA;EACE,0CAA0C;EAC1C,yCAAyC;EACzC,4BAA4B;EAC5B,wCAAwC;EACxC,0CAA0C;EAC1C,kDAAkD;EAClD,aAAa;EACb,mBAAmB;EACnB,mBAAmB;AACrB;AACA;EACE,4BAA4B;EAC5B,yCAAyC;EACzC,kBAAkB;EAClB,aAAa;EACb,mBAAmB;AACrB","sourcesContent":[".twasi-darkblue {\n  -webkit-user-select: none;\n  user-select: none;\n}\n.twasi-darkblue img {\n  pointer-events: none;\n}\n.twasi-darkblue .TableRow {\n  border-bottom: 3px solid #00b6f5;\n  color: #ffffff;\n}\n.twasi-darkblue .TableRow > th {\n  color: #ffffff;\n  font-weight: 600;\n  font-size: 16px;\n}\n.twasi-darkblue .bannerHeader {\n  background-color: #0d1b2f;\n}\n.twasi-darkblue .bannerHeaderTopBar {\n  background-color: #00b6f5;\n  z-index: 1;\n}\n.twasi-darkblue .themeIcon:hover{\n  background: #00b6f5;\n  -webkit-background-clip: text;\n  -webkit-text-fill-color: transparent;\n}\n.twasi-darkblue a {\n  color: #00b6f5;\n  text-decoration: none;\n}\n.twasi-darkblue .header-wrapper {\n  border-bottom-right-radius: 0px !important;\n  border-bottom-left-radius: 0px !important;\n  padding: 23px 23px 23px 23px;\n  background: rgba(13,27,47,.5) !important;\n  backdrop-filter: blur(10px) saturate(130%);\n  -webkit-backdrop-filter: blur(10px) saturate(130%);\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n}\n.twasi-darkblue .overview-infobox {\n  padding: 20px 23px 20px 23px;\n  background: rgba(47,128,237,1) !important;\n  position: relative;\n  display: flex;\n  flex-direction: row;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
