import {useLazyQuery, useMutation, useQuery, useSubscription} from '@apollo/client'
import {GET_ALL_USERS} from "../queries/getAllUsers";
import {ON_USERS_CHANGED} from "../subscriptions/onUsersChanged";
import {CREATE_USER} from "../mutations/createUser";
import {UPDATE_USER} from "../mutations/updateUser";
import {REMOVE_USER} from "../mutations/removeUser";
import {GET_USER_BY_ID} from "../queries/getUserById";
import {ME} from "../queries/me";
import moment from "moment";

export const useUsers = () => {

    const fetchQuery = GET_ALL_USERS

    const {
        data: dataMe,
        loading: loadingMe,
        error: errorMe,
        refetch: refetchQuery,
    } = useQuery(ME, {
        fetchPolicy: 'no-cache'
    })

    const {
        data: dataQuery,
        loading: loadingQuery,
        error: errorQuery,
    } = useQuery(fetchQuery, {
        fetchPolicy: 'no-cache'
    })
    const {data: allUsersSubData} = useSubscription(ON_USERS_CHANGED, {
        fetchPolicy: 'no-cache'
    });

    let userData = allUsersSubData ? allUsersSubData?.onUsersChanged : dataQuery?.getAllUsers;

    const [add, {error: errorAdd}] = useMutation(
        CREATE_USER,
        {
            errorPolicy: "all",
            fetchPolicy: 'no-cache',
            onCompleted: (res) => {
                if(!res) return
                refetchQuery()
                console.log('user created!')
            },
        },
    );

    const [update, {error: errorUpdate}] = useMutation(
        UPDATE_USER,
        {
            errorPolicy: "all",
            fetchPolicy: 'no-cache',
            onCompleted: (res) => {
                if(!res) return
                refetchQuery()
                console.log('user updated!')
            },
        },
    );

    const [remove, {error: errorRemove}] = useMutation(
        REMOVE_USER,
        {
            errorPolicy: "all",
            fetchPolicy: 'no-cache',
            onCompleted: (res) => {
                if(!res) return
                refetchQuery()
                console.log('user removed!')
            },
        },
    );

    const [getById] = useLazyQuery(
        GET_USER_BY_ID,
        {
            errorPolicy: "all",
            fetchPolicy: 'no-cache',
            onCompleted: (res) => {
                if(!res) return
                refetchQuery()
                console.log('user found!')
            },
        },
    );

    return {
        users: userData,
        onlineUsers: userData?.filter(user => moment().diff(moment(user.last_seen), 'seconds') < 15),
        me: dataMe,
        loading: loadingQuery || loadingMe,
        error: errorQuery || errorAdd || errorRemove || errorUpdate || errorMe,
        addUser: (input) => add({
            variables: {
                input: input
            }
        }),
        updateUser: (input, id) => update({
            variables: {
                input: input,
                _id: id,
            }
        }),
        removeUser: (id) => remove({
            variables: {
                _id: id
            }
        }),
        getUserById: (id) => getById({
            variables: {
                _id: id,
            }
        })
    }
}