// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.infoWidget {
  background: rgba(13,27,47,.5) !important;
  backdrop-filter: blur(10px) saturate(130%) !important;
  -webkit-backdrop-filter: blur(10px) saturate(130%) !important;
  height: 40px !important;
  border-radius: 50px !important;
}

.ring-animation {
  animation: ring 0.2s ease-in-out;
  animation-iteration-count: 2;
}

.infoWidget > span {
  padding: 0px !important;
}
.infoWidgetButton {
  height: 40px !important;
  width: 40px !important;
}


@keyframes ring {
  0%{
    transform: rotate(0deg) scale(1);
  }
  25%{
    transform: rotate(-20deg) scale(1.2);
  }
  75%{
    transform: rotate(20deg) scale(1.2);
  }
  100%{
    transform: rotate(0deg) scale(1);
  }
}

.dot {
  height: 10px;
  width: 10px;
  border-radius: 50px;
  margin-right: 15px;
  margin-left: 5px;
}
.notification-subtype {
  margin-top: 5px;
  margin-right: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/views/common/Header/InfoWidget/style.css"],"names":[],"mappings":"AAAA;EACE,wCAAwC;EACxC,qDAAqD;EACrD,6DAA6D;EAC7D,uBAAuB;EACvB,8BAA8B;AAChC;;AAEA;EACE,gCAAgC;EAChC,4BAA4B;AAC9B;;AAEA;EACE,uBAAuB;AACzB;AACA;EACE,uBAAuB;EACvB,sBAAsB;AACxB;;;AAGA;EACE;IACE,gCAAgC;EAClC;EACA;IACE,oCAAoC;EACtC;EACA;IACE,mCAAmC;EACrC;EACA;IACE,gCAAgC;EAClC;AACF;;AAEA;EACE,YAAY;EACZ,WAAW;EACX,mBAAmB;EACnB,kBAAkB;EAClB,gBAAgB;AAClB;AACA;EACE,eAAe;EACf,kBAAkB;AACpB","sourcesContent":[".infoWidget {\n  background: rgba(13,27,47,.5) !important;\n  backdrop-filter: blur(10px) saturate(130%) !important;\n  -webkit-backdrop-filter: blur(10px) saturate(130%) !important;\n  height: 40px !important;\n  border-radius: 50px !important;\n}\n\n.ring-animation {\n  animation: ring 0.2s ease-in-out;\n  animation-iteration-count: 2;\n}\n\n.infoWidget > span {\n  padding: 0px !important;\n}\n.infoWidgetButton {\n  height: 40px !important;\n  width: 40px !important;\n}\n\n\n@keyframes ring {\n  0%{\n    transform: rotate(0deg) scale(1);\n  }\n  25%{\n    transform: rotate(-20deg) scale(1.2);\n  }\n  75%{\n    transform: rotate(20deg) scale(1.2);\n  }\n  100%{\n    transform: rotate(0deg) scale(1);\n  }\n}\n\n.dot {\n  height: 10px;\n  width: 10px;\n  border-radius: 50px;\n  margin-right: 15px;\n  margin-left: 5px;\n}\n.notification-subtype {\n  margin-top: 5px;\n  margin-right: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
