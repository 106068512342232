import {gql} from '@apollo/client';
import {EMPLOYEE_ENTITY} from "../entities/employeeEntity";
import {VACATION_ENTITY} from "../entities/vacationEntity";

export const GET_VACATION_BY_ID = gql`
  query getVacationById($_id: String!){
    getVacationById(_id: $_id) {
        ...VacationEntity
        employee {
            ...EmployeeEntity
        }
    }
  }
  ${VACATION_ENTITY},${EMPLOYEE_ENTITY}
`;
