import React, {useState} from 'react';

import './style.css';
import ContentHeader from "../common/ContentHeader";
import {
    Autocomplete,
    Box,
    Button,
    Card,
    CardContent,
    Checkbox,
    Fab,
    FormControlLabel,
    Grid, List, ListItem, ListItemButton, ListItemText,
    Paper,
    Stack,
    TextField,
    Typography
} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {useAnnotations} from "../../apollo/hooks/useAnnotations";
import {FormattedMessage} from "react-intl";
import {useCustomers} from "../../apollo/hooks/useCustomers";
import {useUsers} from "../../apollo/hooks/useUsers";
import GetCustomer from "../common/Essentials/GetCustomer";
import {AiFillDelete} from "react-icons/ai";
import PopoverMenu from "../common/PopoverMenu/PopoverMenu";
import ErrorPaper from "../common/ErrorPaper";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
    margin: {
        margin: theme.spacing(0.5),
    }
}));

const Annotations = () => {

    const classes = useStyles();
    const [selectedCustomer, setSelectedCustomer] = React.useState(null);
    const [assignment, setAssignment] = React.useState(false);
    const [annotation, setAnnotation] = React.useState("");
    const [customerName, setCustomerName] = React.useState("");
    const [customerPhone, setCustomerPhone] = React.useState("");

    const {annotations, addAnnotation, updateAnnotation, removeAnnotation, loading, error} = useAnnotations()
    const {me} = useUsers()
    const {enabledCustomers, loading: customersLoading, error: customersError} = useCustomers()

    if (loading || customersLoading) return "Loading..."
    if (error || customersError) return "Error..."


    const handleSelectCustomer = (event, index) => {
        if (index) {
            setSelectedCustomer(enabledCustomers.filter(x => x._id === index._id)[0]);
        } else {
            setSelectedCustomer(null)
        }
    }

    const handleAddAnnotation = () => {
        const payload = {
            customer: selectedCustomer ? selectedCustomer._id : null,
            user: me.me._id.toString(),
            annotation: annotation,
            checked: false,
            assignment: assignment,
            customerName: customerName,
            customerPhone: customerPhone
        }
        addAnnotation(payload).then(() => {
            setAssignment(false)
            setAnnotation("")
            setCustomerName("")
            setCustomerPhone("")
            setSelectedCustomer(null)
        })
    }

    const handleCheckAnnotation = (object, checked) => {
        const payload = {
            customer: object.customer ? object.customer._id : null,
            user: object.user._id,
            annotation: object.annotation,
            checked: checked,
            assignment: object?.assignment,
            customerName: object?.customerName,
            customerPhone: object?.customerPhone
        }
        updateAnnotation(payload, object._id)
    }

    const renderAnnotations = (checked) => {
        return annotations.filter(annotation => annotation.checked === checked).map((annotation, index) => (
            <Card key={index}>
                <CardContent>
                    <Box p={1}>
                        <Stack direction={'row'} spacing={1} justifyContent={'space-between'} alignItems={'center'}>
                            <Stack direction={'column'} spacing={1} width={'100%'}>
                                <GetCustomer customer={annotation.customer}></GetCustomer>
                                <Paper>
                                    <Box p={1}>
                                        <Stack direction={'row'} spacing={5} justifyContent={'space-between'}>
                                            <Stack direction={'column'} spacing={1}>
                                                <Typography variant={'body2'}>
                                                    Anmerkungstext
                                                </Typography>
                                                <Typography color={'#ffffff'} variant={'body1'}>
                                                    {annotation.annotation}
                                                </Typography>
                                            </Stack>
                                            <Stack direction={'column'} spacing={1}>
                                                <Typography variant={'body2'}>
                                                    Ansprechpartner
                                                </Typography>
                                                <Typography color={'#ffffff'} variant={'body1'}>
                                                    {annotation.customerName}
                                                </Typography>
                                            </Stack>
                                            <Stack direction={'column'} spacing={1}>
                                                <Typography variant={'body2'}>
                                                    Telefonnummer
                                                </Typography>
                                                <Typography color={'#ffffff'} variant={'body1'}>
                                                    {annotation.customerPhone}
                                                </Typography>
                                            </Stack>
                                        </Stack>
                                    </Box>
                                </Paper>
                                <Stack direction={'row'} justifyContent={'space-between'}>
                                    <Typography variant={'body2'}>
                                        {annotation.assignment ? "Kundenanfrage" : "Anmerkung"}
                                    </Typography>
                                    <Typography variant={'body2'}>
                                        {moment(annotation?.created_at).format("LLL") + " erstellt von " + annotation.user.prename + " " + annotation.user.surname}
                                    </Typography>
                                </Stack>
                            </Stack>
                            <Stack direction={'column'} alignItems={'center'}>
                                <PopoverMenu>
                                    <List dense style={{padding: 0}}>
                                        <ListItem disablePadding>
                                            <ListItemButton onClick={() => handleCheckAnnotation(annotation, !annotation.checked)}>
                                                <ListItemText primary={annotation.checked ? "Als ausstehend markieren" : "Als erledigt markieren"}/>
                                            </ListItemButton>
                                        </ListItem>
                                        <ListItem disablePadding>
                                            <ListItemButton onClick={() => removeAnnotation(annotation._id)}>
                                                <ListItemText
                                                    primary={"Löschen"}/>
                                            </ListItemButton>
                                        </ListItem>
                                    </List>
                                </PopoverMenu>
                            </Stack>
                        </Stack>
                    </Box>
                </CardContent>
            </Card>
        ))
    }

    const renderNewAnnotation = () => {
        return (
            <Card>
                <CardContent>
                    <Box p={1}>
                        <Stack direction={'column'} spacing={1}>
                            <Stack direction={'row'} justifyContent={'space-between'}>
                                <Autocomplete
                                    style={{width: '300px'}}
                                    id="customer"
                                    onChange={handleSelectCustomer}
                                    value={selectedCustomer && selectedCustomer}
                                    options={enabledCustomers}
                                    getOptionLabel={option => option.customerId + " - " + option.name}
                                    renderInput={params => (
                                        <TextField
                                            {...params}
                                            label={<FormattedMessage id="customers.search"/>}
                                            variant="filled"
                                            size={"small"}
                                        />
                                    )}
                                />
                                <FormControlLabel
                                    control={<Checkbox onClick={(event) => setAssignment(event.target.checked)}
                                                       checked={assignment}/>} label="Als Kundenanfrage speichern"/>
                            </Stack>
                            <TextField value={annotation} onChange={(e) => setAnnotation(e.target.value)}
                                       label={'Anmerkungstext'} variant={'filled'} size={"small"}/>
                            <Stack direction={'row'} spacing={1} alignItems={'center'}>
                                <TextField fullWidth value={customerName}
                                           onChange={(e) => setCustomerName(e.target.value)} label={'Ansprechpartner (optional)'}
                                           variant={'filled'} size={"small"}/>
                                <TextField fullWidth value={customerPhone}
                                           onChange={(e) => setCustomerPhone(e.target.value)}
                                           label={'Telefonnummer (optional)'} variant={'filled'} size={"small"}/>
                                <Box>
                                    <Fab disabled={!annotation} onClick={() => handleAddAnnotation()} size="small"
                                         color="primary"
                                         aria-label="add">
                                        <i className="material-icons">add</i>
                                    </Fab>
                                </Box>
                            </Stack>
                        </Stack>
                    </Box>
                </CardContent>
            </Card>
        )
    }

    return (
        <Grid container spacing={1}>
            <Grid item md={6} xs={12}>
                <ContentHeader
                    title="Ausstehend"
                    subtitle="Hier können Anmerkungen angelegt werden."
                />
                <Paper style={{borderTopRightRadius: "0px", borderTopLeftRadius: "0px", padding: "0px"}}
                       className={"pageContainer"}>
                    <Box p={1}>
                        <Stack direction={'column'} spacing={1}>
                            {renderNewAnnotation()}
                            {renderAnnotations(false)}
                        </Stack>
                    </Box>
                </Paper>
            </Grid>
            <Grid item md={6} xs={12}>
                <ContentHeader
                    title="Erledigt"
                    subtitle="Hier sind erledigte Anmerkungen hinterlegt."
                />
                <Paper style={{borderTopRightRadius: "0px", borderTopLeftRadius: "0px", padding: "0px"}}
                       className={"pageContainer"}>
                    <Box p={1}>
                        <Stack direction={'column'} spacing={1}>
                            {renderAnnotations(true)}
                        </Stack>
                    </Box>
                </Paper>
            </Grid>
        </Grid>
    );

}

export default Annotations;
