// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media only screen and (max-width: 599px) {
  .siteContent {
    width: 95%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 40px;
    font-family: Noto Sans, sans-serif;
    box-sizing: border-box;
  }
  .sidebar {
    width: 90px;
  }
}

@media only screen and (min-width: 600px ) {
  .siteContent {
    box-sizing: border-box;
    width: 95%;
    margin-left: auto;
    margin-right: auto;
    font-family: Noto Sans, sans-serif;
  }
  .sidebar {
    width: 90px;
  }
}

@media only screen and (min-width: 980px ) {
  .siteContent {
    box-sizing: border-box;
    width: 95%;
    margin-left: auto;
    margin-right: auto;
    font-family: Noto Sans, sans-serif;
  }
  .sidebar {
    width: 90px;
  }
}

@media only screen and (min-width: 1260px ) {
  .siteContent {
    box-sizing: border-box;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    font-family: Noto Sans, sans-serif;
  }
  .sidebar {
    width: initial;
  }
}
`, "",{"version":3,"sources":["webpack://./src/views/common/Content/style.css"],"names":[],"mappings":"AAAA;EACE;IACE,UAAU;IACV,iBAAiB;IACjB,kBAAkB;IAClB,gBAAgB;IAChB,kCAAkC;IAClC,sBAAsB;EACxB;EACA;IACE,WAAW;EACb;AACF;;AAEA;EACE;IACE,sBAAsB;IACtB,UAAU;IACV,iBAAiB;IACjB,kBAAkB;IAClB,kCAAkC;EACpC;EACA;IACE,WAAW;EACb;AACF;;AAEA;EACE;IACE,sBAAsB;IACtB,UAAU;IACV,iBAAiB;IACjB,kBAAkB;IAClB,kCAAkC;EACpC;EACA;IACE,WAAW;EACb;AACF;;AAEA;EACE;IACE,sBAAsB;IACtB,UAAU;IACV,iBAAiB;IACjB,kBAAkB;IAClB,kCAAkC;EACpC;EACA;IACE,cAAc;EAChB;AACF","sourcesContent":["@media only screen and (max-width: 599px) {\n  .siteContent {\n    width: 95%;\n    margin-left: auto;\n    margin-right: auto;\n    margin-top: 40px;\n    font-family: Noto Sans, sans-serif;\n    box-sizing: border-box;\n  }\n  .sidebar {\n    width: 90px;\n  }\n}\n\n@media only screen and (min-width: 600px ) {\n  .siteContent {\n    box-sizing: border-box;\n    width: 95%;\n    margin-left: auto;\n    margin-right: auto;\n    font-family: Noto Sans, sans-serif;\n  }\n  .sidebar {\n    width: 90px;\n  }\n}\n\n@media only screen and (min-width: 980px ) {\n  .siteContent {\n    box-sizing: border-box;\n    width: 95%;\n    margin-left: auto;\n    margin-right: auto;\n    font-family: Noto Sans, sans-serif;\n  }\n  .sidebar {\n    width: 90px;\n  }\n}\n\n@media only screen and (min-width: 1260px ) {\n  .siteContent {\n    box-sizing: border-box;\n    width: 80%;\n    margin-left: auto;\n    margin-right: auto;\n    font-family: Noto Sans, sans-serif;\n  }\n  .sidebar {\n    width: initial;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
