import React, {useEffect, useState} from 'react';

import {Box, Card, CardContent, Grid, Paper, Stack, Typography} from "@mui/material";
import {useParams} from "react-router-dom";
import ContentHeader from "../common/ContentHeader";
import PlanCard from "./PlanCard";
import {useEmployees} from "../../apollo/hooks/useEmployees";
import {usePlans} from "../../apollo/hooks/usePlans";
import Timeline from '@mui/lab/Timeline';
import TimelineItem, {timelineItemClasses} from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import moment from "moment-business-days";

const PlanHistory = (props) => {

    const routeParams = useParams()

    const [selectedEmployee, setSelectedEmployee] = useState(null)

    const {getEmployeeById} = useEmployees()
    const {plans, addPlan, loading: plansLoading, error: plansError} = usePlans()

    useEffect(() => {
        getEmployeeById(routeParams.employee).then((val) => {
            setSelectedEmployee(val.data.getEmployeeById)
        })
    }, [])

    if (!selectedEmployee) return "Loading"

    let timelineElements = plans.filter(plan => plan.employee._id === selectedEmployee._id)
        .reduce(function (elementArray, currentElement, currentIndex) {
            var daysBreakLimit = Math.round(moment.duration(moment().add(3, 'M').diff(moment())).asDays())
            var daysWorkLimit = 0
            var daysWorked = 0

            var lastElement = elementArray.slice(-1)[0]

            if(!lastElement) {
                // erstes element
                daysWorkLimit = Math.round(moment.duration(moment(currentElement.startdate).add(9, 'M').diff(moment(currentElement.startdate))).asDays())
                if(currentElement.enddate) {
                    if(moment().isAfter(moment(currentElement.enddate)) || moment().isBefore(moment(currentElement.startdate))) {
                        // wenn heute nach ende der überlassung ODER wenn heute vor start der Überlassung
                        currentElement.paused = true
                        currentElement.pausestart = moment(currentElement.enddate)
                        currentElement.pauseend = moment()
                    }
                    daysWorked = Math.round(moment.duration(moment(currentElement.enddate).diff(moment(currentElement.startdate))).asDays())
                } else {
                    daysWorked = Math.round(moment.duration(moment().diff(moment(currentElement.startdate))).asDays())
                }
                currentElement.worklimit = daysWorkLimit
                currentElement.timeLeft = daysWorkLimit - daysWorked
                currentElement.blocked = daysWorked >= daysWorkLimit ? true : false
            } else {
                if(lastElement.customer._id === currentElement.customer._id){
                    // wenn aktuelles element === letztes element
                    if(currentElement.enddate) {
                        if(moment().isAfter(moment(currentElement.enddate)) || moment().isBefore(moment(currentElement.startdate))) {
                            // wenn heute nach ende der überlassung ODER wenn heute vor start der Überlassung
                            currentElement.paused = true
                        }
                        if(moment(lastElement.enddate).isBefore(moment(currentElement.startdate))) {
                            if(Math.round(moment.duration(moment(currentElement.startdate).diff(moment(lastElement.enddate))).asDays()) >= daysBreakLimit) {
                                daysWorkLimit = Math.round(moment.duration(moment(currentElement.startdate).add(9, 'M').diff(moment(currentElement.startdate))).asDays())
                                currentElement.blocked = false
                                currentElement.timeLeft = daysWorkLimit
                                currentElement.worklimit = daysWorkLimit
                            }
                            var breakElement = {
                                startdate: moment(lastElement.enddate),
                                enddate: moment(currentElement.startdate),
                                __typename: "BreakEntity"
                            }
                            elementArray = elementArray.concat(breakElement);
                        }
                        daysWorked = Math.round(moment.duration(moment(currentElement.enddate).diff(moment(currentElement.startdate))).asDays())
                    } else {
                        daysWorked = Math.round(moment.duration(moment().diff(moment(currentElement.startdate))).asDays())
                    }
                    currentElement.timeLeft = lastElement.timeLeft - daysWorked
                    currentElement.blocked = daysWorked >= lastElement.timeLeft ? true : false
                } else {
                    // wenn letztes element !== aktuelles element
                    daysWorkLimit = Math.round(moment.duration(moment(currentElement.startdate).add(9, 'M').diff(moment(currentElement.startdate))).asDays())
                    if(currentElement.enddate) {
                        if(moment().isAfter(moment(currentElement.enddate)) || moment().isBefore(moment(currentElement.startdate))) {
                            // wenn heute nach ende der überlassung ODER wenn heute vor start der Überlassung
                            currentElement.paused = true
                        }
                        daysWorked = Math.round(moment.duration(moment(currentElement.enddate).diff(moment(currentElement.startdate))).asDays())
                    } else {
                        daysWorked = Math.round(moment.duration(moment().diff(moment(currentElement.startdate))).asDays())
                    }
                    currentElement.timeLeft = daysWorkLimit - daysWorked
                    currentElement.blocked = daysWorked >= daysWorkLimit ? true : false
                }
            }

            // aktuelles element dem bestehenden array hinzufügen
            elementArray = elementArray.concat(currentElement);

            return elementArray;
        }, []).sort(function (a, b) {
            a = a.startdate
            b = b.startdate
            if (b === null) {
                return -1;
            }
            return new Date(a) - new Date(b)
        }).reverse()

    const renderTypeName = (element) => {
        switch (element.__typename) {
            case 'VacationEntity':
                return (
                    <PlanCard
                        title={"Urlaub"}
                        disableMenu
                        startdate={element.startdate}
                        enddate={element.enddate}
                    />
                )
            case 'DiseaseEntity':
                return (
                    <PlanCard
                        title={"Krank"}
                        disableMenu
                        startdate={element.startdate}
                        enddate={element.enddate}
                    />
                )
            case 'PlanEntity':
                return (
                    <PlanCard
                        customer={plans.filter(x => x.customer._id === element.customer._id)[0].customer}
                        startdate={element.startdate}
                        enddate={element.enddate}
                        plan={element}
                    />
                )
            case 'BreakEntity':
                return (
                    <PlanCard
                        title={'Pause'}
                        disableMenu
                        startdate={element.startdate}
                        enddate={element.enddate}
                    />
                )
            default:
                return 'Unbekannt'
        }
    }

    const renderSortedPlans = () => {
        return timelineElements.map((element, index, elements) => {
            return (
                <TimelineItem>
                    <TimelineSeparator>
                        <TimelineDot color={'primary'}/>
                        <TimelineConnector sx={{bgcolor: '#ffffff'}}/>
                    </TimelineSeparator>
                    <TimelineContent sx={{overflow: 'hidden'}}>
                        <Stack alignItems={'center'} direction={'row'} spacing={1}>
                            {renderTypeName(element)}
                            <Box style={{minWidth: '50px'}}>
                                <Card>
                                    <CardContent>
                                        <Box p={1}>
                                            <Stack direction={'column'} spacing={1}>
                                                <Typography variant={'body1'} color={'#ffffff'}>{element.timeLeft} Tage
                                                    übrig</Typography>
                                                <Typography variant={'body1'} color={'#ffffff'}>{element.blocked ? "Ende erreicht" : "Ende nicht erreicht"}</Typography>
                                                <Typography variant={'body1'} color={'#ffffff'}>{element.paused ? "Nicht aktiv" : "Aktiv"}</Typography>
                                            </Stack>
                                        </Box>
                                    </CardContent>
                                </Card>
                            </Box>
                        </Stack>
                    </TimelineContent>
                </TimelineItem>
            )
        })
    }

    return (
        <Grid container spacing={1}>
            <Grid item xs={6}>
                <Box>
                    <ContentHeader
                        title={selectedEmployee.vorname + " " + selectedEmployee.nachname}
                        subtitle={"Bisherige Überlassungen von " + selectedEmployee.vorname + " " + selectedEmployee.nachname}
                    />
                    <Paper>
                        <Box p={1} ml={1}>
                            <Timeline sx={{
                                [`& .${timelineItemClasses.root}:before`]: {
                                    flex: 0,
                                    padding: 0,
                                },
                            }}>
                                {renderSortedPlans()}
                            </Timeline>
                        </Box>
                    </Paper>
                </Box>
            </Grid>
            <Grid item xs={6}>
                <Box>
                    <ContentHeader
                        title="Zusammenfassung"
                        subtitle="..."
                    />
                    <Paper>
                        <Box p={1}>

                        </Box>
                    </Paper>
                </Box>
            </Grid>
        </Grid>
    );

}

export default PlanHistory;
