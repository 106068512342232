import { gql } from '@apollo/client';

import {NOTIFICATION_ENTITY} from "../entities/notificationEntity";
import {USER_ENTITY} from "../entities/userEntity";

export const GET_ALL_NOTIFICATIONS = gql`
  query getAllNotifications {
    getAllNotifications {
        ...NotificationEntity
        user {
          ...UserEntity
        },
    }
  }
  ${NOTIFICATION_ENTITY},${USER_ENTITY}
`;
