import { gql } from '@apollo/client';

import {PLAN_ENTITY} from "../entities/planEntity";
import {CUSTOMER_ENTITY} from "../entities/customerEntity";
import {ANNOTATION_ENTITY} from "../entities/annotationEntity";
import {CONTACT_ENTITY} from "../entities/contactEntity";

export const ON_CUSTOMERS_CHANGED = gql`
  subscription {
    onCustomersChanged {
        ...CustomerEntity
        plans {
          ...PlanEntity
        },
        contacts {
          ...ContactEntity
        }
        annotations {
          ...AnnotationEntity
        }
    }
  }
  ${PLAN_ENTITY},${CUSTOMER_ENTITY},${ANNOTATION_ENTITY},${CONTACT_ENTITY}
`;
