import {gql} from '@apollo/client';

import {PLAN_ENTITY} from "../entities/planEntity";

import {EMPLOYEE_ENTITY} from "../entities/employeeEntity";
import {DISEASE_ENTITY} from "../entities/diseaseEntity";
import {VACATION_ENTITY} from "../entities/vacationEntity";

export const GET_ALL_EMPLOYEES = gql`
  query getAllEmployees {
    getAllEmployees {
        ...EmployeeEntity,
        plans {
          ...PlanEntity
        },
        diseases {
          ...DiseaseEntity
        },
        vacations {
          ...VacationEntity
        }
    }
  }
  ${PLAN_ENTITY},${EMPLOYEE_ENTITY},${DISEASE_ENTITY},${VACATION_ENTITY}
`;
