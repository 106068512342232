import {useMutation, useQuery, useSubscription} from '@apollo/client'
import {ON_MESSAGES_CHANGED} from "../subscriptions/onMessagesChanged";
import {CREATE_MESSAGE} from "../mutations/createMessage";
import {REMOVE_MESSAGE} from "../mutations/removeMessage";
import {MY_MESSAGES} from "../queries/myMessages";

export const useMessages = () => {

    const fetchQuery = MY_MESSAGES

    const {
        data: dataQuery,
        loading: loadingQuery,
        error: errorQuery,
        refetch: refetchQuery,
    } = useQuery(fetchQuery, {
        fetchPolicy: 'no-cache'
    })
    const {data: allMessagesSubData} = useSubscription(ON_MESSAGES_CHANGED, {
        fetchPolicy: 'no-cache'
    });

    let messageData = allMessagesSubData ? allMessagesSubData?.onMessagesChanged : dataQuery?.myMessages;

    const [add, {error: errorAdd}] = useMutation(
        CREATE_MESSAGE,
        {
            errorPolicy: "all",
            fetchPolicy: 'no-cache',
            onCompleted: (res) => {
                if(!res) return
                refetchQuery()
                console.log('message created!')
            },
        },
    );

    const [remove, {error: errorRemove}] = useMutation(
        REMOVE_MESSAGE,
        {
            errorPolicy: "all",
            fetchPolicy: 'no-cache',
            onCompleted: (res) => {
                if(!res) return
                refetchQuery()
                console.log('message removed!')
            },
        },
    );

    return {
        messages: messageData,
        loading: loadingQuery,
        error: errorQuery || errorAdd || errorRemove,
        addMessage: (input) => add({
            variables: {
                input: input
            }
        }),
        removeMessage: (id) => remove({
            variables: {
                _id: id
            }
        })
    }
}