import { gql } from '@apollo/client';
import {PLAN_ENTITY} from "../entities/planEntity";

export const UPDATE_PLAN = gql`
  mutation updatePlan($input: PlanInput!, $_id: String!) {
    updatePlan(input: $input, _id: $_id) {
        ...PlanEntity
    }
  }
  ${PLAN_ENTITY}
`;
