import {useMutation, useQuery, useSubscription} from '@apollo/client'
import {GET_ALL_CONTACTS} from "../queries/getAllContacts";
import {ON_CONTACTS_CHANGED} from "../subscriptions/onContactsChanged";
import {CREATE_CONTACT} from "../mutations/createContact";
import {UPDATE_CONTACT} from "../mutations/updateContact";
import {REMOVE_CONTACT} from "../mutations/removeContact";

export const useContacts = () => {

    const fetchQuery = GET_ALL_CONTACTS

    const {
        data: dataQuery,
        loading: loadingQuery,
        error: errorQuery,
        refetch: refetchQuery,
    } = useQuery(fetchQuery, {
        fetchPolicy: 'no-cache'
    })
    const {data: allContactsSubData} = useSubscription(ON_CONTACTS_CHANGED, {
        fetchPolicy: 'no-cache'
    });

    let contactData = allContactsSubData ? allContactsSubData?.onContactsChanged : dataQuery?.getAllContacts;

    const [add, {loading: loadingAdd, error: errorAdd}] = useMutation(
        CREATE_CONTACT,
        {
            errorPolicy: "all",
            fetchPolicy: 'no-cache',
            onCompleted: (res) => {
                if(!res) return
                refetchQuery()
                //addNotification("Überlassung " + res.createEmployee.vorname + " " + res.createEmployee.nachname + " hinzugefügt.", "EMPLOYEES")
                console.log('contact created!')
            },
        },
    );

    const [update, {loading: loadingUpdate, error: errorUpdate}] = useMutation(
        UPDATE_CONTACT,
        {
            errorPolicy: "all",
            fetchPolicy: 'no-cache',
            onCompleted: (res) => {
                if(!res) return
                refetchQuery()
                console.log('contact updated!')
            },
        },
    );

    const [remove, {loading: loadingRemove, error: errorRemove}] = useMutation(
        REMOVE_CONTACT,
        {
            errorPolicy: "all",
            fetchPolicy: 'no-cache',
            onCompleted: (res) => {
                if(!res) return
                refetchQuery()
                console.log('contact removed!')
            },
        },
    );

    return {
        contacts: contactData,
        loading: loadingQuery,
        error: errorQuery || errorAdd || errorRemove || errorUpdate,
        addContact: (input) => add({
            variables: {
                input: input
            }
        }),
        updateContact: (input, id) => update({
            variables: {
                input: input,
                _id: id,
            }
        }),
        removeContact: (id) => remove({
            variables: {
                _id: id
            }
        })
    }
}