import {useLazyQuery, useMutation, useQuery, useSubscription} from '@apollo/client'
import {CREATE_CUSTOMER} from "../mutations/createCustomer";
import {UPDATE_CUSTOMER} from "../mutations/updateCustomer";
import {ARCHIVE_CUSTOMER} from "../mutations/archiveCustomer";
import {GET_CUSTOMER_BY_ID} from "../queries/getCustomerById";
import {ON_CUSTOMERS_CHANGED} from "../subscriptions/onCustomersChanged";
import {GET_ALL_CUSTOMERS} from "../queries/getAllCustomers";
import {REMOVE_CUSTOMER} from "../mutations/removeCustomer";
import {useNotifications} from "./useNotifications";

export const useCustomers = () => {

    const {addNotification} = useNotifications()
    const fetchQuery = GET_ALL_CUSTOMERS

    const {
        data: dataQuery,
        loading: loadingQuery,
        error: errorQuery,
        refetch: refetchQuery,
    } = useQuery(fetchQuery, {
        fetchPolicy: 'no-cache'
    })
    const {data: allCustomersSubData} = useSubscription(ON_CUSTOMERS_CHANGED, {
        fetchPolicy: 'no-cache'
    });

    let customerData = allCustomersSubData ? allCustomersSubData?.onCustomersChanged : dataQuery?.getAllCustomers;

    const [add, {error: errorAdd}] = useMutation(
        CREATE_CUSTOMER,
        {
            errorPolicy: "all",
            fetchPolicy: 'no-cache',
            onCompleted: (res) => {
                if(!res) return
                refetchQuery()
                addNotification("Firma " + res.createCustomer.name + " hinzugefügt.", "CUSTOMERS")
                console.log('customer created!')
            },
        },
    );

    const [update, {error: errorUpdate}] = useMutation(
        UPDATE_CUSTOMER,
        {
            errorPolicy: "all",
            fetchPolicy: 'no-cache',
            onCompleted: (res) => {
                if(!res) return
                refetchQuery()
                addNotification("Firma " + res.updateCustomer.name + " aktualisiert.", "CUSTOMERS")
                console.log('customer updated!')
            },
        },
    );

    const [archive, {error: errorArchive}] = useMutation(
        ARCHIVE_CUSTOMER,
        {
            errorPolicy: "all",
            fetchPolicy: 'no-cache',
            onCompleted: (res) => {
                if(!res) return
                refetchQuery()
                addNotification("Firma " + res.archiveCustomer.name + (res.archiveCustomer.disabled ? " archiviert." : " aus dem Archiv entfernt."), "CUSTOMERS")
                console.log('customer archived!')
            },
        },
    );

    const [remove, {error: errorRemove}] = useMutation(
        REMOVE_CUSTOMER,
        {
            errorPolicy: "all",
            fetchPolicy: 'no-cache',
            onCompleted: (res) => {
                if(!res) return
                refetchQuery()
                addNotification("Firma " + res.removeCustomer.name + " gelöscht.", "CUSTOMERS")
                console.log('customer removed!')
            },
        },
    );

    const [getById] = useLazyQuery(
        GET_CUSTOMER_BY_ID,
        {
            errorPolicy: "all",
            fetchPolicy: 'no-cache',
            onCompleted: (res) => {
                if(!res) return
                refetchQuery()
                console.log('customer found!')
            },
        },
    );

    return {
        customers: customerData,
        enabledCustomers: customerData?.filter(x => !x.disabled).sort((a, b) => a.customerId - b.customerId),
        disabledCustomers: customerData?.filter(x => x.disabled).sort((a, b) => a.customerId - b.customerId),
        loading: loadingQuery,
        error: errorQuery || errorAdd || errorArchive || errorUpdate,
        addCustomer: (input) => add({
            variables: {
                input: input
            }
        }),
        updateCustomer: (input, id) => update({
            variables: {
                input: input,
                _id: id,
            }
        }),
        archiveCustomer: (id, disabled) => archive({
            variables: {
                _id: id,
                disabled: disabled
            }
        }),
        removeCustomer: (id) => remove({
            variables: {
                _id: id
            }
        }),
        getCustomerById: (id) => getById({
            variables: {
                _id: id,
            }
        })
    }
}