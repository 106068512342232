// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.calendar_resizeHandle__Guj-P {
    background-color: white;
    width: 20px;
    position: relative;
    z-index: 99;
}
.calendar_cursorMarker__fEbGb {
    background-color: #ffffff;
}
.calendar_diseaseContainer__wPekp {
    flex-grow: 1;
}`, "",{"version":3,"sources":["webpack://./src/views/Calendar/calendar.module.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,WAAW;IACX,kBAAkB;IAClB,WAAW;AACf;AACA;IACI,yBAAyB;AAC7B;AACA;IACI,YAAY;AAChB","sourcesContent":[".resizeHandle {\n    background-color: white;\n    width: 20px;\n    position: relative;\n    z-index: 99;\n}\n.cursorMarker {\n    background-color: #ffffff;\n}\n.diseaseContainer {\n    flex-grow: 1;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"resizeHandle": `calendar_resizeHandle__Guj-P`,
	"cursorMarker": `calendar_cursorMarker__fEbGb`,
	"diseaseContainer": `calendar_diseaseContainer__wPekp`
};
export default ___CSS_LOADER_EXPORT___;
