import {gql} from '@apollo/client';
import {CONTACT_ENTITY} from "../entities/contactEntity";

export const UPDATE_CONTACT = gql`
  mutation updateContact($input: ContactInput!, $_id: String!) {
    updateContact(input: $input, _id: $_id) {
        ...ContactEntity
    }
  }
  ${CONTACT_ENTITY}
`;
