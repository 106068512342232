import React from 'react';

import './style.css';
import moment from "moment";
import {FormattedMessage} from "react-intl";
import Dot from "./Dot";
import {
    Box,
    Card,
    CardContent,
    Drawer,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    Tab,
    Tabs,
    Typography
} from "@mui/material";
import {useUsers} from "../../../../apollo/hooks/useUsers";
import {useNotifications} from "../../../../apollo/hooks/useNotifications";

require('moment/locale/de');

function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

const NotificationPopper = (props) => {
    const [notificationTab, setNotificationTab] = React.useState(0);

    const {
        notifications,
        removeNotification,
        reviewNotification,
        loading: notificationsLoading,
        loadingRemove: loadingRemove,
        loadingReview: loadingReview,
        error: notificationsError
    } = useNotifications()

    const {me, loading: usersLoading, error: usersError} = useUsers()

    if (usersLoading || notificationsLoading) return (<p>Loading...</p>);
    if (usersError || notificationsError) return (<p>Error...</p>);

    const handleRemoveNotification = (id) => {
        removeNotification(id.toString())
    }

    const handleReviewNotification = (id) => {
        reviewNotification(id.toString(), me.me._id.toString())
    }

    const handleChangeNotificationTab = (event, newValue) => {
        setNotificationTab(newValue);
    };

    const renderNotificationCategories = (type) => {
        const uniqueCategories = notifications.reduce((notification, d) => {
            const found = notification.find(a => a.category === d.category);
            //const value = { name: d.name, val: d.value };
            const value = {notification: d}; // the element in data property
            if (!found) {
                //acc.push(...value);
                notification.push({category: d.category, data: [value]}) // not found, so need to add data property
            } else {
                //acc.push({ name: d.name, data: [{ value: d.value }, { count: d.count }] });
                found.data.push(value) // if found, that means data property exists, so just push new element to found.data.
            }
            return notification;
        }, []);

        if (!uniqueCategories.length) return (
            <List id={"notification-list"}>
                <Grid container spacing={0} direction="column" alignItems="center" justify="center"
                      style={{minHeight: 'calc(100vh - 65px)'}}>
                    <Typography style={{textAlign: "center"}}>
                        <h4>Keine Benachrichtigungen</h4>
                    </Typography>
                </Grid>
            </List>
        )

        return uniqueCategories.map((category, i) => {
            let filteredNotificationArray;
            switch (type) {
                case "new":
                    filteredNotificationArray = category.data.filter(x => !x.notification.reviewed.includes(me.me._id.toString()))
                    break
                case "today":
                    filteredNotificationArray = category.data.filter(x => moment(x.notification.creationDate).startOf("day").toString() === moment().startOf("day").toString())
                    break
                case "own":
                    filteredNotificationArray = category.data.filter(x => x.notification.user._id === me.me._id)
                    break;
                case "week":
                    filteredNotificationArray = category.data.filter(x => moment(x.notification.creationDate).week() === moment().week())
                    break;
                default:
                    filteredNotificationArray = category.data
                    break;
            }
            return (
                <List key={i} id={"notification-list"}>
                    {!filteredNotificationArray.length || notifications.length === 0 || !uniqueCategories.length ?
                        <Grid container spacing={0} direction="column" alignItems="center" justify="center"
                              style={{minHeight: 'calc(100vh - 65px)'}}>
                            <Typography style={{textAlign: "center"}}>
                                <h4>Keine Benachrichtigungen</h4>
                            </Typography>
                        </Grid> :
                        <Typography style={{textAlign: "center"}}>
                            <h4><FormattedMessage id={"notification." + category.category}/></h4>
                        </Typography>}
                    {renderNotifications(filteredNotificationArray, type)}
                </List>
            )
        })
    }

    const renderNotifications = (notifications) => {
        return notifications.map((notification, i) => (
            <Card key={i} id={"notification-card"} style={{marginTop: '5px', borderRadius: "20px"}}>
                <CardContent style={{padding: "0px"}}>
                    <ListItem key={i}>
                        {/*
                        <div className={"notification-subtype"}>
                            <i className="material-icons">close</i>
                        </div>
                        */}
                        <Dot variant={notification.notification.severity}/>
                        <ListItemText
                            primary={notification.notification.message}
                            secondary={notification.notification.user.prename + " " + notification.notification.user.surname + " - " + moment(notification.notification.creationDate).format("LLLL")}
                        />
                        <ListItemSecondaryAction>
                            {me.me.role === "ADMIN" &&
                                <IconButton color={"secondary"} disabled={loadingRemove}
                                            onClick={() => handleRemoveNotification(notification.notification._id)}
                                            edge="end" aria-label="delete">
                                    <i className="material-icons">close</i>
                                </IconButton>}
                            {notificationTab === 0 &&
                                <IconButton color={"primary"} disabled={loadingReview}
                                            onClick={() => handleReviewNotification(notification.notification._id)}
                                            edge="end" aria-label="delete">
                                    <i className="material-icons">visibility</i>
                                </IconButton>}
                        </ListItemSecondaryAction>
                    </ListItem>
                </CardContent>
            </Card>
        )).reverse()
    }

    return (
        <Drawer open={props.openNotificationPopper} anchor={"right"}
                onClose={props.onPopperClose}>
            <Tabs
                value={notificationTab}
                indicatorColor="primary"
                textColor="primary"
                onChange={handleChangeNotificationTab}
                aria-label="disabled tabs example"
            >
                <Tab label="Neu"/>
                <Tab label="Heute"/>
                <Tab label="Eigene"/>
                <Tab label={"KW " + moment().week()}/>
            </Tabs>
            <TabPanel value={notificationTab} index={0}>
                {renderNotificationCategories("new")}
            </TabPanel>
            <TabPanel value={notificationTab} index={1}>
                {renderNotificationCategories("today")}
            </TabPanel>
            <TabPanel value={notificationTab} index={2}>
                {renderNotificationCategories("own")}
            </TabPanel>
            <TabPanel value={notificationTab} index={3}>
                {renderNotificationCategories("week")}
            </TabPanel>
        </Drawer>
    )
}

export default NotificationPopper;
