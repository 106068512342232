import {gql} from '@apollo/client';
import {CUSTOMER_ENTITY} from "../entities/customerEntity";
import {CONTACT_ENTITY} from "../entities/contactEntity";

export const GET_ALL_CONTACTS = gql`
  query getAllContacts {
    getAllContacts {
        ...ContactEntity
        customer {
          ...CustomerEntity
        }
    }
  }
  ${CONTACT_ENTITY},${CUSTOMER_ENTITY}
`;
