import {gql} from '@apollo/client';
import {EMPLOYEE_ENTITY} from "../entities/employeeEntity";
import {DISEASE_ENTITY} from "../entities/diseaseEntity";

export const GET_DISEASE_BY_ID = gql`
  query getDiseaseById($_id: String!){
    getDiseaseById(_id: $_id) {
        ...DiseaseEntity
        employee {
            ...EmployeeEntity
        }
    }
  }
  ${DISEASE_ENTITY},${EMPLOYEE_ENTITY}
`;
