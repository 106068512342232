import { gql } from '@apollo/client';

import {MESSAGE_ENTITY} from "../entities/messageEntity";

export const CREATE_MESSAGE = gql`
  mutation createMessage($input: MessageInput!) {
    createMessage(input: $input){
        ...MessageEntity
    }
  }
  ${MESSAGE_ENTITY}
`;
