import { gql } from '@apollo/client';

import {ANNOTATION_ENTITY} from "../entities/annotationEntity";

export const CREATE_ANNOTATION = gql`
  mutation createAnnotation($input: AnnotationInput!) {
    createAnnotation(input: $input){
        ...AnnotationEntity
    }
  }
  ${ANNOTATION_ENTITY}
`;
