import React from 'react';

import './style.css';

import {LOGIN_USER} from '../../../apollo/mutations/loginUser';
import {useMutation} from "@apollo/client";

import Logo from '../Logo/Logo';
import {Alert, Box, Button, Grid, Stack, TextField, Typography} from "@mui/material";
import {useTheme} from "@mui/styles";

const Login = () => {

    const [username, setUsername] = React.useState("")
    const [password, setPassword] = React.useState("")
    const [error, setError] = React.useState("")

    const [loginUser] = useMutation(LOGIN_USER);

    const theme = useTheme();

    const handleLoginAttempt = () => {
        loginUser({
            variables: {
                username: username,
                password: password
            }
        }).then((res) => {
            localStorage.setItem("jwt", res.data.loginUser)
            window.location.reload()
        }).catch(e => {
            // GraphQL errors can be extracted here
            if (e.graphQLErrors)
                setError("Falsche Anmeldedaten, bitte versuchen Sie es erneut!");
            setUsername("")
            setPassword("")
            return false;
        })
    }

    return (
        <Grid container spacing={0}>
            <Grid item md={6}>
                <Box height={'100vh'} width={'100%'} display={'grid'} alignItems="center" justifyItems="center"
                     sx={{backgroundColor: theme.components.MuiPaper.styleOverrides.root.backgroundColor}}>


                    <Box width={'400px'}>
                        <Stack direction={'column'} spacing={1}>
                            <Typography variant={"h5"}>Login</Typography>
                            <Box>
                                {error && !username &&
                                    <Alert severity="error">{error}</Alert>}
                            </Box>
                            <TextField value={username} onChange={(e) => setUsername(e.target.value)}
                                       style={{marginTop: "10px"}} fullWidth variant={"filled"}
                                       label={"Nutzername"}/>
                            <TextField value={password} type={"password"}
                                       onChange={(e) => setPassword(e.target.value)}
                                       style={{marginTop: "15px"}} fullWidth variant={"filled"}
                                       label={"Passwort"}/>
                            <Button disabled={!username || !password} onClick={() => handleLoginAttempt()}
                                    fullWidth
                                    style={{marginTop: "15px"}} variant={"contained"} color={"primary"}
                                    size={"large"}>Login</Button>
                        </Stack>
                    </Box>


                </Box>
            </Grid>
            <Grid item md={6}>
                <Box height={'100vh'} width={'100%'} display={'grid'} alignItems="center" justifyItems="center">
                    <Stack direction={'column'}>
                        <Box textAlign={'center'}>
                            <Logo withText width={'200px'} id={'Login'} color={"#ffffff"}/>
                        </Box>
                        {/*
                        <Paper>
                            <Box p={2}>
                                <Stack direction={'column'} spacing={0}>
                                    <Typography variant={'h5'}>ALEXXIUM Personalplanungs Software</Typography>
                                    <Typography variant={'body1'}>Bitte loggen Sie sich links mit Ihren Daten ein.</Typography>
                                </Stack>
                            </Box>
                        </Paper>
                        */}
                    </Stack>
                </Box>
            </Grid>
        </Grid>
    );

}

export default Login;