import {gql} from '@apollo/client';

export const PLAN_ENTITY = gql`
  fragment PlanEntity on PlanEntity {
    _id,
    startdate,
    enddate,
    created_at,
    updated_at
  }
`;
