import moment from "moment-timezone";

export function isInKw(start, end, kw) {

    // start & end of specified KW
    const startdateweek = moment(kw).startOf("week")
    const enddateweek = moment(kw).endOf("week").endOf('day')
    //console.log(startdateweek.format('DD.MM.yyyy'), enddateweek.format('DD.MM.yyyy'))
    // start & end of specified entity
    const startdate = moment(start).startOf('day')
    const startdatekw = moment(start).startOf("week").startOf('day')
    const enddate = moment(end).startOf('day')

    // filter
    return (startdate.isBetween(startdateweek, enddateweek, 'day', '[]') ||
            enddate.isBetween(startdateweek, enddateweek, 'day', '[]')) ||
        (startdateweek.isBetween(startdate, enddate, 'day', '[]') ||
            enddateweek.isBetween(startdate, enddate, 'day', '[]')) ||
        (!moment(end).isValid() && startdateweek.isSameOrAfter(startdatekw, 'day'))
}
