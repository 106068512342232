import React from 'react';

const Logo = (props) => (
    <>
        {props.withText ?
            <svg width={props.width ? props.width : "80px"} version="1.1" id={props.id}
                 xmlns="http://www.w3.org/2000/svg"
                 x="0px" y="0px"
                 viewBox="0 0 2834.6 2834.6">
                <style type="text/css">
                    {
                        `.${props.id} { fill:${props.color}; }`
                    }
                </style>
                <>
                    <path className={props.id} id={props.id}
                          d="M1682.3,1412.2l298.2,536h417.8l-363.7-633.7C1907.5,1337.5,1791,1369,1682.3,1412.2z"/>
                </>
                <path className={props.id} id={props.id} d="M1930.5,1127.8c-216.9,0-404.4,43.4-565.7,110.6l111.9-201l52.9,103.5c114.6-29.8,240.2-47.2,377.5-47.2
	l-430.5-759.9L553,1948.1h416.6c379.9-516.6,778.4-666.8,1429.7-710.1C2288.5,1170.1,2129.1,1127.8,1930.5,1127.8z M749.8,1699.3
	C749.8,1699.3,749.8,1699.3,749.8,1699.3C749.8,1699.3,749.8,1699.3,749.8,1699.3z M590.8,1899.2
	C590.8,1899.2,590.7,1899.3,590.8,1899.2C590.7,1899.3,590.8,1899.2,590.8,1899.2z M586.6,1904.8
	C586.6,1904.8,586.5,1904.8,586.6,1904.8C586.5,1904.8,586.6,1904.8,586.6,1904.8z"/>
                <>
                    <path className={props.id} id={props.id} d="M146.3,2438.2l7.4,21.9l-33.3,60.8H11l172.9-328.2l172.9,328.2H247.3l-32.4-57.8l6.6-24.9l-37.6-84.5
		L146.3,2438.2z M103.8,2412.4h161.9v69.1H103.8V2412.4z"/>
                    <path className={props.id} id={props.id} d="M537.1,2214.6v225.4h124.7v81h-221v-306.3H537.1z"/>
                    <path className={props.id} id={props.id} d="M839.9,2214.6v306.3H748v-306.3H839.9z M814.5,2214.6H969v76.6H814.5V2214.6z M814.5,2326.2h145.7v74.4H814.5
		V2326.2z M814.5,2444.3H969v76.6H814.5V2444.3z"/>
                    <path className={props.id} id={props.id} d="M1379,2214.6l-99.3,151.4l105.9,154.9h-120.3l-46.4-85.8l-47.7,85.8h-120.3l108.5-154.9l-97.6-151.4h120.3
		l37.6,74l39-74H1379z"/>
                    <path className={props.id} id={props.id} d="M1769.4,2214.6L1670,2366l105.9,154.9h-120.3l-46.4-85.8l-47.7,85.8h-120.3l108.5-154.9l-97.6-151.4h120.3
		l37.6,74l38.9-74H1769.4z"/>
                    <path className={props.id} id={props.id} d="M1960.6,2214.6v306.3h-98.5v-306.3H1960.6z"/>
                    <path className={props.id} id={props.id} d="M2171.6,2214.6v196.9c0,12.3,3.1,22.3,9.4,30c6.3,7.7,16,11.6,29.1,11.6c13.1,0,22.8-3.9,29.1-11.6
		c6.3-7.7,9.4-17.7,9.4-30v-196.9h96.3v205.7c0,21.9-3.6,40.3-10.7,55.4c-7.2,15-17,27.1-29.5,36.3c-12.5,9.2-26.9,15.9-43.1,20.1
		c-16.2,4.2-33.3,6.3-51.4,6.3s-35.2-2.1-51.4-6.3c-16.2-4.2-30.6-10.9-43.1-20.1c-12.5-9.2-22.4-21.3-29.5-36.3
		c-7.1-15-10.7-33.5-10.7-55.4v-205.7H2171.6z"/>
                    <path className={props.id} id={props.id} d="M2544.8,2520.9H2442l56.9-321.6l135.7,165.4l135.7-165.4l56.9,321.6h-102.8l-15.8-125.2l-74,96.7l-74-96.7
		L2544.8,2520.9z"/>
                </>
            </svg>
            :
            <svg width={props.width ? props.width : "80px"} version="1.1" id={props.id}
                 xmlns="http://www.w3.org/2000/svg"
                 x="0px" y="0px"
                 viewBox="0 0 2834.6 2478.5">
                <style type="text/css">
                    {
                        `.${props.id} { fill:${props.color}; }`
                    }
                </style>
                <>
                    <>
                        <path className={props.id} id={props.id}
                              d="M2202,2461l-443.1-796.4c152.7-59.2,318.9-104.9,506.9-139.5L2803,2461H2202z"/>
                        <path className={props.id} id={props.id} d="M2256.8,1544.5l516,899h-560.5L1784,1673.7C1927.2,1619.7,2082.5,1577.2,2256.8,1544.5 M2274.8,1505.6
			c-195.3,35.3-374.1,83.7-540.9,150l457.9,822.9h641.5L2274.8,1505.6L2274.8,1505.6z"/>
                    </>
                </>
                <>
                    <path className={props.id} id={props.id} d="M30.2,2461l1388-2425.6l631,1114c-186,2.3-367.6,25.6-540.5,69.4l-89.8-175.6l-212.3,381.3l46.6-19.4
		c128.2-53.5,262.4-94.7,399-122.7c148.8-30.4,304.5-45.8,462.8-45.8c146.6,0,283.3,15.2,406.3,45c94.5,23,179.8,54.3,254.4,93.3
		c-899.7,66.3-1544.2,273.5-2144.7,1086.1H30.2z M288.8,2085.1l-0.2,0.2l17.8,14.9L288.8,2085.1l0.2-0.2L288.8,2085.1z"/>
                    <path className={props.id} id={props.id} d="M1418.1,70.7l601.4,1061.7c-172.2,4.1-340.5,26.3-501.5,66l-68.6-134.1l-29.8-58.4l-31.9,57.3l-171.8,308.5
		l-49.1,88.2l93.2-38.9c127.1-53,260.3-94,395.7-121.7c72.3-14.8,146.8-26.1,221.4-33.7c77.7-7.9,157.7-11.8,237.8-11.8
		c145.2,0,280.5,15,402.2,44.5c69.4,16.9,133.7,38.3,192.3,64c-868.4,71.7-1498.9,288.5-2087.3,1081H60.4l9.8-17.2l9.1,6.9l0.1-0.1
		l-9.1-6.9l5.2-9.2l10.2,7.8l0.3-0.4l-10.3-7.8L1418.1,70.7 M30.1,2382.2l0.2,0.2L30.1,2382.2L30.1,2382.2 M23.6,2390.9L23.6,2390.9
		L23.6,2390.9 M1418.2,0L0,2478.5h639.7c583.3-793.1,1195.1-1023.8,2195-1090.2C2664.5,1284,2419.8,1219,2114.9,1219
		c-333,0-620.9,66.6-868.4,169.9l171.8-308.5l81.3,158.9c175.9-45.8,368.7-72.5,579.6-72.5L1418.2,0L1418.2,0z M302,2096.5
		C302.1,2096.5,302.1,2096.5,302,2096.5C302.1,2096.5,302.1,2096.5,302,2096.5L302,2096.5z M58,2403.4c0,0-0.1,0.1-0.1,0.1
		C57.9,2403.5,58,2403.4,58,2403.4L58,2403.4z M51.5,2412c0,0,0.1-0.1,0.1-0.1C51.5,2411.9,51.5,2412,51.5,2412L51.5,2412z"/>
                </>
            </svg>
        }
    </>
);

export default Logo;
