import {gql} from '@apollo/client';

export const ANNOTATION_ENTITY = gql`
  fragment AnnotationEntity on AnnotationEntity {
    _id,
    annotation,
    checked,
    assignment,
    priority,
    customerName,
    customerPhone,
    created_at,
    updated_at
  }
`;
