import React, {useEffect, useState} from 'react';

import {
    Autocomplete,
    Box,
    Button,
    Card,
    CardContent,
    Grid,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    Paper,
    Stack,
    TextField,
    Typography
} from "@mui/material";
import GetEmployee from "../common/Essentials/GetEmployee";
import {FaLongArrowAltRight} from "react-icons/fa";
import moment from "moment/moment";
import GetCustomer from "../common/Essentials/GetCustomer";
import PopoverMenu from "../common/PopoverMenu/PopoverMenu";
import {usePlans} from "../../apollo/hooks/usePlans";
import {FormattedMessage} from "react-intl";
import {DateRangePicker} from "@mui/x-date-pickers-pro";
import {useCustomers} from "../../apollo/hooks/useCustomers";
import {useGlobalStore} from "../../store";
import {useEmployees} from "../../apollo/hooks/useEmployees";

const PlanCard = (props) => {

    const setGlobalEditPlan = useGlobalStore((state) => state.setGlobalEditPlan)
    const globalEditPlan = useGlobalStore((state) => state.globalEditPlan)

    const {removePlan, updatePlan, loading} = usePlans()
    const {customers, loading: customersLoading, error: customersError} = useCustomers()
    const {employees, loading: employeesLoading, error: employeesError} = useEmployees()
    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const [value, setValue] = useState([null, null])

    useEffect(() => {
        setSelectedCustomer([props.plan?.customer])
        setValue([moment(props.plan?.startdate), props.plan?.enddate ? moment(props.plan.enddate) : null])
    }, [globalEditPlan])

    if (loading || customersLoading || employeesLoading) return "Loading"

    const handleRemovePlan = () => {
        removePlan(props.plan._id)
    }

    const handleEditPlan = () => {
        const payload = {
            employee: props.plan.employee._id,
            customer: selectedCustomer[0]._id,
            startdate: value[0],
            enddate: value[1]
        }
        updatePlan(payload, props.plan._id).then(() => {
            setGlobalEditPlan(null)
        })
    }

    const handleSetEditPlan = () => {
        setGlobalEditPlan(props.plan._id)
    }

    const handleSelectCustomer = (event, index) => {
        if (index) {
            setSelectedCustomer(customers.filter(x => x._id === index._id));
        } else {
            setSelectedCustomer(null)
        }
    }

    const renderEditPlanCard = () => {
        return (
                <Card>
                    <CardContent style={{padding: "0px"}}>
                        <Box p={1}>
                            <Stack direction={'column'} spacing={1} alignItems={'center'}
                                   justifyContent={'space-between'}>
                                <Autocomplete
                                    fullWidth
                                    id="customer"
                                    onChange={handleSelectCustomer}
                                    value={selectedCustomer && selectedCustomer[0]}
                                    options={customers}
                                    getOptionLabel={option => option.customerId + " - " + option.name}
                                    renderInput={params => (
                                        <TextField
                                            {...params}
                                            label={<FormattedMessage id="customers.search"/>}
                                            variant="filled"
                                            size={"small"}
                                        />
                                    )}
                                />
                                <Stack direction={'row'} spacing={1}>
                                    <DateRangePicker
                                        calendars={2}
                                        value={value}
                                        onChange={(newValue) => setValue(newValue)}
                                        slotProps={{
                                            textField: {variant: 'filled', size: 'small'},
                                            fieldSeparator: {children: 'bis'}
                                        }}
                                        localeText={{start: 'Start', end: 'Ende'}}/>
                                </Stack>
                                <Button disabled={!selectedCustomer || !value[0]} fullWidth color={'primary'}
                                        variant={'contained'}
                                        onClick={() => handleEditPlan()}>Speichern</Button>
                            </Stack>
                        </Box>
                    </CardContent>
                </Card>
        )
    }

    const renderPlanCard = () => {
        return (
            <Card style={{height: "100%"}}>
                <CardContent style={{padding: "0px", height: "100%"}}>
                    <Box p={1} style={{height: "100%"}}>
                        <Stack style={{height: "100%"}} direction={'column'} spacing={props.disableMenu ? 0 : 1}
                               justifyContent={'space-between'}>
                            <Stack direction={'row'} spacing={3} justifyContent={'space-between'}>
                                {props.title &&
                                    <Typography variant={'h7'} color={'#ffffff'}>{props.title}</Typography>
                                }
                                {props.employee &&
                                    <GetEmployee employee={employees.find(e => e._id === props.employee._id)}/>
                                }
                                {props.customer &&
                                    <GetCustomer customer={customers.find(c => c._id === props.customer._id)}/>
                                }
                                {!props.disableMenu &&
                                    <Box>
                                        <PopoverMenu>
                                            <List dense style={{padding: 0}}>
                                                <ListItem disablePadding>
                                                    <ListItemButton onClick={() => handleSetEditPlan()}>
                                                        <ListItemText primary="Bearbeiten"/>
                                                    </ListItemButton>
                                                </ListItem>
                                                <ListItem disablePadding>
                                                    <ListItemButton onClick={() => handleRemovePlan()}>
                                                        <ListItemText primary={'Löschen'}/>
                                                    </ListItemButton>
                                                </ListItem>
                                            </List>
                                        </PopoverMenu>
                                    </Box>}
                            </Stack>
                            <Paper>
                                <Stack p={1} direction={'row'} divider={<FaLongArrowAltRight/>}
                                       alignItems={'center'} justifyContent={'space-between'}>
                                    <Stack direction={'column'}>
                                        <Typography variant={'body2'}>Start</Typography>
                                        <Typography color={'inherit'}
                                                    variant={'body2'}>{moment(props.startdate).format('DD.MM.yyyy')}</Typography>
                                    </Stack>
                                    <Stack direction={'column'}>
                                        <Typography variant={'body2'}>Ende</Typography>
                                        <Typography color={'inherit'}
                                                    variant={'body2'}>{props.enddate ? moment(props.enddate).format('DD.MM.yyyy') : "Kein Ende"}</Typography>
                                    </Stack>
                                </Stack>
                            </Paper>
                        </Stack>
                    </Box>
                </CardContent>
            </Card>
        )
    }

    return (
        props.plan?._id === globalEditPlan ? renderEditPlanCard() : renderPlanCard()
    )
}

export default PlanCard;
