import {useLazyQuery, useMutation, useQuery, useSubscription} from '@apollo/client'
import {GET_ALL_DISEASES} from "../queries/getAllDiseases";
import {ON_DISEASES_CHANGED} from "../subscriptions/onDiseasesChanged";
import {CREATE_DISEASE} from "../mutations/createDisease";
import {UPDATE_DISEASE} from "../mutations/updateDisease";
import {REMOVE_DISEASE} from "../mutations/removeDisease";
import {GET_DISEASE_BY_ID} from "../queries/getDiseaseById";

export const useDiseases = () => {

    const fetchQuery = GET_ALL_DISEASES

    const {
        data: dataQuery,
        loading: loadingQuery,
        error: errorQuery,
        refetch: refetchQuery,
    } = useQuery(fetchQuery, {
        fetchPolicy: 'no-cache'
    })
    const {data: allDiseasesSubData} = useSubscription(ON_DISEASES_CHANGED, {
        fetchPolicy: 'no-cache'
    });

    let diseaseData = allDiseasesSubData ? allDiseasesSubData?.onDiseasesChanged : dataQuery?.getAllDiseases;

    const [add, {error: errorAdd}] = useMutation(
        CREATE_DISEASE,
        {
            errorPolicy: "all",
            fetchPolicy: 'no-cache',
            onCompleted: (res) => {
                if(!res) return
                refetchQuery()
                console.log('disease created!')
            },
        },
    );

    const [update, {error: errorUpdate}] = useMutation(
        UPDATE_DISEASE,
        {
            errorPolicy: "all",
            fetchPolicy: 'no-cache',
            onCompleted: (res) => {
                if(!res) return
                refetchQuery()
                console.log('disease updated!')
            },
        },
    );

    const [remove, {error: errorRemove}] = useMutation(
        REMOVE_DISEASE,
        {
            errorPolicy: "all",
            fetchPolicy: 'no-cache',
            onCompleted: (res) => {
                if(!res) return
                refetchQuery()
                console.log('disease removed!')
            },
        },
    );

    const [getById] = useLazyQuery(
        GET_DISEASE_BY_ID,
        {
            errorPolicy: "all",
            fetchPolicy: 'no-cache',
            onCompleted: (res) => {
                if(!res) return
                refetchQuery()
                console.log('disease found!')
            },
        },
    );

    return {
        diseases: diseaseData?.filter(disease => !disease.employee.disabled),
        loading: loadingQuery,
        error: errorQuery || errorAdd || errorRemove || errorUpdate,
        addDisease: (input) => add({
            variables: {
                input: input
            }
        }),
        updateDisease: (input, id) => update({
            variables: {
                input: input,
                _id: id,
            }
        }),
        removeDisease: (id) => remove({
            variables: {
                _id: id
            }
        }),
        getDiseaseById: (id) => getById({
            variables: {
                _id: id,
            }
        })
    }
}