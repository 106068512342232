// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.popovermenu_container__XJCAS {
    background: rgba(13, 27, 47, 0.5);
    backdrop-filter: blur(10px) saturate(1.3);
}`, "",{"version":3,"sources":["webpack://./src/views/common/PopoverMenu/popovermenu.module.css"],"names":[],"mappings":"AAAA;IACI,iCAAiC;IACjC,yCAAyC;AAC7C","sourcesContent":[".container {\n    background: rgba(13, 27, 47, 0.5);\n    backdrop-filter: blur(10px) saturate(1.3);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `popovermenu_container__XJCAS`
};
export default ___CSS_LOADER_EXPORT___;
