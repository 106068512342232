import React from 'react';
import {FormattedMessage} from 'react-intl';

import ContentHeader from '../common/ContentHeader';

import './style.css';

import LoadingSkeleton from "../common/LoadingSkeleton";
import withSnackbar from "../common/Notification/Notification";
import GetCustomer from "../common/Essentials/GetCustomer";
import {
    Autocomplete,
    Box,
    Button,
    Fab,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    TextField
} from "@mui/material";
import {useNavigate} from "react-router-dom";
import ErrorPaper from "../common/ErrorPaper";
import PopoverMenu from "../common/PopoverMenu/PopoverMenu";
import {useCustomers} from "../../apollo/hooks/useCustomers";

const Customers = () => {

    let sortedCustomers;
    const navigate = useNavigate()
    const [selectedCustomer, setSelectedCustomer] = React.useState(null);
    const [showArchivedCustomers, setShowArchivedCustomers] = React.useState(false);

    const {enabledCustomers, disabledCustomers, archiveCustomer, loading, error} = useCustomers()

    if (loading) return (
        <LoadingSkeleton/>
    );

    if (showArchivedCustomers) {
        sortedCustomers = disabledCustomers;
    } else {
        sortedCustomers = enabledCustomers;
    }

    let allCustomers = selectedCustomer ? selectedCustomer : sortedCustomers;

    const handleToggleArchive = (value) => {
        setShowArchivedCustomers(value);
        setSelectedCustomer(null);
    }

    const handleArchiveCustomer = (customer, archive) => {
        archiveCustomer(customer._id, archive)
        setSelectedCustomer(null);
    }

    const handleSelectCustomer = (event, index) => {
        if (index) {
            setSelectedCustomer(sortedCustomers.filter(x => x._id === index._id));
        } else {
            setSelectedCustomer(null)
        }
    }

    function renderCustomerData() {
        if (allCustomers.length === 0) {
            return (
                <ErrorPaper title={'Keine Auftraggeber gefunden'}
                            subtitle={'Zu den ausgewählten Kriterien wurden keine Auftraggeber gefunden.'}/>
            )
        }
        return allCustomers.map((customer, i) =>
            <TableRow key={i}>
                <TableCell>
                    <ListItem style={{padding: '0px'}}>
                        <ListItemText primary={<span className={'bold'}>{customer.customerId}</span>}
                                      secondary="Kunden ID"/>
                    </ListItem>
                </TableCell>
                <TableCell style={{maxWidth: "350px"}}><GetCustomer customer={customer}/></TableCell>
                <TableCell>
                    <ListItem style={{padding: '0px'}}>
                        <ListItemText primary={<span
                            className={"bold"}>{customer.strasse} {customer.hausnummer}<br/>{customer.postleitzahl} {customer.ort}</span>}
                                      secondary="Adresse"/>
                    </ListItem>
                </TableCell>
                <TableCell>
                    <ListItem style={{padding: '0px'}}>
                        <ListItemText primary={
                            <span className={'bold'}>
                                {customer?.contacts[0]?.telephone}
                            </span>
                        }
                                      secondary={customer?.contacts[0] ? customer?.contacts[0]?.anrede + " " + customer?.contacts[0]?.nachname : "Kein Ansprechpartner"}/>
                    </ListItem>
                </TableCell>
                <TableCell>
                    <ListItem style={{padding: '0px'}}>
                        <ListItemText primary={<span className={'bold'}>{customer.fax}</span>}
                                      secondary={customer.fax ? "Fax" : "Keine Faxnummer"}/>
                    </ListItem>
                </TableCell>
                <TableCell>
                    <PopoverMenu>
                        <List dense style={{padding: 0}}>
                            <ListItem disablePadding>
                                <ListItemButton onClick={() => navigate('/customers/' + customer._id + '/edit')}>
                                    <ListItemText primary="Bearbeiten"/>
                                </ListItemButton>
                            </ListItem>
                            <ListItem disablePadding>
                                <ListItemButton onClick={() => handleArchiveCustomer(customer, !showArchivedCustomers)}>
                                    <ListItemText
                                        primary={showArchivedCustomers ? "Aus Archiv entfernen" : "Archivieren"}/>
                                </ListItemButton>
                            </ListItem>
                        </List>
                    </PopoverMenu>
                </TableCell>
            </TableRow>
        )
    }

    return (
        <Box>
            <ContentHeader
                title={showArchivedCustomers ? <FormattedMessage id="customers.archive.title"/> :
                    <FormattedMessage id="customers.title"/>}
                subtitle={showArchivedCustomers ? <FormattedMessage id="customers.archive.subtitle"/> :
                    <FormattedMessage id="customers.subtitle"/>}
                search={
                    <Autocomplete
                        style={{width: '300px'}}
                        id="customer"
                        onChange={handleSelectCustomer}
                        value={selectedCustomer && selectedCustomer[0]}
                        options={sortedCustomers}
                        getOptionLabel={option => option.customerId + " - " + option.name}
                        renderInput={params => (
                            <TextField
                                {...params}
                                label={showArchivedCustomers ? <FormattedMessage id="customers.archive.search"/> :
                                    <FormattedMessage id="customers.search"/>}
                                variant="filled"
                                size={"small"}
                            />
                        )}
                    />
                }
                actions={
                    <Stack direction={'row'} spacing={1}>
                        <Button onClick={() => handleToggleArchive(false)} variant={"contained"}
                                disabled={!showArchivedCustomers} color={"primary"}>
                            <i className="material-icons">group</i> Aktiv
                        </Button>
                        <Button onClick={() => handleToggleArchive(true)} variant={"contained"}
                                disabled={showArchivedCustomers} color={"primary"}>
                            <i className="material-icons">archive</i> Archiv
                        </Button>
                        <Fab onClick={() => navigate('/customers/create')} size="small" color="primary"
                             aria-label="add">
                            <i className="material-icons">add</i>
                        </Fab>
                    </Stack>
                }
            />
            <Paper style={{borderTopRightRadius: "0px", borderTopLeftRadius: "0px"}}>
                <TableContainer component={Paper}>
                    <Table size="small" aria-label="customer table">
                        <TableBody>
                            {renderCustomerData()}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </Box>
    );

}

export default Customers;
