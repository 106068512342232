import { gql } from '@apollo/client';

import {USER_ENTITY} from "../entities/userEntity";

export const GET_ALL_USERS = gql`
  query getAllUsers {
    getAllUsers {
        ...UserEntity
    }
  }
  ${USER_ENTITY}
`;
