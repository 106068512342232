import {gql} from '@apollo/client';
import {EMPLOYEE_ENTITY} from "../entities/employeeEntity";

export const CREATE_EMPLOYEE = gql`
  mutation createEmployee($input: EmployeeInput!) {
    createEmployee(input: $input){
        ...EmployeeEntity
    }
  }
  ${EMPLOYEE_ENTITY}
`;
