// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bannerHeader {
  height: 800px;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background-image: url('https://images.unsplash.com/photo-1594038658121-219eda06322c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1074&q=80');
  background-size: cover;
  background-repeat: no-repeat;
  filter: saturate(1) blur(20px);
  z-index: 0;
}
.bannerHeader:after {
  content: '';
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(#030c17 0%, transparent 20%, #030c17 90%);
}
`, "",{"version":3,"sources":["webpack://./src/views/common/Header/style.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,WAAW;EACX,kBAAkB;EAClB,OAAO;EACP,MAAM;EACN,uLAAuL;EACvL,sBAAsB;EACtB,4BAA4B;EAC5B,8BAA8B;EAC9B,UAAU;AACZ;AACA;EACE,WAAW;EACX,YAAY;EACZ,WAAW;EACX,kBAAkB;EAClB,MAAM;EACN,OAAO;EACP,qEAAqE;AACvE","sourcesContent":[".bannerHeader {\n  height: 800px;\n  width: 100%;\n  position: absolute;\n  left: 0;\n  top: 0;\n  background-image: url('https://images.unsplash.com/photo-1594038658121-219eda06322c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1074&q=80');\n  background-size: cover;\n  background-repeat: no-repeat;\n  filter: saturate(1) blur(20px);\n  z-index: 0;\n}\n.bannerHeader:after {\n  content: '';\n  height: 100%;\n  width: 100%;\n  position: absolute;\n  top: 0;\n  left: 0;\n  background: linear-gradient(#030c17 0%, transparent 20%, #030c17 90%);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
