import React from 'react';


import './style.css';

import {useQuery, useSubscription} from "@apollo/client";

import {GET_ALL_NOTIFICATIONS} from "../../../../apollo/queries/getAllNotifications";
import {ON_NOTIFICATIONS_CHANGED} from "../../../../apollo/subscriptions/onNotificationsChanged";
import NotificationPopper from "./NotificationPopper";
import {ON_NEW_NOTIFICATION} from "../../../../apollo/subscriptions/onNewNotification";
import UserPopper from "./UserPopper";
import {Badge, IconButton, Stack} from "@mui/material";
import {withStyles} from "@mui/styles";
import {useUsers} from "../../../../apollo/hooks/useUsers";

const StyledBadge = withStyles((theme) => ({
    badge: {
        right: 3,
        top: 7,
        padding: '0 4px',
        position: 'absolute'
    },
}))(Badge);

const InfoWidget = () => {

    const [openNotificationPopper, setOpenNotificationPopper] = React.useState(false)
    const [openUserPopper, setOpenUserPopper] = React.useState(false)

    const {
        data: notificationData,
        error: notificationError,
        loading: notificationLoading
    } = useQuery(GET_ALL_NOTIFICATIONS)
    const {data: notificationSubData} = useSubscription(ON_NOTIFICATIONS_CHANGED);
    useSubscription(ON_NEW_NOTIFICATION, {
        onSubscriptionData: raw => {
            document.getElementById("bell").setAttribute('class', 'material-icons ring-animation')
            setTimeout(function () {
                document.getElementById("bell").setAttribute('class', 'material-icons')
            }, 1000);
        }
    });
    const {onlineUsers, me, loading: usersLoading, error: usersError} = useUsers()

    if (usersLoading) return (<p>Loading...</p>);
    if (usersError) return (<p>Error...</p>);

    const handleOpenNotificationPopper = () => {
        if (openNotificationPopper) setOpenNotificationPopper(false)
        setOpenNotificationPopper(true)
    }

    const handleOpenUserPopper = () => {
        if (openUserPopper) setOpenUserPopper(false)
        setOpenUserPopper(true)
    }

    if (notificationError) return (<p>Error...</p>);
    if (notificationLoading) return (<p>Loading...</p>);

    var notificationCount = 0
    if (notificationSubData) {
        notificationCount = notificationSubData.onNotificationsChanged.filter(notification => !notification.reviewed.includes(me.me._id)).length
    } else {
        notificationCount = notificationData.getAllNotifications.filter(notification => !notification.reviewed.includes(me.me._id)).length
    }
    const notifications = notificationSubData ? notificationSubData.onNotificationsChanged : notificationData.getAllNotifications

    const handleLogout = () => {
        localStorage.removeItem("jwt")
        window.location.reload();
    }

    return (
        <div>
            <Stack direction={'row'} spacing={1}>
                <IconButton className="infoWidgetButton" onClick={() => handleOpenUserPopper()} size="small">
                    <StyledBadge
                        overlap="circular"
                        badgeContent={(onlineUsers.length - 1) > 0 ? onlineUsers.length - 1 : 0}
                        color="primary">
                        <i className="material-icons">group</i>
                    </StyledBadge>
                </IconButton>
                <IconButton className="infoWidgetButton" onClick={() => handleOpenNotificationPopper()} size="small">
                    <StyledBadge overlap="circular" badgeContent={notificationCount} color="secondary">
                        <i id={"bell"} className="material-icons">notifications</i>
                    </StyledBadge>
                </IconButton>
                <IconButton className="infoWidgetButton" color={'secondary'} onClick={() => handleLogout()}
                            size="small">
                    <i className="material-icons">logout</i>
                </IconButton>
            </Stack>
            <NotificationPopper onPopperClose={() => setOpenNotificationPopper(false)} notifications={notifications}
                                openNotificationPopper={openNotificationPopper}/>
            <UserPopper onPopperClose={() => setOpenUserPopper(false)}
                        openUserPopper={openUserPopper}/>
        </div>
    );
}

export default InfoWidget;
