import React from 'react';
import './App.css';
import {BrowserRouter} from 'react-router-dom';
import {IntlProvider} from 'react-intl';

import Authloader from './auth/Authloader';

import {ApolloClient, ApolloLink, ApolloProvider, concat, InMemoryCache} from '@apollo/client';
import {onError} from "@apollo/client/link/error";
//import {WebSocketLink} from '@apollo/client/link/ws';
import {HttpLink} from 'apollo-link-http';
import {split} from 'apollo-link';
import {getMainDefinition} from 'apollo-utilities';

import {getToken} from './jwtContents';

import PanelContent from './views/common/PanelContent';
import Header from './views/common/Header';
import Content from './views/common/Content';
import OnlineUsersWrapper from './views/common/OnlineUsersWrapper';

// Themes
import twasiDarkBlue from './themes/twasi-darkblue/twasi-darkblue';

// Translations
//import germanData from 'react-intl/locale-data/de';
import german from './translations/de_de';
import Chatbox from "./views/Chatbox";
import {CssBaseline, ThemeProvider} from "@mui/material";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";
import {WebSocketLink} from "@apollo/client/link/ws";
import {SubscriptionClient} from "subscriptions-transport-ws";
//import englishData from 'react-intl/locale-data/en';
//import english from './translations/en_en';

require('moment/locale/de');

//addLocaleData(germanData);
//addLocaleData(englishData);

const App = () => {

    const [error] = React.useState("");
    const [loginRequired, setLoginRequired] = React.useState(false);

    const httpLink = new HttpLink({
        uri: window.env.GRAPHQL_URL
    });

    const authMiddleware = new ApolloLink((operation, forward) => {
        // add the authorization to the headers
        operation.setContext({
            headers: {
                authorization: getToken() ? "Bearer " + localStorage.getItem('jwt') : "",
            }
        });
        return forward(operation);
    });

    const wsLink = new WebSocketLink(
        new SubscriptionClient(window.env.WEBSOCKET_URL, {
            reconnect: true,
            connectionParams: {
                authToken: getToken() ? localStorage.getItem('jwt') : "",
            }
        })
    );

    const splittedHttpLink = split(
        // split based on operation type
        ({query}) => {
            const {kind, operation} = getMainDefinition(query);
            return kind === 'OperationDefinition' && operation === 'subscription';
        },
        wsLink,
        httpLink,
    );

    const forceLogin = () => {
        setLoginRequired(true)
        if (localStorage.getItem("jwt")) {
            localStorage.removeItem("jwt");
            window.location.reload()
        }
    }

    const onErrorLink = onError(({graphQLErrors, networkError, operation, forward}) => {
        if (graphQLErrors) {
            for (let err of graphQLErrors) {
                console.log(err)
                switch (err.extensions?.code) {
                    // Apollo Server sets code to UNAUTHENTICATED
                    // when an AuthenticationError is thrown in a resolver
                    case 'UNAUTHENTICATED':
                        forceLogin()
                        // Retry the request, returning the new observable
                        return forward(operation);
                    default:
                        return
                }
            }
        }

        // To retry on network errors, we recommend the RetryLink
        // instead of the onError link. This just logs the error.
        if (networkError) {
            console.log(`[Network error]: ${networkError}`);
        }

        /*
        graphQLErrors.map(({ extensions, message, locations, path }) => {
          if(extensions && extensions.code) {
            console.log(`[GraphQL error]: Code: ${extensions.code}, Message: ${message}, Location: ${locations}, Path: ${path}`);
            setError(`[GraphQL error]: Code: ${extensions.code}, Message: ${message}, Location: ${locations}, Path: ${path}`);
            switch (extensions.code) {
              case "UNAUTHENTICATED":
                forceLogin();
                break;
              default:
                return false;
            }
          } else {
            console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
            setError(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
            return false;
          }
          return false;
        });

      if (networkError)
        console.log(`[Network error]: ${networkError}`);
        //setError(`${error}\n[Network error]: ${networkError}`);
        return false;

         */
    });

    const link = concat(authMiddleware, onErrorLink.concat(splittedHttpLink));
    const cache = new InMemoryCache();

    const client = new ApolloClient({
        link,
        cache
    });

    return (
        <ApolloProvider client={client}>
            <BrowserRouter>
                <IntlProvider locale="de" messages={german}>
                    <ThemeProvider theme={twasiDarkBlue}>
                        <LocalizationProvider dateAdapter={AdapterMoment}>

                            <CssBaseline/>
                            <Authloader error={error} loginRequired={loginRequired}>
                                <OnlineUsersWrapper>
                                    <Content>
                                        <Header/>
                                        <PanelContent/>
                                    </Content>
                                    <Chatbox/>
                                </OnlineUsersWrapper>
                            </Authloader>

                        </LocalizationProvider>
                    </ThemeProvider>
                </IntlProvider>
            </BrowserRouter>
        </ApolloProvider>
    );
}

export default App;