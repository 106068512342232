import React from 'react';

import './style.css';
import Rank from '../Rank';
import Logo from '../Logo';
import InfoWidget from './InfoWidget';
import {Box, Stack, Typography} from "@mui/material";
import {useUsers} from "../../../apollo/hooks/useUsers";


const Header = () => {

    const {me, loading, error} = useUsers()

    if (loading) return (<p>Loading...</p>);
    if (error) return (<p>Error...</p>);

    return (
        <Box py={3}>
            <Box className="bannerHeader"/>
            <Stack direction={'row'} alignItems="center"
                   justifyContent={'space-between'} style={{position: "relative", zIndex: 1}}>
                <Box>
                    <Stack direction={'column'}>
                        <Typography color="inherit" variant={'h5'}>{me.me.prename} {me.me.surname}</Typography>
                        <Typography variant={'body1'}><Rank rank={me.me.role}/></Typography>
                    </Stack>
                </Box>
                <Box>
                    <Logo id="logo" color="#ffffff"/>
                </Box>
                <Box>
                    <InfoWidget/>
                </Box>
            </Stack>
        </Box>
    );
}

export default Header;
