import React from 'react';
import {Box, Paper, Stack, Typography} from "@mui/material";
import {Search} from "@mui/icons-material";
import styles from "./styles.module.css"

const ContentHeader = (props) => {

    return (
        <Paper className={styles.contentHeaderWrapper}>
            <Box p={2} width={'100%'}>
                <Stack direction="row"
                       alignItems="center"
                       justifyContent={'space-between'}
                       spacing={2}>
                    <Stack direction={'column'} spacing={0}>
                        <Typography variant={'h6'} color={'white'}>
                            {props.title && props.title}
                        </Typography>
                        <Typography variant={'body2'}>
                            {props.subtitle && props.subtitle}
                        </Typography>
                    </Stack>
                    {props.search &&
                        <Stack direction={'row'} spacing={1} alignItems={'center'}>
                            <Search style={{color: '#ffffff'}}/>
                            {props.search}
                        </Stack>}
                    {props.actions &&
                        <Box>
                            {props.actions}
                        </Box>}
                </Stack>
            </Box>
        </Paper>
    );

}

export default ContentHeader;
