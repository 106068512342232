// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FullpageLoaderWrapper {
  display: flex;
  width: 100vw;
  height: 100vh;
  align-items: center;
  justify-content: center;
}

.FullpageLoader {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.FullpageLoaderImage {
  height: 100px;
  margin: 0 auto;
}

.FullpageLoaderText {
  text-align: center;
  flex-direction: column;
  margin-top: 15px;
  margin-left: auto;
  margin-right: auto;
}
`, "",{"version":3,"sources":["webpack://./src/views/common/FullpageLoader/style.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;AACzB;;AAEA;EACE,aAAa;EACb,cAAc;AAChB;;AAEA;EACE,kBAAkB;EAClB,sBAAsB;EACtB,gBAAgB;EAChB,iBAAiB;EACjB,kBAAkB;AACpB","sourcesContent":[".FullpageLoaderWrapper {\n  display: flex;\n  width: 100vw;\n  height: 100vh;\n  align-items: center;\n  justify-content: center;\n}\n\n.FullpageLoader {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n}\n\n.FullpageLoaderImage {\n  height: 100px;\n  margin: 0 auto;\n}\n\n.FullpageLoaderText {\n  text-align: center;\n  flex-direction: column;\n  margin-top: 15px;\n  margin-left: auto;\n  margin-right: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
