import React from 'react';

import './style.css';

const Dot = (props) => {

    switch(props.variant) {
        case "success":
            return <div style={{ backgroundColor: "#3bd204" }} className={"dot"}></div>
        case "error":
            return <div style={{ backgroundColor: "#d21f3c" }} className={"dot"}></div>
        case "info":
            return <div style={{ backgroundColor: "#00b6f5" }} className={"dot"}></div>
        default:
            return <div className={"dot"}></div>
    }

}

export default Dot;
