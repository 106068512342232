// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_contentHeaderWrapper__WokhI {
    background: rgba(13, 27, 47, 0.5) !important;
    backdrop-filter: blur(10px) saturate(1.3) !important;
}`, "",{"version":3,"sources":["webpack://./src/views/common/ContentHeader/styles.module.css"],"names":[],"mappings":"AAAA;IACI,4CAA4C;IAC5C,oDAAoD;AACxD","sourcesContent":[".contentHeaderWrapper {\n    background: rgba(13, 27, 47, 0.5) !important;\n    backdrop-filter: blur(10px) saturate(1.3) !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contentHeaderWrapper": `styles_contentHeaderWrapper__WokhI`
};
export default ___CSS_LOADER_EXPORT___;
