import {gql} from '@apollo/client';
import {DISEASE_ENTITY} from "../entities/diseaseEntity";

export const UPDATE_DISEASE = gql`
  mutation updateDisease($input: DiseaseInput!, $_id: String!) {
    updateDisease(input: $input, _id: $_id) {
      ...DiseaseEntity
    }
  }
  ${DISEASE_ENTITY}
`;
