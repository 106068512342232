import {gql} from '@apollo/client';

import {ANNOTATION_ENTITY} from "../entities/annotationEntity";

export const REMOVE_ANNOTATION = gql`
  mutation removeAnnotation($_id: String!) {
    removeAnnotation(_id: $_id){
        ...AnnotationEntity
    }
  }
  ${ANNOTATION_ENTITY}
`;
