import React from 'react';
import {Autocomplete, Box, Grid, Paper, Stack, Tab, Tabs, TextField, Typography} from "@mui/material";
import ContentHeader from "../common/ContentHeader";
import moment from "moment";
import {FormattedMessage} from "react-intl";
import ErrorPaper from "../common/ErrorPaper";
import PlanCard from "./PlanCard";
import {usePlans} from "../../apollo/hooks/usePlans";
import {useEmployees} from "../../apollo/hooks/useEmployees";
import {useCustomers} from "../../apollo/hooks/useCustomers";
import {isInKw} from "../../filters";

function TabPanel(props) {
    const {children, value, index} = props;

    return (
        <Box>
            {value === index && children}
        </Box>
    );
}

const Plans = () => {

    const [planTab, setPlanTab] = React.useState(0);
    const [selectedEmployee, setSelectedEmployee] = React.useState(null);
    const [selectedCustomer, setSelectedCustomer] = React.useState(null);

    const {plans, loading: plansLoading, error: plansError} = usePlans()
    const {enabledEmployees, loading: employeeLoading, error: employeeError} = useEmployees()
    const {enabledCustomers, loading: customerLoading, error: customerError} = useCustomers()

    if (plansError || employeeError || customerError) return (<p>Error...</p>);
    if (plansLoading || employeeLoading || customerLoading) return (<p>Loading...</p>);

    let sortedEmployees = enabledEmployees.sort((a, b) => a.employeeId - b.employeeId)
    let sortedCustomers = enabledCustomers.sort((a, b) => a.customerId - b.customerId)

    let currentPlans = plans.reduce(function (o, cur) {
        if (isInKw(cur.startdate, cur.enddate, moment())) {
            var occurs = o.find(ob => ob.customer._id === cur.customer._id);
            if (occurs) {
                occurs.plans.push(cur)
            } else {
                var obj = {
                    customer: cur.customer,
                    plans: [cur]
                };
                o = o.concat(obj);
            }
        }
        return o;
    }, [])

    let futurePlans = plans.reduce(function (o, cur) {
        if (cur.employee !== null && (moment(cur.startdate).startOf('day') >= moment())) {
            var occurs = o.find(ob => ob.customer._id === cur.customer._id);
            if (occurs) {
                occurs.plans.push(cur)
            } else {
                var obj = {
                    customer: cur.customer,
                    plans: [cur]
                };
                o = o.concat(obj);
            }
        }
        return o;
    }, [])

    let expiredPlans = plans.reduce(function (o, cur) {
        if (!isInKw(cur.startdate, cur.enddate, moment())) {
            var occurs = o.find(ob => ob.customer._id === cur.customer._id);
            if (occurs) {
                occurs.plans.push(cur)
            } else {
                var obj = {
                    customer: cur.customer,
                    plans: [cur]
                };
                o = o.concat(obj);
            }
        }
        return o;
    }, [])

    const handleChangePlanTab = (event, newValue) => {
        setPlanTab(newValue);
    };

    const renderSubPlans = (plans) => {
        return (
            plans.map((plan, index) => (
                <Grid key={index} item xs={3}>
                    <PlanCard
                        employee={plan.employee}
                        startdate={plan.startdate}
                        enddate={plan.enddate}
                        plan={plan}
                    />
                </Grid>
            ))
        )
    }


    const renderPlans = (sortedPlans) => {
        let filteredPlans = sortedPlans.filter(
            plan => selectedCustomer ? plan.customer._id === selectedCustomer._id : true
        ).filter(
            plan => selectedEmployee ? plan.plans.some(x => selectedEmployee && x.employee._id === selectedEmployee._id) : true
        )
        if (filteredPlans.length === 0) {
            return (
                <ErrorPaper title={'Keine Überlassungen gefunden'}
                            subtitle={'Zu den ausgewählten Kriterien wurden keine Überlassungen gefunden.'}/>
            )
        }
        return (
            filteredPlans.map((plan, index) => (
                <Box key={index}>
                    <ContentHeader title={plan.customer.name} subtitle={plan.plans.length + " Überlassungen"}/>
                    <Paper>
                        <Box p={1}>
                            <Grid container spacing={1}>
                                {renderSubPlans(plan.plans.filter(plan => selectedEmployee ? plan.employee._id === selectedEmployee._id : true))}
                            </Grid>
                        </Box>
                    </Paper>
                </Box>
            ))
        )
    }

    const handleSelectEmployee = (event, index) => {
        if (index) {
            setSelectedEmployee(sortedEmployees.filter(x => x._id === index._id)[0]);
        } else {
            setSelectedEmployee(null)
        }
    }

    const handleSelectCustomer = (event, index) => {
        if (index) {
            setSelectedCustomer(sortedCustomers.filter(x => x._id === index._id)[0]);
        } else {
            setSelectedCustomer(null)
        }
    }

    const renderHeaderTitle = () => {
        switch (planTab) {
            case 0:
                return "Aktuelle Verträge";
            case 1:
                return "Zukünftige Verträge";
            case 2:
                return "Ausgelaufene Verträge";
            default:
                return "Error...";
        }
    }

    return (
        <Stack direction={'column'} spacing={1}>
            <Tabs
                indicatorColor="primary"
                textColor="primary"
                value={planTab}
                onChange={handleChangePlanTab}
            >
                <Tab label="Aktuelle Verträge"/>
                <Tab label="Zukünftige Verträge"/>
                <Tab label="Ausgelaufene Verträge"/>
            </Tabs>
            <ContentHeader title={renderHeaderTitle()}
                           actions={
                               <Stack direction={'row'} spacing={1} alignItems={'center'}>
                                   <Typography variant={'body2'}>Verträge Filtern</Typography>
                                   <Autocomplete
                                       style={{width: '300px'}}
                                       id="customer"
                                       onChange={handleSelectCustomer}
                                       options={sortedCustomers}
                                       getOptionLabel={option => option.customerId + " - " + option.name}
                                       renderInput={params => (
                                           <TextField
                                               {...params}
                                               label={<FormattedMessage id="customers.search"/>}
                                               variant="filled"
                                               size={"small"}
                                           />
                                       )}
                                   />
                                   <Autocomplete
                                       style={{width: '300px'}}
                                       id="employee"
                                       onChange={handleSelectEmployee}
                                       options={sortedEmployees}
                                       getOptionLabel={option => option.employeeId + " - " + option.vorname + " " + option.nachname}
                                       renderInput={params => (
                                           <TextField
                                               {...params}
                                               label={<FormattedMessage id="employees.search"/>}
                                               variant="filled"
                                               size={"small"}
                                           />
                                       )}
                                   />
                                   {/*
                                   <Autocomplete
                                       style={{width: '100px'}}
                                       id="kw"
                                       onChange={handleSelectKw}
                                       options={renderCalenderWeeks()}
                                       getOptionLabel={option => option.week}
                                       renderInput={params => (
                                           <TextField
                                               {...params}
                                               label={"KW"}
                                               variant="filled"
                                               size={"small"}
                                           />
                                       )}
                                   />
                                   */}
                               </Stack>
                           }
            />
            {planTab === 0 &&
                <TabPanel value={planTab} index={0}>
                    <Stack direction={'column'} spacing={1}>
                        {renderPlans(currentPlans)}
                    </Stack>
                </TabPanel>}
            {planTab === 1 &&
                <TabPanel value={planTab} index={1}>
                    <Stack direction={'column'} spacing={1}>
                        {renderPlans(futurePlans)}
                    </Stack>
                </TabPanel>}
            {planTab === 2 &&
                <TabPanel value={planTab} index={2}>
                    <Stack direction={'column'} spacing={1}>
                        {renderPlans(expiredPlans)}
                    </Stack>
                </TabPanel>}
        </Stack>
    )
}

export default Plans;
