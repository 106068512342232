import React from 'react';

import FullpageLoader from '../views/common/FullpageLoader';
import Login from "../views/common/Login/Login";

const AuthLoader = (props) => {
    if(props.loginRequired) return <Login/>
    if(props.error) return <FullpageLoader error={props.error}/>
    return props.children;
}

export default AuthLoader;
