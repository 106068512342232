import React, {useEffect} from 'react';
import {FormattedMessage} from "react-intl";
import withSnackbar from "../common/Notification/Notification";
import {
    Autocomplete,
    Box,
    Button,
    Card,
    CardContent, Divider,
    FilledInput,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    Stack,
    TextField, Typography
} from "@mui/material";
import ContentHeader from "../common/ContentHeader";
import {useNavigate, useParams} from "react-router-dom";
import {useCustomers} from "../../apollo/hooks/useCustomers";
import {useContacts} from "../../apollo/hooks/useContacts";

const CustomerTemplate = (props) => {
    // Contact
    const [contactAnrede, setContactAnrede] = React.useState("Herr");
    const [contactVorname, setContactVorname] = React.useState("");
    const [contactNachname, setContactNachname] = React.useState("");
    const [contactTelephone, setContactTelephone] = React.useState("");
    const [contactEmail, setContactEmail] = React.useState("");
    // Stammdaten
    const [customerId, setCustomerId] = React.useState("");
    const [name, setName] = React.useState("");
    const [strasse, setStrasse] = React.useState("");
    const [hausnummer, setHausnummer] = React.useState("");
    const [ort, setOrt] = React.useState("");
    const [postleitzahl, setPostleitzahl] = React.useState("");
    const [fax, setFax] = React.useState("");
    const [gewerk, setGewerk] = React.useState([]);
    const [ansprechpartner, setAnsprechpartner] = React.useState([]);
    const [errors, setErrors] = React.useState([]);

    const routeParams = useParams()
    const navigate = useNavigate()

    const {addCustomer, updateCustomer, getCustomerById, loading, error} = useCustomers()
    const {contacts, addContact, updateContact, removeContact, loading: contactsLoading, error: contactsError} = useContacts()

    useEffect(() => {
        if(!routeParams.id) return
        getCustomerById(routeParams.id).then((val) => {
            setCustomerId(val.data.getCustomerById.customerId)
            setName(val.data.getCustomerById.name)
            setStrasse(val.data.getCustomerById.strasse)
            setHausnummer(val.data.getCustomerById.hausnummer)
            setPostleitzahl(val.data.getCustomerById.postleitzahl)
            setOrt(val.data.getCustomerById.ort)
            setFax(val.data.getCustomerById.fax)
            setGewerk(val.data.getCustomerById.gewerk)
            setAnsprechpartner(val.data.getCustomerById.ansprechpartner)
        })
    }, [])

    const filteredContacts = contacts?.filter(contact => contact.customer._id === routeParams.id)

    if(contactsLoading) return ('...Loading')
    if(contactsError) return ('...Error')

    const handleCreateCustomer = () => {
        const payload = {
            customerId,
            name,
            strasse,
            hausnummer,
            ort,
            postleitzahl,
            fax,
            gewerk
        }
        addCustomer(payload).then(res => {
            if (res.data) {
                navigate('/customers')
            }
            if (res.errors) {
                setErrors(
                    res.errors[0].message.map(error => {
                        return {
                            property: error.property,
                            constraint: Object.values(error.constraints)[0]
                        }
                    })
                )
            }
        })
    }

    const handleUpdateCustomer = () => {
        const payload = {
            customerId,
            name,
            strasse,
            hausnummer,
            ort,
            postleitzahl,
            fax,
            gewerk
        }
        updateCustomer(payload, routeParams.id).then(() => navigate('/customers'))
    }

    const getError = (propertyName) => {
        return errors.filter(e => e.property === propertyName).length > 0
    }

    const getHelperText = (propertyName) => {
        if (!errors.filter(e => e.property === propertyName).length > 0) return false;
        return <FormattedMessage id={errors.filter(e => e.property === propertyName)[0]?.constraint}/>
    }

    const handleChangeGewerke = (event, index) => {
        var gewerke = [];
        for (var i = 0; i < index.length; i++) {
            gewerke.push(index[i])
        }
        setGewerk(gewerke)
    }

    const handleAddContact = () => {
        const payload = {
            anrede: contactAnrede,
            nachname: contactNachname,
            vorname: contactVorname,
            telephone: contactTelephone,
            email: contactEmail,
            customer: routeParams.id
        }
        addContact(payload).then(() => {
            setContactAnrede("Herr")
            setContactVorname("")
            setContactNachname("")
            setContactTelephone("")
            setContactEmail("")
        })
    }

    const renderNewContact = () => {
        return (
            <Box>
                <Grid container spacing={1} display={'flex'} alignItems={'center'}>
                    <Grid item xs={2}>
                        <FormControl variant="filled" fullWidth>
                            <InputLabel id="anrede">Anrede</InputLabel>
                            <Select
                                displayEmpty
                                labelId="anrede"
                                label={"Anrede"}
                                key={"anrede"}
                                onChange={(e) => setContactAnrede(e.target.value)}
                                value={contactAnrede}
                                input={
                                    <FilledInput
                                        name="anrede"
                                    />
                                }
                            >
                                <MenuItem value={"Herr"}>Herr</MenuItem>
                                <MenuItem value={"Frau"}>Frau</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            label="Vorname"
                            key={"name"}
                            fullWidth
                            value={contactVorname}
                            onChange={(e) => setContactVorname(e.target.value)}
                            InputLabelProps={{shrink: true}}
                            variant="filled"
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            label="Nachname"
                            key={"name"}
                            fullWidth
                            value={contactNachname}
                            onChange={(e) => setContactNachname(e.target.value)}
                            InputLabelProps={{shrink: true}}
                            variant="filled"
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            label="Telefon"
                            key={"name"}
                            fullWidth
                            value={contactTelephone}
                            onChange={(e) => setContactTelephone(e.target.value)}
                            InputLabelProps={{shrink: true}}
                            variant="filled"
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            fullWidth
                            label="Email"
                            key={"name"}
                            value={contactEmail}
                            onChange={(e) => setContactEmail(e.target.value)}
                            InputLabelProps={{shrink: true}}
                            variant="filled"
                        />
                    </Grid>
                    <Grid item xs={1}>
                        <Button fullWidth variant={'contained'} disableElevation onClick={() => handleAddContact()}>Hinzufügen</Button>
                    </Grid>
                </Grid>
            </Box>
        )
    }

    const renderContacts = () => {
        return filteredContacts?.map((contact, index) => (
            <Box index={index}>
                <Grid container spacing={1} display={'flex'} alignItems={'center'}>
                    <Grid item xs={2}>
                        <FormControl variant="filled" fullWidth>
                            <InputLabel id="anrede">Anrede</InputLabel>
                            <Select
                                disabled
                                displayEmpty
                                labelId="anrede"
                                label={"Anrede"}
                                key={"anrede"}
                                value={contact.anrede}
                                input={
                                    <FilledInput
                                        name="anrede"
                                    />
                                }
                            >
                                <MenuItem value={"Herr"}>Herr</MenuItem>
                                <MenuItem value={"Frau"}>Frau</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            disabled
                            label="Vorname"
                            key={"name"}
                            fullWidth
                            value={contact.vorname}
                            InputLabelProps={{shrink: true}}
                            variant="filled"
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            disabled
                            label="Nachname"
                            key={"name"}
                            fullWidth
                            value={contact.nachname}
                            InputLabelProps={{shrink: true}}
                            variant="filled"
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            disabled
                            label="Telefon"
                            key={"name"}
                            fullWidth
                            value={contact.telephone}
                            InputLabelProps={{shrink: true}}
                            variant="filled"
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            fullWidth
                            disabled
                            label="Email"
                            key={"name"}
                            fullWidth
                            value={contact.email}
                            InputLabelProps={{shrink: true}}
                            variant="filled"
                        />
                    </Grid>
                    <Grid item xs={1}>
                        <Button color={'secondary'} disableElevation variant={'contained'} onClick={() => removeContact(contact._id)}>Löschen</Button>
                    </Grid>
                </Grid>
            </Box>
        ))
    }

    const getFormContent = () => {
        return (
            <Paper>
                <Box p={2}>
                    <Stack direction={'column'} spacing={1}>
                        <Grid container spacing={1}>
                            <Grid item xs={2}>
                                <TextField
                                    error={getError("customerId")}
                                    helperText={getHelperText("customerId")}
                                    label={"Kunden ID"}
                                    key={"customerId"}
                                    fullWidth
                                    value={customerId}
                                    onChange={(e) => setCustomerId(e.target.value)}
                                    InputLabelProps={{shrink: true}}
                                    variant="filled"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    error={getError("name")}
                                    helperText={getHelperText("name")}
                                    label="Firmenname"
                                    key={"name"}
                                    fullWidth
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    InputLabelProps={{shrink: true}}
                                    variant="filled"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    error={getError("strasse")}
                                    helperText={getHelperText("strasse")}
                                    label="Straße"
                                    key={"strasse"}
                                    fullWidth
                                    value={strasse}
                                    onChange={(e) => setStrasse(e.target.value)}
                                    InputLabelProps={{shrink: true}}
                                    variant="filled"
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <TextField
                                    error={getError("hausnummer")}
                                    helperText={getHelperText("hausnummer")}
                                    label="Hausnummer"
                                    key={"hausnummer"}
                                    fullWidth
                                    value={hausnummer}
                                    onChange={(e) => setHausnummer(e.target.value)}
                                    InputLabelProps={{shrink: true}}
                                    variant="filled"
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <TextField
                                    error={getError("postleitzahl")}
                                    helperText={getHelperText("postleitzahl")}
                                    label="Postleitzahl"
                                    key={"postleitzahl"}
                                    fullWidth
                                    value={postleitzahl}
                                    onChange={(e) => setPostleitzahl(e.target.value)}
                                    InputLabelProps={{shrink: true}}
                                    variant="filled"
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <TextField
                                    error={getError("ort")}
                                    helperText={getHelperText("ort")}
                                    label="Ort"
                                    key={"ort"}
                                    fullWidth
                                    value={ort}
                                    onChange={(e) => setOrt(e.target.value)}
                                    InputLabelProps={{shrink: true}}
                                    variant="filled"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Autocomplete
                                    multiple
                                    id="gewerk"
                                    value={gewerk ? gewerk : []}
                                    onChange={handleChangeGewerke}
                                    options={["Lüftung", "Heizung / Sanitär", "Klima", "Elektro", "MSR"]}
                                    getOptionLabel={option => option}
                                    renderInput={params => (
                                        <TextField {...params} label="Gewerk" variant="filled" fullWidth/>
                                    )}
                                />
                            </Grid>
                        </Grid>
                        {filteredContacts.length !== 0 &&
                        <Card>
                            <CardContent>
                                <Stack direction={'column'} spacing={2} p={2}>
                                    {renderContacts()}
                                </Stack>
                            </CardContent>
                        </Card>}
                        {customerId &&
                        <Card>
                            <CardContent>
                                <Stack direction={'column'} spacing={2} p={2}>
                                    {renderNewContact()}
                                </Stack>
                            </CardContent>
                        </Card>}
                    </Stack>
                </Box>
            </Paper>
        );
    }

    return (
        <Box>
            <Box>
                <ContentHeader
                    title={routeParams.id ? name : "Neue Firma anlegen"}
                    subtitle={routeParams.id ? "Firma bearbeiten" : "Firma hinzufügen"}
                    actions={
                        <Stack direction={'row'} spacing={1}>
                            <Button variant={"contained"} color={"secondary"}
                                    onClick={() => navigate('/customers')}>Zurück</Button>
                            {routeParams.id ? <Button variant={"contained"} color={"primary"}
                                                      onClick={() => handleUpdateCustomer()}
                                                      disabled={loading}>Speichern</Button>
                                :
                                <Button variant={"contained"} color={"primary"}
                                        onClick={() => handleCreateCustomer()}
                                        disabled={loading}>Hinzufügen</Button>}
                        </Stack>
                    }
                />
                <Paper>
                    {getFormContent()}
                </Paper>
            </Box>
        </Box>
    )
}

export default withSnackbar(CustomerTemplate);
