import {useLazyQuery, useMutation, useQuery, useSubscription} from '@apollo/client'
import {GET_ALL_VACATIONS} from "../queries/getAllVacations";
import {ON_VACATIONS_CHANGED} from "../subscriptions/onVacationsChanged";
import {CREATE_VACATION} from "../mutations/createVacation";
import {UPDATE_VACATION} from "../mutations/updateVacation";
import {REMOVE_VACATION} from "../mutations/removeVacation";
import {GET_VACATION_BY_ID} from "../queries/getVacationById";

export const useVacations = () => {

    const fetchQuery = GET_ALL_VACATIONS

    const {
        data: dataQuery,
        loading: loadingQuery,
        error: errorQuery,
        refetch: refetchQuery,
    } = useQuery(fetchQuery, {
        fetchPolicy: 'no-cache'
    })
    const {data: allVacationsSubData} = useSubscription(ON_VACATIONS_CHANGED, {
        fetchPolicy: 'no-cache'
    });

    let vacationData = allVacationsSubData ? allVacationsSubData?.onVacationsChanged : dataQuery?.getAllVacations;

    const [add, {error: errorAdd}] = useMutation(
        CREATE_VACATION,
        {
            errorPolicy: "all",
            fetchPolicy: 'no-cache',
            onCompleted: (res) => {
                if(!res) return
                refetchQuery()
                console.log('vacation created!')
            },
        },
    );

    const [update, {error: errorUpdate}] = useMutation(
        UPDATE_VACATION,
        {
            errorPolicy: "all",
            fetchPolicy: 'no-cache',
            onCompleted: (res) => {
                if(!res) return
                refetchQuery()
                console.log('vacation updated!')
            },
        },
    );

    const [remove, {error: errorRemove}] = useMutation(
        REMOVE_VACATION,
        {
            errorPolicy: "all",
            fetchPolicy: 'no-cache',
            onCompleted: (res) => {
                if(!res) return
                refetchQuery()
                console.log('vacation removed!')
            },
        },
    );

    const [getById] = useLazyQuery(
        GET_VACATION_BY_ID,
        {
            errorPolicy: "all",
            fetchPolicy: 'no-cache',
            onCompleted: (res) => {
                if(!res) return
                refetchQuery()
                console.log('vacation found!')
            },
        },
    );

    return {
        vacations: vacationData?.filter(vacation => !vacation.employee.disabled),
        loading: loadingQuery,
        error: errorQuery || errorAdd || errorRemove || errorUpdate,
        addVacation: (input) => add({
            variables: {
                input: input
            }
        }),
        updateVacation: (input, id) => update({
            variables: {
                input: input,
                _id: id,
            }
        }),
        removeVacation: (id) => remove({
            variables: {
                _id: id
            }
        }),
        getVacationById: (id) => getById({
            variables: {
                _id: id,
            }
        })
    }
}