import React from 'react';
import {FormattedMessage} from 'react-intl';

import ContentHeader from '../common/ContentHeader';
import GetEmployee from '../common/Essentials/GetEmployee';

import './style.css';

import LoadingSkeleton from "../common/LoadingSkeleton";
import {
    Autocomplete,
    Box,
    Button,
    Fab,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    TextField
} from "@mui/material";
import {useNavigate} from "react-router-dom";
import ErrorPaper from "../common/ErrorPaper";
import PopoverMenu from "../common/PopoverMenu/PopoverMenu";
import {useEmployees} from "../../apollo/hooks/useEmployees";

const Employees = () => {

    let sortedEmployees;
    const navigate = useNavigate()
    const [selectedEmployee, setSelectedEmployee] = React.useState(null);
    const [showArchivedEmployees, setShowArchivedEmployees] = React.useState(false);

    const {enabledEmployees, disabledEmployees, loading, error, archiveEmployee, removeEmployee} = useEmployees();

    if (loading) return (
        <LoadingSkeleton/>
    );

    if (showArchivedEmployees) {
        sortedEmployees = disabledEmployees;
    } else {
        sortedEmployees = enabledEmployees;
    }

    let allEmployees = selectedEmployee ? selectedEmployee : sortedEmployees;

    const handleToggleArchive = (value) => {
        setShowArchivedEmployees(value);
        setSelectedEmployee(null);
    }

    const handleArchiveEmployee = (employee, archive) => {
        archiveEmployee(employee._id, archive)
        setSelectedEmployee(null);
    }

    const handleSelectEmployee = (event, index) => {
        if (index) {
            setSelectedEmployee(sortedEmployees.filter(x => x._id === index._id));
        } else {
            setSelectedEmployee(null)
        }
    }

    function renderEmployeeData() {
        if (allEmployees.length === 0) {
            return (
                <ErrorPaper title={'Keine Mitarbeiter gefunden'}
                            subtitle={'Zu den ausgewählten Kriterien wurden keine Mitarbeiter gefunden.'}/>
            )
        }
        return allEmployees.map((employee, i) =>
            <TableRow key={i}>
                <TableCell>
                    <ListItem style={{padding: '0px'}}>
                        <ListItemText primary={<span className={'bold'}>{employee.employeeId}</span>}
                                      secondary="Personal ID"/>
                    </ListItem>
                </TableCell>
                <TableCell><GetEmployee employee={employee}/></TableCell>
                <TableCell>
                    <ListItem style={{padding: '0px'}}>
                        <ListItemText primary={<span className={'bold'}>{employee.telefon}</span>} secondary="Telefon"/>
                    </ListItem>
                </TableCell>
                <TableCell>
                    <ListItem style={{padding: '0px'}}>
                        <ListItemText primary={<span
                            className={'bold'}>{employee.strasse} {employee.hausnummer}<br/>{employee.postleitzahl} {employee.ort}</span>}
                                      secondary="Adresse"/>
                    </ListItem>
                </TableCell>
                <TableCell>
                    <PopoverMenu>
                        <List dense style={{padding: 0}}>
                            <ListItem disablePadding>
                                <ListItemButton onClick={() => navigate('/employees/' + employee._id + '/edit')}>
                                    <ListItemText primary="Bearbeiten"/>
                                </ListItemButton>
                            </ListItem>
                            <ListItem disablePadding>
                                <ListItemButton onClick={() => handleArchiveEmployee(employee, !showArchivedEmployees)}>
                                    <ListItemText
                                        primary={showArchivedEmployees ? "Aus Archiv entfernen" : "Archivieren"}/>
                                </ListItemButton>
                            </ListItem>
                            {showArchivedEmployees &&
                                <ListItem disablePadding>
                                    <ListItemButton onClick={() => removeEmployee(employee._id)}>
                                        <ListItemText primary='Löschen'/>
                                    </ListItemButton>
                                </ListItem>}
                            {!showArchivedEmployees &&
                                <ListItem disablePadding>
                                    <ListItemButton onClick={() => navigate('/plans/create/' + employee._id)}>
                                        <ListItemText primary='Vertrag erstellen'/>
                                    </ListItemButton>
                                </ListItem>}
                        </List>
                    </PopoverMenu>
                </TableCell>
            </TableRow>
        )
    }

    return (
        <Box>
            <ContentHeader
                title={showArchivedEmployees ? <FormattedMessage id="employees.archive.title"/> :
                    <FormattedMessage id="employees.title"/>}
                subtitle={showArchivedEmployees ? <FormattedMessage id="employees.archive.subtitle"/> :
                    <FormattedMessage id="employees.subtitle"/>}
                search={
                    <Autocomplete
                        style={{width: '300px'}}
                        id="employee"
                        onChange={handleSelectEmployee}
                        value={selectedEmployee && selectedEmployee[0]}
                        options={sortedEmployees}
                        getOptionLabel={option => option.employeeId + " - " + option.vorname + " " + option.nachname}
                        renderInput={params => (
                            <TextField
                                {...params}
                                label={showArchivedEmployees ? <FormattedMessage id="employees.archive.search"/> :
                                    <FormattedMessage id="employees.search"/>}
                                variant="filled"
                                size={"small"}
                            />
                        )}
                    />
                }
                actions={
                    <Stack direction={'row'} spacing={1}>
                        <Button onClick={() => handleToggleArchive(false)} variant={"contained"}
                                disabled={!showArchivedEmployees} color={"primary"}>
                            <i className="material-icons">group</i> Aktiv
                        </Button>
                        <Button onClick={() => handleToggleArchive(true)} variant={"contained"}
                                disabled={showArchivedEmployees} color={"primary"}>
                            <i className="material-icons">archive</i> Archiv
                        </Button>
                        <Fab onClick={() => navigate('/employees/create')} size="small" color="primary"
                             aria-label="add">
                            <i className="material-icons">add</i>
                        </Fab>
                    </Stack>
                }
            />
            <Paper style={{borderTopRightRadius: "0px", borderTopLeftRadius: "0px"}}>
                <TableContainer component={Paper}>
                    <Table size="small" aria-label="employee table">
                        <TableBody>
                            {renderEmployeeData()}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </Box>
    );

}

export default Employees;
