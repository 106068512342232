import {gql} from '@apollo/client';
import {EMPLOYEE_ENTITY} from "../entities/employeeEntity";

export const UPDATE_EMPLOYEE = gql`
  mutation updateEmployee($input: EmployeeInput!, $_id: String!) {
    updateEmployee(input: $input, _id: $_id){
        ...EmployeeEntity
    }
  }
  ${EMPLOYEE_ENTITY}
`;
