import { gql } from '@apollo/client';
import {EMPLOYEE_ENTITY} from "../entities/employeeEntity";

export const REMOVE_EMPLOYEE = gql`
  mutation removeEmployee($_id: String!) {
    removeEmployee(_id: $_id) {
        ...EmployeeEntity
    }
  }
  ${EMPLOYEE_ENTITY}
`;