import {gql} from '@apollo/client';

export const CUSTOMER_ENTITY = gql`
  fragment CustomerEntity on CustomerEntity {
    _id,
    customerId,
    name,
    strasse,
    hausnummer,
    ort,
    postleitzahl,
    fax,
    disabled,
    gewerk,
    created_at,
    updated_at
  }
`;