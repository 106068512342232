import {gql} from '@apollo/client';

import {PLAN_ENTITY} from "../entities/planEntity";
import {CUSTOMER_ENTITY} from "../entities/customerEntity";
import {ANNOTATION_ENTITY} from "../entities/annotationEntity";
import {CONTACT_ENTITY} from "../entities/contactEntity";

export const GET_CUSTOMER_BY_ID = gql`
  query getCustomerById($_id: String!){
    getCustomerById(_id: $_id) {
        ...CustomerEntity
        plans {
          ...PlanEntity
        },
        contacts {
          ...ContactEntity
        },
        annotations {
          ...AnnotationEntity
        }
    }
  }
  ${PLAN_ENTITY},${CUSTOMER_ENTITY},${ANNOTATION_ENTITY},${CONTACT_ENTITY}
`;
