import {gql} from '@apollo/client';
import {EMPLOYEE_ENTITY} from "../entities/employeeEntity";

export const ARCHIVE_EMPLOYEE = gql`
  mutation archiveEmployee($_id: String!, $disabled: Boolean!) {
    archiveEmployee(_id: $_id, disabled: $disabled) {
        ...EmployeeEntity
    }
  }
  ${EMPLOYEE_ENTITY}
`;
