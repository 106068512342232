import {gql} from '@apollo/client';
import {USER_ENTITY} from "../entities/userEntity";

export const GET_USER_BY_ID = gql`
  query getUserById($_id: String!){
    getUserById(_id: $_id) {
        ...UserEntity
    }
  }
  ${USER_ENTITY}
`;
