import {gql} from '@apollo/client';
import {ANNOTATION_ENTITY} from "../entities/annotationEntity";

export const UPDATE_ANNOTATION = gql`
  mutation updateAnnotation($input: AnnotationInput!, $_id: String!) {
    updateAnnotation(input: $input, _id: $_id) {
        ...AnnotationEntity
    }
  }
  ${ANNOTATION_ENTITY}
`;
