import React, {useEffect} from 'react';
import {Route, Routes} from 'react-router-dom';

import Sidebar from '../Sidebar/Sidebar';

import Overview from '../../Overview';
import Employees from '../../Employees';
import Annotations from "../../Annotations";

import withSnackbar from "../Notification/Notification";
import {useSubscription} from "@apollo/client";
import {ON_NEW_NOTIFICATION} from "../../../apollo/subscriptions/onNewNotification";
import Customers from "../../Customers/Customers";
import Calendar from "../../Calendar";
import EmployeeTemplate from "../../Employees/EmployeeTemplate";
import CustomerTemplate from "../../Customers/CustomerTemplate";
import Plans from "../../Plans";
import {Box, Stack} from "@mui/material";
import styles from "./panelcontent.module.css"
import PlanTemplate from "../../Plans/PlanTemplate";
import {useUsers} from "../../../apollo/hooks/useUsers";
import {ON_NEW_MESSAGE} from "../../../apollo/subscriptions/onNewMessage";
import Snd from "snd-lib";
import PlanHistory from "../../Plans/PlanHistory";


const PanelContent = (props) => {

    const {me} = useUsers()
    const snd = new Snd();

    useEffect(() => {
        snd.load(Snd.KITS.SND01)
    }, [snd])

    const {data: notificationSubData} = useSubscription(ON_NEW_NOTIFICATION);
    useSubscription(ON_NEW_NOTIFICATION, {
        onSubscriptionData: raw => {
            snd.play(Snd.SOUNDS.NOTIFICATION);
            const message = "[" + notificationSubData.onNewNotification.user.prename + " " + notificationSubData.onNewNotification.user.surname + "] " + notificationSubData.onNewNotification.message
            props.snackbarShowMessage(message, notificationSubData.onNewNotification.severity, notificationSubData.onNewNotification.duration)
        }
    });

    useSubscription(ON_NEW_MESSAGE, {
        onSubscriptionData: raw => {
            const target = raw?.subscriptionData.data.onNewMessage.target
            const user = raw?.subscriptionData.data.onNewMessage.user
            if (target._id === me.me._id) {
                snd.play(Snd.SOUNDS.NOTIFICATION);
                localStorage.setItem("chatmessages", parseInt(localStorage.getItem("chatmessages")) + 1)
                props.snackbarShowMessage("Neue Nachricht von " + user.prename + " " + user.surname, 'info', 2000)
            }
            //const message = "[" + notificationSubData.onNewNotification.user.prename + " " + notificationSubData.onNewNotification.user.surname + "] " + notificationSubData.onNewNotification.message
            //props.snackbarShowMessage(message, notificationSubData.onNewNotification.severity, notificationSubData.onNewNotification.duration)
        }
    });

    return (
        <Stack direction={'row'} spacing={1} className={styles.pageWrapper} display={'flex'}>
            <Box className={styles.sidebarWrapper}>
                <Sidebar/>
            </Box>
            <Box className={styles.contentWrapper}>
                <Routes>
                    <Route index element={<Overview/>}/>
                    <Route path='employees'>
                        <Route index element={<Employees/>}/>
                        <Route path='create' element={<EmployeeTemplate/>}/>
                        <Route path=':id/edit' element={<EmployeeTemplate/>}/>
                    </Route>
                    <Route path='customers'>
                        <Route index element={<Customers/>}/>
                        <Route path='create' element={<CustomerTemplate/>}/>
                        <Route path=':id/edit' element={<CustomerTemplate/>}/>
                    </Route>
                    <Route path='plans'>
                        <Route index element={<Plans/>}/>
                        <Route path='create/:employee' element={<PlanTemplate/>}/>
                        <Route path=':employee' element={<PlanHistory/>}/>
                        <Route path=':id/edit' element={<PlanTemplate/>}/>
                    </Route>
                    <Route path='annotations'>
                        <Route index element={<Annotations/>}/>
                    </Route>
                    <Route path='calendar'>
                        <Route index element={<Calendar/>}/>
                    </Route>
                </Routes>
            </Box>
        </Stack>
    )
}

export default withSnackbar(PanelContent);
