// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sidebar_sidebarWrapper__sBIm9 {
    background-color: #00b6f5;
}
.sidebar_iconButton__i\\+9R8 {
    min-width: 40px !important;
    height: 100%;
}
.sidebar_buttonIcon__4c0D2 {
    font-size: 1.7rem;
}`, "",{"version":3,"sources":["webpack://./src/views/common/Sidebar/sidebar.module.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;AAC7B;AACA;IACI,0BAA0B;IAC1B,YAAY;AAChB;AACA;IACI,iBAAiB;AACrB","sourcesContent":[".sidebarWrapper {\n    background-color: #00b6f5;\n}\n.iconButton {\n    min-width: 40px !important;\n    height: 100%;\n}\n.buttonIcon {\n    font-size: 1.7rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sidebarWrapper": `sidebar_sidebarWrapper__sBIm9`,
	"iconButton": `sidebar_iconButton__i+9R8`,
	"buttonIcon": `sidebar_buttonIcon__4c0D2`
};
export default ___CSS_LOADER_EXPORT___;
