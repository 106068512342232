import {gql} from '@apollo/client';

export const NOTIFICATION_ENTITY = gql`
  fragment NotificationEntity on NotificationEntity {
    _id,
    creationDate,
    category,
    message,
    duration,
    severity,
    reviewed,
    created_at,
    updated_at
  }
`;
