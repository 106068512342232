import {useLazyQuery, useMutation, useQuery, useSubscription} from '@apollo/client'
import {GET_ALL_PLANS} from "../queries/getAllPlans";
import {ON_PLANS_CHANGED} from "../subscriptions/onPlansChanged";
import {CREATE_PLAN} from "../mutations/createPlan";
import {UPDATE_PLAN} from "../mutations/updatePlan";
import {REMOVE_PLAN} from "../mutations/removePlan";
import {GET_PLAN_BY_ID} from "../queries/getPlanById";

export const usePlans = () => {

    const fetchQuery = GET_ALL_PLANS

    const {
        data: dataQuery,
        loading: loadingQuery,
        error: errorQuery,
        refetch: refetchQuery,
    } = useQuery(fetchQuery, {
        fetchPolicy: 'no-cache'
    })
    const {data: allPlansSubData} = useSubscription(ON_PLANS_CHANGED, {
        fetchPolicy: 'no-cache'
    });

    let planData = allPlansSubData ? allPlansSubData?.onPlansChanged : dataQuery?.getAllPlans;

    const [add, {loading: loadingAdd, error: errorAdd}] = useMutation(
        CREATE_PLAN,
        {
            errorPolicy: "all",
            fetchPolicy: 'no-cache',
            onCompleted: (res) => {
                if (!res) return
                refetchQuery()
                //addNotification("Überlassung " + res.createEmployee.vorname + " " + res.createEmployee.nachname + " hinzugefügt.", "EMPLOYEES")
                console.log('plan created!')
            },
        },
    );

    const [update, {loading: loadingUpdate, error: errorUpdate}] = useMutation(
        UPDATE_PLAN,
        {
            errorPolicy: "all",
            fetchPolicy: 'no-cache',
            onCompleted: (res) => {
                if (!res) return
                refetchQuery()
                console.log('plan updated!')
            },
        },
    );

    const [remove, {loading: loadingRemove, error: errorRemove}] = useMutation(
        REMOVE_PLAN,
        {
            errorPolicy: "all",
            fetchPolicy: 'no-cache',
            onCompleted: (res) => {
                if (!res) return
                refetchQuery()
                console.log('plan removed!')
            },
        },
    );

    const [getById] = useLazyQuery(
        GET_PLAN_BY_ID,
        {
            errorPolicy: "all",
            fetchPolicy: 'no-cache',
            onCompleted: () => {
                refetchQuery()
                console.log('plan found!')
            },
        },
    );

    return {
        plans: planData?.filter(plan => !plan.employee.disabled),
        loading: loadingQuery || loadingAdd || loadingRemove || loadingUpdate,
        error: errorQuery || errorAdd || errorRemove || errorUpdate,
        addPlan: (input) => add({
            variables: {
                input: input
            }
        }),
        updatePlan: (input, id) => update({
            variables: {
                input: input,
                _id: id,
            }
        }),
        removePlan: (id) => remove({
            variables: {
                _id: id
            }
        }),
        getPlanById: (id) => getById({
            variables: {
                _id: id,
            }
        })
    }
}

/*
props.snackbarGenerateMessage(
    "Plan für die Firma " + res.data.createPlan.customer.name + " wurde hinzugefügt",
    "success",
    "PLANS",
    4000
)
*/