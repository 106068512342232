import React from 'react';
import { FormattedMessage } from 'react-intl';

const Rank = (rank) => {
  switch (rank.rank) {
    case 'ADMIN': return <FormattedMessage id="rank.admin" />;
    case 'FACHBEREICHSLEITER': return <FormattedMessage id="rank.fachbereichsleiter" />;
    case 'BETRIEBSLEITER': return <FormattedMessage id="rank.betriebsleiter" />;
    case 'SEKRETARIAT': return <FormattedMessage id="rank.sekretariat" />;
    default: return 'Unknown';
  }
}

export default Rank;
