import { gql } from '@apollo/client';

import {NOTIFICATION_ENTITY} from "../entities/notificationEntity";
import {USER_ENTITY} from "../entities/userEntity";

export const ME = gql`
  query me {
    me {
      ...UserEntity
      notifications {
          ...NotificationEntity
      }
    }
  }
  ${NOTIFICATION_ENTITY},${USER_ENTITY}
`;
