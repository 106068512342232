import React, {useEffect, useState} from 'react';

import {Autocomplete, Box, Button, Card, CardContent, Grid, Paper, Stack, TextField} from "@mui/material";
import {useParams} from "react-router-dom";
import ContentHeader from "../common/ContentHeader";
import PlanCard from "./PlanCard";
import {FormattedMessage} from "react-intl";
import {DateRangePicker} from "@mui/x-date-pickers-pro";
import {useEmployees} from "../../apollo/hooks/useEmployees";
import {usePlans} from "../../apollo/hooks/usePlans";
import {useCustomers} from "../../apollo/hooks/useCustomers";
import styles from './plans.module.css'

const PlanTemplate = (props) => {

    const routeParams = useParams()

    const [selectedEmployee, setSelectedEmployee] = useState(null)
    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const [value, setValue] = useState([null, null])

    const {getEmployeeById} = useEmployees()
    const {plans, addPlan, loading: plansLoading, error: plansError} = usePlans()
    const {customers, loading: customersLoading, error: customersError} = useCustomers()

    useEffect(() => {
        getEmployeeById(routeParams.employee).then((val) => {
            setSelectedEmployee(val.data.getEmployeeById)
        })
    }, [])

    if (plansLoading || customersLoading || !selectedEmployee) return "Loading"
    if (plansError || customersError) return "Error"

    const handleCreatePlan = () => {
        const payload = {
            employee: selectedEmployee._id,
            customer: selectedCustomer[0]._id,
            startdate: value[0],
            enddate: value[1]
        }
        addPlan(payload).then(() => {
            setSelectedCustomer(null)
            setValue([null, null])
        })
    }

    const handleSelectCustomer = (event, index) => {
        if (index) {
            setSelectedCustomer(customers.filter(x => x._id === index._id));
        } else {
            setSelectedCustomer(null)
        }
    }

    let timelineElements = plans.filter(plan => plan.employee._id === selectedEmployee._id).concat(selectedEmployee.diseases, selectedEmployee.vacations)
        .sort(function (a, b) {
            a = a.startdate
            b = b.enddate
            if (b === null) {
                return -1;
            }
            return new Date(a) - new Date(b)
        })

    const renderTypeName = (element) => {
        switch (element.__typename) {
            case 'VacationEntity':
                return (
                    <PlanCard
                        title={"Urlaub"}
                        disableMenu
                        startdate={element.startdate}
                        enddate={element.enddate}
                    />
                )
            case 'DiseaseEntity':
                return (
                    <PlanCard
                        title={"Krank"}
                        disableMenu
                        startdate={element.startdate}
                        enddate={element.enddate}
                    />
                )
            case 'PlanEntity':
                return (
                    <PlanCard
                        customer={plans.filter(x => x.customer._id === element.customer._id)[0].customer}
                        startdate={element.startdate}
                        enddate={element.enddate}
                        plan={element}
                    />
                )
            default:
                return 'Unbekannt'
        }
    }

    const renderSortedPlans = () => {
        return timelineElements.map((element, index) => (
            <Grid key={index} item xs={3} className={index !== 0 && styles.planCard}>
                {renderTypeName(element)}
            </Grid>
        ))
    }

    const renderNewPlanCard = () => {
        return (
            <Grid item xs={3}>
                <Card>
                    <CardContent style={{padding: "0px"}}>
                        <Box p={1}>
                            <Stack direction={'column'} spacing={1} alignItems={'center'}
                                   justifyContent={'space-between'}>
                                <Autocomplete
                                    fullWidth
                                    id="customer"
                                    onChange={handleSelectCustomer}
                                    value={selectedCustomer && selectedCustomer[0]}
                                    options={customers}
                                    getOptionLabel={option => option.customerId + " - " + option.name}
                                    renderInput={params => (
                                        <TextField
                                            {...params}
                                            label={<FormattedMessage id="customers.search"/>}
                                            variant="filled"
                                            size={"small"}
                                        />
                                    )}
                                />
                                <Stack direction={'row'} spacing={1}>
                                    <DateRangePicker
                                        calendars={2}
                                        value={value}
                                        onChange={(newValue) => setValue(newValue)}
                                        slotProps={{
                                            textField: {variant: 'filled', size: 'small'},
                                            fieldSeparator: {children: 'bis'}
                                        }}
                                        localeText={{start: 'Start', end: 'Ende'}}/>
                                </Stack>
                                <Button disabled={!selectedCustomer || !value[0]} fullWidth color={'primary'}
                                        variant={'contained'}
                                        onClick={() => handleCreatePlan()}>Erstellen</Button>
                            </Stack>
                        </Box>
                    </CardContent>
                </Card>
            </Grid>
        )
    }

    return (
        <Box>
            <ContentHeader
                title={selectedEmployee.vorname + " " + selectedEmployee.nachname}
                subtitle={"Bisherige Überlassungen von " + selectedEmployee.vorname + " " + selectedEmployee.nachname}
            />
            <Paper>
                <Box p={1}>
                    <Grid container spacing={1}>
                        {renderSortedPlans()}
                        {renderNewPlanCard()}
                    </Grid>
                </Box>
            </Paper>
        </Box>
    );

}

export default PlanTemplate;
