import { gql } from '@apollo/client';
import {CONTACT_ENTITY} from "../entities/contactEntity";

export const REMOVE_CONTACT = gql`
  mutation removeContact($_id: String!) {
    removeContact(_id: $_id) {
        ...ContactEntity
    }
  }
  ${CONTACT_ENTITY}
`;
